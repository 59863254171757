import { List, Text } from '@mantine/core'
import type { BaseSection } from '../../types/insight-types'
import { BaseSection as BaseSectionComponent } from './BaseSection'

type ContentItem = { title: string; content: string | string[] }

type KeyValueSectionProps = BaseSection & {
  content: Record<string, string> | ContentItem[]
}

export function KeyValueSection({ title, content }: KeyValueSectionProps) {
  const entries = Array.isArray(content)
    ? (content as ContentItem[]).map(
        (item) =>
          [
            item.title,
            Array.isArray(item.content)
              ? item.content.join(', ')
              : item.content,
          ] as [string, string],
      )
    : Object.entries(content)

  return (
    <BaseSectionComponent title={title}>
      <List spacing="xs">
        {entries.map(([key, value]) => (
          <List.Item key={`${key}-${value}`}>
            <Text component="span" fw={500}>
              {key}:
            </Text>{' '}
            {value}
          </List.Item>
        ))}
      </List>
    </BaseSectionComponent>
  )
}
