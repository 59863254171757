import { Divider, Group, Stack, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { SearchSuggestion } from '@/features/Search/types'

export interface SearchSuggestionProps {
  suggestion: SearchSuggestion
  onClick: (suggestion: SearchSuggestion) => void
}

export function SearchSuggestionCell({
  suggestion,
  onClick,
}: SearchSuggestionProps) {
  const {
    title,
    icon,
    location: { formattedAddress },
    subject,
  } = suggestion
  return (
    <Stack
      gap="sm"
      onClick={() => onClick(suggestion)}
      bg="darken.7"
      c="gray.0"
      p="sm"
      mb="md"
      style={{
        borderRadius: 'var(--mantine-radius-md)',
        backdropFilter: 'blur(1.5px)',
      }}
    >
      <Group gap="sm" wrap="nowrap">
        <Icon name={icon} size="lg" />
        <Text size="md">{title}</Text>
      </Group>
      <Divider />
      <Group gap="sm" wrap="nowrap">
        <Icon name="my_location" size="sm" />
        <Text size="xs">{formattedAddress}</Text>
      </Group>
      <Group gap="sm" wrap="nowrap">
        <Icon name="search" size="sm" />
        <Text size="xs">{subject}</Text>
      </Group>
    </Stack>
  )
}
