/* v8 ignore start */
export type ShareRoleType = 'viewer' | 'editor' | 'owner' | 'creator'

export interface CollectionCount {
  total: number
  image: number
  news: number
  social: number
  dat: number
}

export interface CollectionUser {
  email: string
  role: ShareRoleType
}

export interface Collection {
  id: string
  name: string
  authoredOn: Date
  creator: string
  createdOn: Date
  modifiedOn: Date
  lastActivity: Date
  desc: string
  owner: string | null
  tags: string[] | null
  owners: string[] | null
  editors: string[] | null
  viewers: string[] | null
  users: CollectionUser[]
  status: string
  refId: string
  refType: string
  preview: string
  notifyNew: boolean
  counts: CollectionCount
}

const CollectionCategoryTypes = {
  IMAGE: 'IMAGE',
  WEB_ARTICLE: 'WEB_ARTICLE',
  SOCIAL_MEDIA: 'SOCIAL_MEDIA',
  FILE: 'FILE',
  COLLECTION: 'COLLECTION',
} as const

export type CollectionCategoryType = ValueOf<typeof CollectionCategoryTypes>

export interface CollectionItem {
  authoredOn: string
  createdOn: string
  name: string
  desc: string
  owner: string
  id: string
  collectionId: string
  preview: string
  status: string
  refType: string
  refId: string
  download: string
  category: CollectionCategoryType
  workspaceId: string
  doc: string
  users?: CollectionUser[]
}

export function isCollection(
  data: Collection | CollectionItem,
): data is Collection {
  return (
    'counts' in data || data.category === CollectionCategoryTypes.COLLECTION
  )
}

export interface ShareWithVendorForm {
  emails: string[]
  message: string
  consent?: boolean
  role: ShareRoleType
}

export interface UnshareWithVendorParams {
  emails: string[]
  role: ShareRoleType
}

export interface TerrapinConfig {
  name: string
  query: string
  owner: string
  description: string
  created: string
  modified: string
  filters: []
  expiration: string
  backfill: boolean
  op_mode: 'search' | 'feed'
  frequency: string
  email_notification: string
  categories: string[]
  pk_id: string
  batch_id: string
  collection_id: string
  queryId: string
  locations: []
}

export interface TerrapinConfigResponse {
  success: boolean
  message: string
  configs: TerrapinConfig
}

export type CollectionSortType =
  | ''
  | 'alpha-asc'
  | 'alpha-desc'
  | 'date-asc'
  | 'date-desc'

export interface SavedSearchLocationsDownloadProps {
  collectionId: string
  collectionName?: string
  totalCount?: number
  filter?: string | undefined
  searchTerm?: string | undefined
}

export interface ListCollectionsParams {
  next?: string
  prev?: string
  limit?: number
  sort?: CollectionSortType
  term?: string
}

export interface CollectionsResponse {
  collections: Collection[]
  next: string
  prev: string
  total: number
}

export interface CreateCollectionParams {
  name: string
  desc: string
  tags?: string[]
}

export interface ShareCollectionParams {
  id: string
  emails: string[]
  message: string
  role: ShareRoleType
}

export interface UnshareCollectionParams {
  id: string
  emails: string[]
  role: ShareRoleType
}

export interface UpdateCollectionParams {
  id: string
  name?: string
  desc?: string
  tags?: string[]
}
