import { Button, Group, Menu } from '@mantine/core'
import { useCollectionItems } from '@/api/collections/collection-items'
import type { Collection } from '@/api/collections/types'
import { ConditionalTooltip } from '@/components/lib/ConditionalTooltip'
import {
  ActionsMenu,
  SearchInput,
  SortToggleButton,
  TypeControl,
} from '@/features/Collections/components'
import { useCollectionDetailHeader } from '@/features/Collections/hooks'
import type { ViewType } from '@/features/Collections/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useCollectionItemsState } from '@/stores/collection-items'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import {
  MdOutlineDownload,
  MdOutlineFileUpload,
  MdOutlinePeopleAlt,
  MdOutlineSearch,
} from 'react-icons/md'

export interface CollectionDetailHeaderProps {
  collection: Collection
  viewType: ViewType
  setViewType: (value: ViewType) => void
}

export function CollectionDetailActions({
  collection,
  viewType,
  setViewType,
}: CollectionDetailHeaderProps) {
  const state = useCollectionItemsState()
  const apiData = useCollectionItems(collection.id)
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  const {
    disableShare,
    handleDownloadClick,
    handleShareClick,
    handleUploadClick,
    searchTerm,
    setSearchTerm,
    shareTooltip,
    handleExploreResultsClick,
    terrapinConfig,
  } = useCollectionDetailHeader({ collection })

  const { totalCount } = apiData

  const icons = {
    download: <MdOutlineDownload />,
    upload: <MdOutlineFileUpload />,
    people: <MdOutlinePeopleAlt />,
    explore: <MdOutlineSearch />,
  }

  return (
    <Group gap="sm">
      <SearchInput {...{ searchTerm, setSearchTerm }} />
      <TypeControl {...{ viewType, setViewType }} />
      <ActionsMenu>
        {!isCommercial && totalCount > 0 && (
          <Menu.Item leftSection={icons.download} onClick={handleDownloadClick}>
            Download Imagery Metadata
          </Menu.Item>
        )}
        <Menu.Item leftSection={icons.upload} onClick={handleUploadClick}>
          Upload a file
        </Menu.Item>
        {terrapinConfig?.queryId && (
          <Menu.Item
            leftSection={icons.explore}
            onClick={handleExploreResultsClick}
          >
            Explore Results
          </Menu.Item>
        )}
      </ActionsMenu>
      <ConditionalTooltip label={shareTooltip}>
        <Button
          color="gray.9"
          size="xs"
          leftSection={icons.people}
          variant="outline"
          disabled={disableShare}
          onClick={handleShareClick}
        >
          SHARE
        </Button>
      </ConditionalTooltip>
      <SortToggleButton
        sort={state.sort}
        selectedCategories={state.selectedCategories}
        onSortChange={state.setSort}
        onCategoryToggle={state.toggleSelectedCategory}
      />
    </Group>
  )
}
