import { type FC, useState } from 'react'
import { ActionIcon, Group, Input, Paper } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useStopDrawingShape } from '@/stores/map-draw-store'

type MapSearchHereButtonProps = {
  onClick: (query: string) => void
}

export const MapSearchActionButtons: FC<MapSearchHereButtonProps> = ({
  onClick,
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const stopDrawingShape = useStopDrawingShape()

  const handleKeyUp = ({ key }: React.KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      onClick(searchQuery)
    }
  }

  return (
    <Paper withBorder radius="xl" p="xs" shadow="sm">
      <Group gap="xs">
        <Input
          w={350}
          placeholder="Search here"
          value={searchQuery}
          onKeyUp={handleKeyUp}
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          rightSection={
            <ActionIcon onClick={() => onClick(searchQuery)} radius="xl">
              <Icon name="arrow_forward" />
            </ActionIcon>
          }
        />
        <ActionIcon
          variant="subtle"
          c="dark"
          radius="xl"
          onClick={stopDrawingShape}
          title="Cancel"
        >
          <Icon name="close" />
        </ActionIcon>
      </Group>
    </Paper>
  )
}
