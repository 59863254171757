import React, { useEffect } from 'react'

export const useSubscribeScrollIntoView = (isActive: boolean) => {
  const targetRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isActive) {
      targetRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }

    return () => {}
  }, [isActive])

  return { targetRef }
}
