import React from 'react'
import {
  Button,
  Center,
  Divider,
  SegmentedControl,
  Stack,
  Table,
  Text,
} from '@mantine/core'
import type { BulkSearchGeolocationWithId } from '@/api/search'
import { Icon } from '@/components/lib/Icon'
import type { LocationsListSetter } from '@/features/BulkSearch/types.ts'
import { TerrapinDynamicMap } from './TerrapinDynamicMap'
import { TerrapinLocationRow } from './TerrapinLocationRow'

import styles from './terrapin-location-panel.module.css'

enum TerrapinLocationPanelView {
  LIST = 'LIST',
  MAP = 'MAP',
}

interface AreasOfInterestPanelProps {
  setLocationsList: LocationsListSetter
  locations: BulkSearchGeolocationWithId[]
}

export function TerrapinLocationPanel({
  locations,
  setLocationsList,
}: AreasOfInterestPanelProps) {
  const [view, setView] = React.useState(TerrapinLocationPanelView.LIST)

  const handleAdd = () => {
    const updatedLocations: BulkSearchGeolocationWithId[] = [
      {
        _id: crypto.randomUUID(),
        value: '',
        row: locations.length,
        column: {},
        name: '',
        metadata: null,
        filters: [
          {
            type: 'LOCATION',
            value: '',
          },
          {
            type: 'LOCATIONTYPE',
            value: 'latLong',
          },
        ],
      },
      ...locations,
    ]

    setLocationsList(updatedLocations)
  }

  const handleRemove = (id: string) => {
    setLocationsList((previousLocations) =>
      previousLocations.filter((location) => location._id !== id),
    )
  }

  const handleLocationChange = (newLocation: BulkSearchGeolocationWithId) => {
    setLocationsList((previousLocations) =>
      previousLocations.map((location) =>
        location._id === newLocation._id ? newLocation : location,
      ),
    )
  }

  return (
    <Stack>
      <SegmentedControl
        bg="gray.4"
        size="xs"
        ml="xs"
        value={view}
        onChange={(value) => setView(value as TerrapinLocationPanelView)}
        classNames={{
          root: styles.segmentedControl,
        }}
        data={[
          {
            value: TerrapinLocationPanelView.LIST,
            label: (
              <Center style={{ gap: 4 }}>
                <Icon name="view_agenda" />
                List
              </Center>
            ),
          },
          {
            value: TerrapinLocationPanelView.MAP,
            label: (
              <Center style={{ gap: 4 }}>
                <Icon name="map" />
                Map
              </Center>
            ),
          },
        ]}
      />
      {view === TerrapinLocationPanelView.LIST && (
        <>
          <Text size="sm" ml="xs">
            Verify your locations are correct
          </Text>
          <Table withRowBorders={false}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Type</Table.Th>
                <Table.Th>Coordinates</Table.Th>
                <Table.Th>AOI Name (Tag Name)</Table.Th>
                <Table.Th>Buffer (km)</Table.Th>
                <Table.Th />
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <Table.Tr>
                <Table.Td colSpan={5}>
                  <Divider pb="xs" />
                  <Button
                    variant="subtle"
                    onClick={handleAdd}
                    size="sm"
                    fw={400}
                    leftSection={<Icon name="add_circle_outline" />}
                  >
                    Add a location
                  </Button>
                </Table.Td>
              </Table.Tr>
              {locations.map((location) => (
                <TerrapinLocationRow
                  key={location._id}
                  location={location}
                  onChange={handleLocationChange}
                  onRemove={handleRemove}
                  onMapClick={() => setView(TerrapinLocationPanelView.MAP)}
                />
              ))}
            </Table.Tbody>
          </Table>
        </>
      )}

      {view === TerrapinLocationPanelView.MAP && (
        <TerrapinDynamicMap locations={locations} />
      )}
    </Stack>
  )
}
