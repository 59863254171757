import * as React from 'react'
import {
  FEATURE_FLAG_ENV_VARIABLES,
  FEATURE_FLAG_KEYS,
  FEATURE_FLAG_VALUES,
  type FeatureFlagKeys,
} from '@/utils/constants'
import { singletonHook } from 'react-singleton-hook'

declare global {
  interface Window {
    dantiSetFeatureFlag: Record<FeatureFlagKeys, (value?: boolean) => void>
    dantiFeatureFlags: Record<FeatureFlagKeys, boolean | undefined>
  }
}

window.dantiFeatureFlags = window.dantiFeatureFlags || {}
window.dantiSetFeatureFlag = window.dantiSetFeatureFlag || {}

export const useLocalFeature = (key: FeatureFlagKeys) => {
  const feature = FEATURE_FLAG_VALUES[key]
  const [flag, setFlag] = React.useState(window.dantiFeatureFlags[key])
  window.dantiSetFeatureFlag[key] = (value?: boolean) => {
    setFlag(value)
    window.dantiFeatureFlags[key] = value
  }
  const envValueString = import.meta.env[
    FEATURE_FLAG_ENV_VARIABLES[feature]
  ] as string
  const envValue = JSON.parse(envValueString ?? 'null') as boolean | null

  return flag ?? envValue
}

export const useLocalFeatureFlagsImpl = () => ({
  insightFeedback: useLocalFeature(FEATURE_FLAG_KEYS.insightFeedback),
  timeline: useLocalFeature(FEATURE_FLAG_KEYS.timeline),
  fauxAnalytic: useLocalFeature(FEATURE_FLAG_KEYS.fauxAnalytic),
  accountManagement: useLocalFeature(FEATURE_FLAG_KEYS.accountManagement),
  passwordManagement: useLocalFeature(FEATURE_FLAG_KEYS.passwordManagement),
  profileManagement: useLocalFeature(FEATURE_FLAG_KEYS.profileManagement),
  userManagement: useLocalFeature(FEATURE_FLAG_KEYS.userManagement),
  dynamicProviders: useLocalFeature(FEATURE_FLAG_KEYS.dynamicProviders),
  places: useLocalFeature(FEATURE_FLAG_KEYS.places),
  showScores: useLocalFeature(FEATURE_FLAG_KEYS.showScores),
  commercial: useLocalFeature(FEATURE_FLAG_KEYS.commercial),
  advancedPoiSearch: useLocalFeature(FEATURE_FLAG_KEYS.advancedPoiSearch),
  welcomeAnnouncement: useLocalFeature(FEATURE_FLAG_KEYS.welcomeAnnouncement),
})

const defaultFlagValues = Object.fromEntries(
  Object.values(FEATURE_FLAG_KEYS).map((value) => [value, null]),
) as Record<FeatureFlagKeys, boolean | null>

export const useLocalFeatureFlags = singletonHook<
  Record<FeatureFlagKeys, boolean | null>
>(() => defaultFlagValues, useLocalFeatureFlagsImpl)
