import { Text } from '@mantine/core'
import type { BaseSection } from '../../types/insight-types'
import { BaseSection as BaseSectionComponent } from './BaseSection'

type TextSectionProps = BaseSection & {
  content: string
}

export function TextSection({ title, content }: TextSectionProps) {
  return (
    <BaseSectionComponent title={title}>
      <Text key={`${title}-${content}`}>{content}</Text>
    </BaseSectionComponent>
  )
}
