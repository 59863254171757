import { getSocialImages } from '@/api/results'
import { ExternalLink } from '@/features/ExternalLink'
import { SocialMediaReferenceCard } from '@/features/ResultCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { SOCIAL_COLORS } from '@/theme/colors'
import { ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { SocialMediaResult } from '@/utils/types/result-types'
import { startCase } from 'lodash'
import { useResultNavigation } from '../hooks'

export function SocialMediaObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as SocialMediaResult
  const source = item.properties.source.toLowerCase()
  const capitalizedSource = startCase(source)
  const { selectItem, items } = useResultNavigation<SocialMediaResult>(item)

  const images = getSocialImages(item)
  const image =
    images.length > 0
      ? {
          thumbnail: images[0].url,
          title: source,
          source: images[0].url,
        }
      : null

  return (
    <SingleObjectViewTemplate<SocialMediaResult, SocialMediaResult>
      {...{
        item,
        hero: {
          socialIconName: source,
          color: SOCIAL_COLORS[source] || 'blue',
          thumbnailImage: image,
          metaDetails: [capitalizedSource, formatDatetime(item.authoredOn)],
          metaAction: (
            <ExternalLink href={item.properties.link}>Source</ExternalLink>
          ),
        },
        content: { details: item.properties.summary },
        carousel: {
          title: 'More Social Media',
          items,
          selectItem,
          categoryPageLink: {
            url: `${ROUTES.explore}?tab=social`,
            label: 'All Social Media',
          },
          renderCard: (social: SocialMediaResult) => (
            <SocialMediaReferenceCard result={social} />
          ),
        },
      }}
    />
  )
}
