import { ActionIcon, type ActionIconProps } from '@mantine/core'
import { useBackNavigation } from '@/features/AppRouting/hooks'
import { MdArrowBack } from 'react-icons/md'

export function BackButton(props: ActionIconProps) {
  const goBack = useBackNavigation()

  return (
    <ActionIcon
      onClick={goBack}
      radius="50%"
      p="0"
      variant="outline"
      color="gray"
      {...props}
    >
      <MdArrowBack />
    </ActionIcon>
  )
}
