import React from 'react'
import { Autocomplete, Box, Button, Checkbox, Group } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { postServiceRequest } from '@/api/danti-requests'
import { CheckboxGroup } from '@/components/lib/CheckboxGroup'
import { Icon } from '@/components/lib/Icon'
import { toastError, toastSuccess } from '@/components/toasts'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import type { ImageryResult } from '@/utils/types/result-types'
import { captureException, ErrorBoundary } from '@sentry/react'
import { groupBy } from 'lodash'

const analysisTypes = [
  {
    value: 'xviewDetector',
    label: 'xView General Detector (YOLOv8)',
    group: 'General Detectors',
  },
  {
    value: 'aircraft.descartes',
    label: 'Descartes labs foreign aircraft v4.1.0',
    group: 'Aircraft',
  },
  {
    value: 'aircraft.danti',
    label: 'Danti aircraft detection v1.0 (YOLOv5)',
    group: 'Aircraft',
  },
]

const analysisGroups = Object.entries(groupBy(analysisTypes, 'group')).map(
  ([groupName, items]) => ({
    group: groupName,
    items: items.map(({ label, value }) => ({ label, value })),
  }),
)

export function AnalyzeItemModal({
  context,
  id: modalId,
  innerProps: { item },
}: ContextModalProps<{ item: ImageryResult }>) {
  const isFauxAnalyticEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.fauxAnalytic)
  const fauxAnalyticName = 'DejaVu Geolocation'
  const initialSelected = isFauxAnalyticEnabled
    ? [fauxAnalyticName]
    : ['General Detectors']

  const [selectedAnalytics, setSelectedAnalytics] =
    React.useState<string[]>(initialSelected)

  async function handleAnalyzeClick() {
    try {
      if (isFauxAnalyticEnabled) {
        toastSuccess({
          message:
            'Analysis started. This process can take a while. Check your email for results.',
        })
      }
      await postServiceRequest('/download/analytic/', {
        id: item.properties.featureId,
        provider: item.source,
      })
      captureEvent(USER_EVENTS.DETAIL.ANALYZE, {
        resultCategory: item.properties.resultCategory ?? item.category,
      })
      if (!isFauxAnalyticEnabled) {
        toastSuccess({
          message:
            'Analysis started. This process can take a while. Check your email for results.',
        })
      }
    } catch (error) {
      captureException(error, {
        extra: { itemId: item.id, provider: item.source },
      })
      if (!isFauxAnalyticEnabled) {
        toastError({
          message: 'Failed to start analysis. Please try again later.',
        })
      }
    } finally {
      context.closeModal(modalId)
    }
  }

  function handleClick(value: string) {
    if (selectedAnalytics.includes(value)) {
      setSelectedAnalytics((selected) =>
        selected.filter((selectedValue) => selectedValue !== value),
      )
    } else {
      setSelectedAnalytics((selected) => [...selected, value])
    }
  }

  return (
    <>
      {/* No fallback necessary yet. We can just skip rendering the search. */}
      <ErrorBoundary fallback={<span />}>
        <Autocomplete
          leftSection={<Icon name="search" />}
          placeholder="Search for imagery analysis models"
          data={analysisGroups}
        />
      </ErrorBoundary>
      <Box
        mt="sm"
        mih="10rem"
        style={(theme) => ({
          overflowY: 'auto',
          border: `1px solid ${theme.colors.gray[2]}`,
        })}
      >
        {isFauxAnalyticEnabled ? (
          <Checkbox
            p="xs"
            onChange={() => handleClick(fauxAnalyticName)}
            label={fauxAnalyticName}
            checked={selectedAnalytics.includes(fauxAnalyticName)}
          />
        ) : (
          analysisGroups.map(({ group, items }) => (
            <CheckboxGroup
              key={group}
              group={group}
              items={items}
              selected={selectedAnalytics}
              onChange={handleClick}
            />
          ))
        )}
      </Box>
      <Group justify="space-between" mt="md">
        <Button variant="subtle" onClick={() => context.closeModal(modalId)}>
          Cancel
        </Button>
        <Button
          disabled={selectedAnalytics.length === 0}
          onClick={() => void handleAnalyzeClick()}
        >
          Start Analysis
        </Button>
      </Group>
    </>
  )
}
