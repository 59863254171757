import React from 'react'
import { useMantineTheme } from '@mantine/core'
import type { SymbolName } from '@/utils/types/material-icons'
import { classes } from '@/utils/ui-helpers'

export interface SymbolProps extends React.HTMLAttributes<HTMLSpanElement> {
  name: SymbolName
  size?: number | string
  filled?: boolean
  weight?: number
  grade?: number
  opticalSize?: number
}

export const Icon = React.forwardRef<HTMLSpanElement, SymbolProps>(
  ({ size: iconSize, ...props }: SymbolProps, ref) => {
    const {
      name,
      filled = false,
      weight = 300,
      grade = 0,
      opticalSize,
      style,
      className,
      ...rest
    } = props
    const theme = useMantineTheme()
    const innerSize = React.useRef<HTMLSpanElement>(null)

    const realSize = React.useRef<string | number>(iconSize ?? '1.333rem')

    if (typeof iconSize === 'string' && iconSize in theme.fontSizes) {
      realSize.current = `calc(4/3 * ${theme.fontSizes[iconSize]})`
    }

    React.useLayoutEffect(() => {
      if (!iconSize && innerSize.current) {
        realSize.current = innerSize.current.getBoundingClientRect().height
      }
    }, [iconSize])

    return (
      <span
        ref={ref}
        style={{
          fontVariationSettings: `"FILL" ${
            filled ? 1 : 0
          }, "wght" ${weight}, "GRAD" ${grade}, "opsz" ${opticalSize ?? realSize.current}`,
          fontSize: iconSize ? realSize.current : '1.333rem',
          ...(iconSize && {
            width: realSize.current,
            height: realSize.current,
          }),
          overflow: 'clip',
          ...style,
        }}
        className={classes('material-symbols-outlined', className)}
        {...rest}
      >
        {name}
        <span style={{ height: '100%', display: 'none' }} ref={innerSize}>
          &nbsp;
        </span>
      </span>
    )
  },
)

Icon.displayName = 'Icon'
