import { useState } from 'react'
import { Card, Flex, Image, Stack, Text } from '@mantine/core'
import type { CollectionItem } from '@/api/collections'
import { Icon } from '@/components/lib/Icon'
import { ScoreDisplay } from '@/components/lib/ScoreDisplay.tsx'
import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import { ResultActions } from '@/features/ResultActions'
import { HIDE_ACTIONS_BY_CATEGORY } from '@/features/ResultActions/constants'
import type { HideableActionCategories } from '@/features/ResultActions/types.ts'
import { ResultImage, type ResultImageObject } from '@/features/ResultImage'
import { formatDate, formatTime } from '@/utils/format-datetime'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ImageryResult, ResultWithId } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'
import type { MultiPolygon, Point, Polygon } from 'geojson'

import styles from './reference-card.module.css'

type ReferenceCardProps = {
  result: ResultWithId
  category: HideableActionCategories
  heroMapImage?: ImageryResult
  heroImage?: ResultImageObject
  heroImageUrl?: string | null
  heroIcon?: React.ReactNode
  heroIconName?: SymbolName
  heroGeometry?: Point | Polygon | MultiPolygon
  title: React.ReactNode
  authoredOn: string
  details?: string
  description?: string
  withActions?: boolean
  item?: CollectionItem
  isActive?: boolean
}

const IMAGE_HEIGHT = '6.875rem' // 110px
const BANNER_HEIGHT = '2.5rem' // 40px

const IconWrapper = ({
  children,
  className = '',
}: {
  children: React.ReactNode
  className?: string
}) => (
  <Flex align="center" h="100%" px="xs" className={className}>
    {children}
  </Flex>
)

export const ReferenceCard = ({
  heroImage,
  heroMapImage,
  heroImageUrl,
  heroIcon,
  heroIconName,
  heroGeometry,
  result,
  category,
  title,
  authoredOn,
  details,
  description,
  withActions = true,
  item,
  isActive,
}: ReferenceCardProps) => {
  const [hideImage, setHideImage] = useState(false)
  const [actionsVisible, setActionsVisible] = useState(false)

  let hero
  let hasImage = true
  if (heroGeometry) {
    hero = <GeometryStaticDisplay geometry={heroGeometry} />
  } else if (heroMapImage) {
    hero = <ResultImage item={heroMapImage} />
  } else if (heroImage) {
    hero = <ResultImage item={heroImage} />
  } else if (heroImageUrl && !hideImage) {
    hero = (
      <Image
        src={heroImageUrl}
        alt={heroImageUrl}
        mih="100%"
        fit="cover"
        loading="lazy"
        onError={() => setHideImage(true)}
      />
    )
  } else if (heroIcon) {
    hero = <IconWrapper className={styles.svgIcon}>{heroIcon}</IconWrapper>
    hasImage = false
  } else {
    hero = (
      <IconWrapper>
        <Icon
          name={heroIconName ?? 'question_mark'}
          size="1.5rem"
          weight={400}
        />
      </IconWrapper>
    )
    hasImage = false
  }

  const hasHero = heroImage || heroImageUrl || heroGeometry

  return (
    <Card
      className={classes(styles.card, isActive && styles.active)}
      padding="xs"
      radius="md"
      withBorder
      onMouseEnter={() => setActionsVisible(true)}
      onMouseLeave={() => setActionsVisible(false)}
    >
      <Card.Section
        h={hasImage ? IMAGE_HEIGHT : BANNER_HEIGHT}
        bg="gray.2"
        c="gray"
        style={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}
      >
        {hero}
      </Card.Section>
      {withActions && actionsVisible && (
        <ResultActions
          result={result}
          item={item}
          hideActions={HIDE_ACTIONS_BY_CATEGORY[category]}
        />
      )}
      <Stack gap={0}>
        <Text
          lineClamp={2}
          title={typeof title === 'string' ? title : ''}
          fz="xs"
        >
          {title}
        </Text>
        <Text fz="xs" c="gray">
          {formatDate(authoredOn)} {formatTime(authoredOn)}
        </Text>
        {details && <Text fz="xs">{details}</Text>}
        {description && (
          <Text lineClamp={hasHero && !hideImage ? 3 : 6} fz="xs">
            {description}
          </Text>
        )}
        <ScoreDisplay result={result} />
      </Stack>
    </Card>
  )
}
