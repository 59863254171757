import { Stack, Text } from '@mantine/core'
import type { ReactNode } from 'react'

interface BaseSectionProps {
  title?: string
  children: ReactNode
}

export function BaseSection({ title, children }: BaseSectionProps) {
  return (
    <Stack gap="xs">
      {title && (
        <Text fw={700} size="lg">
          {title}
        </Text>
      )}
      {children}
    </Stack>
  )
}
