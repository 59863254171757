import type { CollectionItem } from '@/api/collections/types'
import {
  useNavigateToCollection,
  useNavigateToCollectionItem,
} from '@/features/AppRouting'
import { ListItem } from '@/features/Collections/components'
import { iconSize } from '@/utils/icon-size'
import { isSubCollection } from '@/utils/types/result-type-checkers'
import type { ResultWithId } from '@/utils/types/result-types'
import { MdOutlineFolder } from 'react-icons/md'
import { CollectionDetailResultIcon } from './CollectionDetailResultIcon'

export interface CollectionDetailListItemProps {
  data: CollectionItem
}

export function CollectionDetailListItem({
  data,
}: CollectionDetailListItemProps) {
  const navigateToCollection = useNavigateToCollection()
  const navigateToCollectionItem = useNavigateToCollectionItem()

  const isCollection = isSubCollection(data)

  function handleNavigation() {
    if (isCollection) {
      navigateToCollection(data.refId)
    } else {
      navigateToCollectionItem(data.collectionId, data.id)
    }
  }

  const { name, authoredOn } = data
  const searchResultItem = isCollection
    ? null
    : (JSON.parse(data.doc) as ResultWithId)

  return (
    <ListItem
      data={data}
      onClick={handleNavigation}
      icon={
        searchResultItem ? (
          <CollectionDetailResultIcon result={searchResultItem} />
        ) : (
          <MdOutlineFolder size={iconSize('xxl')} />
        )
      }
      date={authoredOn}
      name={name}
    />
  )
}
