import { Title } from '@mantine/core'
import { modals } from '@mantine/modals'

export function openSearchFiltersModal({
  isPostFilter,
}: {
  isPostFilter: boolean
}) {
  modals.openContextModal({
    size: 1000,
    modal: 'searchFilters',
    styles: {
      header: {
        padding:
          'var(--mantine-spacing-xl) var(--mantine-spacing-xl) var(--mantine-spacing-sm) var(--mantine-spacing-xl)',
      },
    },
    title: (
      <Title component="span" order={2}>
        {isPostFilter ? 'Search Filters' : 'Advanced Search Options'}
      </Title>
    ),
    centered: true,
    innerProps: {},
  })
}
