import { useSearchMetadata } from '@/api/results'
import type { SearchMetadata } from '@/api/results/types.ts'
import { useMapLayers } from '@/features/DantiMap/hooks'
import { useGetFilters } from '@/stores/filters-store'
import * as turf from '@turf/turf'
import type { MutableRefObject } from 'react'
import { EXTENT_LAYER_ID, POINT_LAYER_ID, SOURCE_ID } from './constants'

export const useLocationResponseLayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const searchMetadata = useSearchMetadata()
  const apiFilter = useGetFilters()

  let layers
  if (
    searchMetadata?.geometry &&
    (searchMetadata.locationType == 'geolocation' ||
      searchMetadata.locationType == 'property')
  ) {
    const isRadiusSearch =
      apiFilter.radius !== null && searchMetadata.radiusKilometers

    const transformRadiusIntoPolygonGeojson = (search: SearchMetadata) => {
      return turf.circle(
        [
          search.geometry.coordinates[0] as number,
          search.geometry.coordinates[1] as number,
        ],
        search.radiusKilometers as number,
        {
          steps: 64,
          units: 'kilometers',
        },
      )
    }

    const geojsonFeature = isRadiusSearch
      ? transformRadiusIntoPolygonGeojson(searchMetadata)
      : turf.feature(searchMetadata.geometry)
    const { type } = geojsonFeature.geometry
    const isPoint = !isRadiusSearch && ['Point', 'MultiPoint'].includes(type)
    const layerId = isPoint ? POINT_LAYER_ID : EXTENT_LAYER_ID

    layers = {
      [layerId]: {
        sourceId: SOURCE_ID,
        type: isPoint ? 'symbol' : 'line',
        isVisible: true,
        data: geojsonFeature,
        ...(isPoint
          ? { pointIcon: 'Point Data Icon-Query' }
          : { lineWidth: 2, lineColor: '#F2970A', lineDasharray: [1, 1] }),
      },
    }
  }
  useMapLayers({
    map: map.current,
    layers: layers || {},
  })
}
