import { useMapDrawStore } from '@/stores/map-draw-store/store'

export const useIsDrawing = () => useMapDrawStore((s) => s.isDrawing)
export const useDrawMode = () => useMapDrawStore((s) => s.drawMode)
export const useSetDrawMode = () => useMapDrawStore((s) => s.setDrawMode)
export const useCurrentSearchGeometry = () =>
  useMapDrawStore((s) => s.currentSearchGeometry)
export const useUploadedFeature = () =>
  useMapDrawStore((s) => s.uploadedFeature)
export const useSearchHerePopup = () =>
  useMapDrawStore((s) => s.searchHerePopup)
export const useDrawController = () => useMapDrawStore((s) => s.drawController)

export const useSetCurrentSearchGeometry = () =>
  useMapDrawStore((s) => s.setCurrentSearchGeometry)
export const useSetDrawController = () =>
  useMapDrawStore((s) => s.setDrawController)
export const useSetIsDrawing = () => useMapDrawStore((s) => s.setIsDrawing)
export const useSetSearchHerePopup = () =>
  useMapDrawStore((s) => s.setSearchHerePopup)
export const useSetUploadedFeature = () =>
  useMapDrawStore((s) => s.setUploadedFeature)
