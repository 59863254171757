import { Group, Text } from '@mantine/core'
import type { Collection } from '@/api/collections/types'
// import { TableHeaderSortCell } from '@/features/Collections/components'
// import { SORT_TYPES } from '@/features/Collections/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { iconSize } from '@/utils/icon-size'
import { createColumnHelper } from '@tanstack/react-table'
import {
  MdOutlineFolderSpecial,
  MdOutlineLoop,
  MdSpaceDashboard,
} from 'react-icons/md'
import { CollectionMenu } from './CollectionMenu'

const columnHelper = createColumnHelper<Collection>()

export const CollectionTableColumns = [
  columnHelper.accessor('name', {
    header: () => {
      return (
        // TODO: Restore when collection sorting is resolved
        // <TableHeaderSortCell
        //   label="Saved Search Name"
        //   sortType={SORT_TYPES.name}
        // />
        <Text fw="bold" fz="xs">
          Saved Search Name
        </Text>
      )
    },
    cell: (info) => {
      const isTerrapin = Boolean(info.row.original.refId)
      const Icon = isTerrapin ? MdOutlineFolderSpecial : MdSpaceDashboard
      return (
        <Group gap="sm" wrap="nowrap">
          <Icon size={iconSize('xl')} style={{ flexShrink: 0 }} />
          <Text>{info.getValue()}</Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor('lastActivity', {
    header: () => (
      // <TableHeaderSortCell label="Last updated" sortType={SORT_TYPES.date} />
      <Text fw="bold" fz="xs">
        Last updated
      </Text>
    ),
    cell: (info) => <Text>{formatDatetime(info.getValue().toString())}</Text>,
  }),

  columnHelper.accessor('refId', {
    header: () => (
      <Text fw="bold" fz="xs">
        Kind
      </Text>
    ),
    cell: (info) => {
      const isTerrapin = Boolean(info.row.original.refId)
      return (
        <Group gap={12}>
          <Text>
            Saved Search
            {isTerrapin && (
              <MdOutlineLoop
                size={iconSize('md')}
                style={{
                  verticalAlign: 'middle',
                  marginLeft: 'var(--mantine-spacing-xxs)',
                }}
              />
            )}
          </Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    cell: (info) => (
      <CollectionMenu collection={info.getValue()} orientation="horizontal" />
    ),
  }),
]
