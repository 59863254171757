import {
  deleteHistoryItems,
  getHistory,
  getRecentHistory,
} from '@/api/history/api'
import { KEYS, PAGE_SIZE } from '@/api/history/constants'
import type {
  GetHistoryParams,
  HistoryResponse,
  UsePageHistoryParams,
  UseRecentHistoryParams,
} from '@/api/history/types'
import {
  useMutation,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query'

export { useHistory } from './use-history'

export const useRecentHistory = ({
  isAuthenticated,
}: UseRecentHistoryParams): UseQueryResult<HistoryResponse> =>
  useQuery({
    queryKey: [KEYS.history, KEYS.recent],
    queryFn: getRecentHistory,
    enabled: isAuthenticated,
  })

export const usePageHistory = ({
  currentPage,
  searchTerm,
  isAuthenticated,
}: UsePageHistoryParams): UseQueryResult<HistoryResponse> =>
  useQuery({
    queryKey: [KEYS.history, currentPage, searchTerm],
    queryFn: async () => {
      const params: GetHistoryParams = {
        from: currentPage * PAGE_SIZE,
        size: PAGE_SIZE,
        term: searchTerm,
      }
      return getHistory(params)
    },
    enabled: isAuthenticated,
  })

export const useDeleteHistoryMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: deleteHistoryItems,
    onSuccess: () => {
      // Slight delay on backend when is updated in opensearch
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: [KEYS.history] })
      }, 500)
    },
  })
}
