import * as React from 'react'

import styles from './bento-section.module.css'

export interface BentoSectionProps {
  isLoading?: boolean
  children?: React.ReactNode
}

const ANIMATION_TIME_MS = 300

export function BentoSection({
  isLoading = false,
  children,
}: BentoSectionProps) {
  const animationRef = React.useRef<HTMLDivElement>(null)

  const updateSkeleton = () => {
    if (Math.random() > 0.3) {
      animationRef.current?.classList.remove(styles.hideSkeleton)
    } else {
      animationRef.current?.classList.add(styles.hideSkeleton)
    }
  }

  React.useEffect(() => {
    if (isLoading) {
      updateSkeleton()
      const interval = setInterval(updateSkeleton, ANIMATION_TIME_MS * 2)
      return () => clearTimeout(interval)
    } else {
      animationRef.current?.classList.remove(styles.hideSkeleton)
    }
  }, [isLoading])

  return (
    <div ref={animationRef} className={styles.bentoSection}>
      {!isLoading && children}
    </div>
  )
}
