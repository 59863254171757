import { useNavigate } from 'react-router-dom'
import { Carousel } from '@mantine/carousel'
import { Anchor, Box, Group, Title } from '@mantine/core'

type CategoryPageLink = {
  url: string
  label: string
}

// Default value is 24, which is too small for touchscreens
// Mantine does not allow { base: 24, lg: 40 } syntax so use 40 for both
const CAROUSEL_CONTROL_SIZE = 40
export interface SOVCarouselProps<ItemType> {
  title: string
  items: ItemType[]
  renderCard: (item: ItemType, index: number) => JSX.Element
  selectItem: (item: ItemType, index: number) => void
  categoryPageLink?: CategoryPageLink
}

export function SOVCarousel<T>({
  title,
  items,
  renderCard,
  selectItem,
  categoryPageLink,
}: SOVCarouselProps<T>) {
  const navigate = useNavigate()

  if (items.length === 0) return null

  return (
    <>
      <Group align="center" justify="space-between" mt="lg">
        <Title order={2}>{title}</Title>
        {categoryPageLink && (
          <Anchor onClick={() => navigate(categoryPageLink.url)}>
            {categoryPageLink.label}
          </Anchor>
        )}
      </Group>
      <Carousel
        align="start"
        type="container"
        loop
        dragFree
        slideGap="xs"
        slideSize="fit-content"
        controlsOffset="0"
        mx={{ base: 'xs', lg: 0 }}
        controlSize={CAROUSEL_CONTROL_SIZE}
        styles={{
          controls: { margin: '0 calc(-1 * var(--mantine-spacing-sm))' },
          control: { opacity: 1 },
        }}
      >
        {items.map((item, index) => (
          <Carousel.Slide key={index} onClick={() => selectItem(item, index)}>
            <Box style={{ cursor: 'pointer' }} maw="200px">
              {renderCard(item, index)}
            </Box>
          </Carousel.Slide>
        ))}
      </Carousel>
    </>
  )
}
