import { ResultImage } from '@/features/ResultImage'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import { useLocalNavigation } from '@/features/SingleObjectView/hooks'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import type { VexcelResult } from '@/utils/types/result-types'

import styles from './vexcel.module.css'

export function VexcelObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as VexcelResult
  const images = (item.properties.vexcel_images?.vexcel_images ?? []).map(
    (x) => x.url,
  )

  const { localIndex, current, selectItem, toNext, toPrevious } =
    useLocalNavigation(images)

  return (
    <SingleObjectViewTemplate<VexcelResult, string>
      {...{
        item,
        hero: {
          vexcelImage: images.length > 0 ? current : undefined,
          vexcelSource: item.source,
          imageAlt: item.title,
          toNext,
          toPrevious,
          metaAction:
            images.length > 0 && localIndex !== undefined
              ? `${localIndex + 1} of ${images.length}`
              : undefined,
        },
        carousel: {
          items: images,
          title: 'All Images',
          renderCard: (image) => (
            <ResultImage
              item={{
                thumbnail: image,
                title: item.title,
                source: item.source,
              }}
              className={styles['image-container']}
            />
          ),
          selectItem,
        },
      }}
    />
  )
}
