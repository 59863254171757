import { normalizeUrl } from '@/api/helper'
import { fetchSignedS3URL } from '@/api/thumbnail/fetch-signed-s3-url'
import { getDataUrlFromProxy } from '@/api/thumbnail/get-data-url-from-proxy'
import { getProxiedUrl } from '@/api/thumbnail/get-proxied-url'
import type { ImageryResultPart } from '@/api/thumbnail/types'

const isDantiAPI = (url: string) => url.includes('danti.ai')
export const isDantiS3 = (url: string): boolean =>
  url.includes('.s3.') &&
  url.includes('.amazonaws.com') &&
  url.includes('danti')

export async function getThumbnailDataUrl(item: ImageryResultPart) {
  if (!item.thumbnail) {
    return
  }
  const url = new URL(item.thumbnail.toString())
  const isS3URL = isDantiS3(url.hostname)

  // Maintain backwards compatibility with old ortho layers that use S3 URLs
  if (
    isS3URL &&
    (url.pathname.includes('blacksky') || url.pathname.includes('up42'))
  ) {
    const normalizedUrl = normalizeUrl(url.toString())
    return getDataUrlFromProxy(normalizedUrl)
  }

  // Legacy Social + News use S3 URLs
  if (isS3URL) {
    const thumbnailUrl = await fetchSignedS3URL(item.thumbnail.toString())
    return thumbnailUrl || undefined
  }

  const thumbnailUrl = (await getProxiedUrl(item)) ?? ''
  if (!thumbnailUrl) {
    return
  }
  return isDantiAPI(thumbnailUrl)
    ? getDataUrlFromProxy(thumbnailUrl)
    : thumbnailUrl
}
