import { Button, Divider, Image, Stack, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { default as announcementImg } from '@/assets/images/announcement-danti-new-look.webp'

export type AnnouncementModalProps = {
  setAcknowledged: (value: boolean) => void
  forceInteraction?: boolean
}

export function AnnouncementModal({
  context,
  innerProps: { setAcknowledged },
}: ContextModalProps<AnnouncementModalProps>) {
  return (
    <Stack gap="sm">
      <Image src={announcementImg} alt="danti-new-look" />
      <Text>
        Now you can view the Explore page and the Map at the same time. This
        enables you to browse different types of data alongside the map. When
        you click on images, news, or other data, they will display on the map
        without the need to switch pages.
      </Text>
      <Divider />
      <Button
        variant="light"
        onClick={() => {
          setAcknowledged(true)
          context.closeAll()
        }}
        w={{ base: '100%', xs: 'fit-content' }}
        style={{ alignSelf: 'end' }}
      >
        Got it
      </Button>
    </Stack>
  )
}
