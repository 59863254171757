import { Paper } from '@mantine/core'
import {
  useHasResults,
  useInsightStatus,
  useIsInsightLoading,
  useSearchStatus,
} from '@/api/results'
import { InsightSectionLoader } from '@/features/InsightPanel/components/sections/InsightSectionLoader'
import { checkIsLoading } from '@/pages/ResultsOverview/check-is-loading'
import type React from 'react'

interface StatusAwareSkeletonProps {
  height?: string
  fallback?: React.ReactNode
  condition?: boolean
  children?: React.ReactNode
}

/**
 * Reads search status to correctly display a skeleton while searching.
 * Once the search is completed, uses the `condition` to determine whether to
 * display the children or null, or the fallback if the search returned no results.
 * @example
 * <StatusAwareSkeleton condition={hasResults} fallback={<NoResults />}>
 *   <ResultDisplay />
 * </StatusAwareSkeleton>
 */
export function StatusAwareSkeleton({
  children = null,
  condition = true,
  fallback = null,
}: StatusAwareSkeletonProps) {
  const status = useSearchStatus()

  const isLoading = checkIsLoading(status)
  const insightStatus = useInsightStatus()
  const isInsightLoading = useIsInsightLoading() || insightStatus === 'pending'
  const hasResults = useHasResults()

  if (isLoading || isInsightLoading) {
    return (
      isInsightLoading && (
        <Paper withBorder p="md" radius="md">
          {[1, 2, 3].map((index) => (
            <InsightSectionLoader key={index} />
          ))}
        </Paper>
      )
    )
  } else {
    if (condition) {
      return children
    } else if (status === 'success' && hasResults) {
      return fallback
    } else {
      return null
    }
  }
}
