import { getSearchRequest, postSearchRequest } from '@/api/danti-requests'
import type {
  CategoryResponse,
  ExecuteQueryParams,
  SearchResult,
} from '@/api/results/types'
import type { ResultWithId } from '@/utils/types/raw-result-types.ts'

export async function executeQuery(params: ExecuteQueryParams) {
  const response = await postSearchRequest(`v2/query?size=100`, params)
  if (!response) {
    throw new Error('Error in execute query network call')
  }
  return response.data as SearchResult
}

export async function getQueryResultsByCategory(
  queryId: string,
  category: string,
  options: {
    size: number
    start: number
  },
) {
  const { size, start } = options
  const stringOptions = { size: `${size}`, start: `${start}` }
  const optionsAsUrlParams = new URLSearchParams(stringOptions).toString()
  const response = await getSearchRequest(
    `v2/results/${queryId}/${category}?${optionsAsUrlParams}`,
  )
  return response.data as CategoryResponse
}

export async function getQueryResultsByQueryId(queryId: string) {
  const response = await getSearchRequest(`v2/results/${queryId}?size=20`)
  if (!response) {
    throw new Error('Error in execute query network call')
  }
  return response.data
}

export async function getInsightResult(queryId: string) {
  const response = await getSearchRequest(`v2/insight/${queryId}`)
  return response.data
}

export async function getDocumentById(documentId: string) {
  const response = await getSearchRequest<ResultWithId>(`v2/doc/${documentId}`)
  if (!response) {
    throw new Error('Error in get document by id network call')
  }
  return response.data
}
