import { getCoreLogicRiskDataFields } from '@/api/results'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { formatDatetime } from '@/utils/format-datetime'
import type { CoreLogicRiskResult } from '@/utils/types/result-types'

export function CoreLogicRiskObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as CoreLogicRiskResult

  return (
    <SingleObjectViewTemplate<CoreLogicRiskResult, string>
      {...{
        item,
        hero: {
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: {
          title: item.properties.address,
          details:
            'Comprehensive property risk assessment including crime, natural disasters, and environmental hazards.',
          data: getCoreLogicRiskDataFields(item),
        },
      }}
    />
  )
}
