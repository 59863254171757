import { Navigate, type PathRouteProps } from 'react-router-dom'
import { useLastAttemptedRoute } from '@/features/AppRouting/hooks'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { type FeatureFlagKeys, ROUTES } from '@/utils/constants'

interface FlaggedRouteProps extends PathRouteProps {
  feature: FeatureFlagKeys
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  predicate?: (featValue: any) => boolean
  fallbackPath?: string
  isProtected?: boolean
}

export function FlaggedRoute({
  element,
  feature,
  fallbackPath,
  isProtected,
}: FlaggedRouteProps) {
  const featureValue = useFeatureFlag(feature)
  const [, setLastAttemptedRoute] = useLastAttemptedRoute()
  const { isAuthorizedUser, hasResourceAccess } = useDantiAuth()

  if (hasResourceAccess()) {
    if (isProtected) {
      if (isAuthorizedUser()) {
        return element
      } else {
        setLastAttemptedRoute(window.location.pathname)

        return <Navigate to={ROUTES.accessDenied} replace />
      }
    }

    return featureValue ? (
      element
    ) : (
      <Navigate to={fallbackPath ?? ROUTES.notFound} replace />
    )
  } else {
    return <Navigate to={fallbackPath ?? ROUTES.notFound} replace />
  }
}
