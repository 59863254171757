import { SimpleGrid } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { CollectionItemGridResult } from './CollectionItemGridResult'

import styles from '../../../collections.module.css'

export interface CollectionDetailGridProps {
  items: CollectionItem[]
  columns?: number
}

export function CollectionDetailGrid({
  items,
  columns,
}: CollectionDetailGridProps) {
  return (
    <SimpleGrid
      className={styles.collectionItemGrid}
      {...(columns && { columns })}
    >
      {items.map((item) => (
        <CollectionItemGridResult item={item} key={item.id} />
      ))}
    </SimpleGrid>
  )
}
