import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { formatDatetime } from '@/utils/format-datetime'
import type { RegridResult } from '@/utils/types/result-types'

export function RegridObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as RegridResult

  return (
    <SingleObjectViewTemplate<RegridResult, null>
      {...{
        item,
        hero: {
          geometry: item.geometry,
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: { title: item.properties.address },
      }}
    />
  )
}
