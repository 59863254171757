import { getRequest, getRequestNoAuth } from '@/api/danti-requests'
import { MIME, PNG_PROVIDERS } from '@/api/thumbnail/constants'
import { debug } from '@/utils/debug'
import { captureException } from '@sentry/react'
import type { AxiosRequestConfig } from 'axios'

export async function getDataUrlFromProxy(thumbnailUrl: string) {
  const get = thumbnailUrl.includes('amazonaws.com')
    ? getRequestNoAuth
    : getRequest
  const config: AxiosRequestConfig = {
    responseType: 'arraybuffer',
  }

  if ([...PNG_PROVIDERS].some((provider) => thumbnailUrl.includes(provider))) {
    config.headers = {
      Accept: 'image/png, image/jpeg', // Some UP42 images are jpeg
    }
  }

  const response = await get<ArrayBufferLike>(thumbnailUrl, config)
  // grab the mime-type so that we know how to handle the data url
  const contentType = response.headers['content-type'] as string
  const mimeType = MIME.exec(contentType)
  if (!mimeType) {
    return
  }
  let base64
  switch (mimeType[1]) {
    case 'image/tiff':
      debug("sending a tiff to the browser, this probably won't work")
      return
    case 'image/png':
    case 'image/jpeg':
      try {
        const dataArray = new Uint8Array(response.data as ArrayBuffer)
        const dataString = dataArray.reduce(
          (data, byte) => data + String.fromCodePoint(byte),
          '',
        )
        base64 = window.btoa(dataString)
      } catch (error) {
        console.error('Failed to parse image data', { e: error })
        captureException(error)
      }
      return new URL(`data:${mimeType[1]};base64,${base64}`).toString()
  }
}
