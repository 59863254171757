import {
  ActionIcon,
  Box,
  Button,
  CheckIcon,
  Combobox,
  FocusTrap,
  Group,
  Input,
  Pill,
  PillsInput,
  Popover,
  Text,
  useCombobox,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import {
  MAP_LAYER_OPTIONS,
  useActiveLayers,
  useSetActiveLayers,
} from '@/stores/map-store'

import styles from '@/features/DantiMap/danti-map.module.css'

type LayerPickerProps = {
  buttonStyle: Record<string, unknown>
}

const MAX_LAYER_INPUT_ITEMS = 2

export function LayerPicker({ buttonStyle }: LayerPickerProps) {
  const activeLayers = useActiveLayers()
  const setActiveLayers = useSetActiveLayers()
  const combobox = useCombobox({ opened: true })

  const handleLayerRemove = (layerToRemove: string) =>
    setActiveLayers(activeLayers.filter((al) => al !== layerToRemove))

  const handleLayerSelect = (selectedLayer: string) =>
    activeLayers.includes(selectedLayer)
      ? handleLayerRemove(selectedLayer)
      : setActiveLayers([...activeLayers, selectedLayer])

  const displayedLayerSelections = activeLayers
    .slice(
      0,
      MAX_LAYER_INPUT_ITEMS === activeLayers.length
        ? MAX_LAYER_INPUT_ITEMS
        : MAX_LAYER_INPUT_ITEMS - 1,
    )
    .map((layer) => (
      <Pill
        key={layer}
        withRemoveButton
        onRemove={() => handleLayerRemove(layer)}
        tt="capitalize"
      >
        {layer}
      </Pill>
    ))

  const layerInputOptions = Object.values(MAP_LAYER_OPTIONS).map((option) => (
    <Combobox.Option
      value={option}
      key={option}
      active={activeLayers.includes(option)}
    >
      <Group gap="xs">
        {activeLayers.includes(option) ? <CheckIcon size={8} /> : null}
        <Text component="span" size="sm">
          {option}
        </Text>
      </Group>
    </Combobox.Option>
  ))

  return (
    <Popover withArrow shadow="md" width="250">
      <Popover.Target>
        <Box>
          <Button
            {...buttonStyle}
            visibleFrom="sm"
            leftSection={<Icon name="stacks" size={16} />}
          >
            Layers ({activeLayers.length})
          </Button>
          <ActionIcon {...buttonStyle} size="md" hiddenFrom="sm">
            <Icon name="stacks" size={16} />
          </ActionIcon>
        </Box>
      </Popover.Target>
      <Popover.Dropdown p={0}>
        <Combobox
          store={combobox}
          onOptionSubmit={handleLayerSelect}
          withinPortal={false}
          offset={4}
        >
          <Combobox.DropdownTarget>
            <PillsInput pointer classNames={{ input: styles.layersInput }}>
              <Pill.Group>
                {activeLayers.length > 0 ? (
                  <>
                    {displayedLayerSelections}
                    {activeLayers.length > MAX_LAYER_INPUT_ITEMS && (
                      <Pill>
                        +{activeLayers.length - (MAX_LAYER_INPUT_ITEMS - 1)}{' '}
                        more
                      </Pill>
                    )}
                  </>
                ) : (
                  <Input.Placeholder>
                    Select layers to display
                  </Input.Placeholder>
                )}

                <Combobox.EventsTarget>
                  <FocusTrap>
                    <PillsInput.Field
                      type="hidden"
                      onKeyDown={(event) => {
                        if (event.key === 'Backspace') {
                          if (activeLayers.length === 0) return

                          const lastItem = activeLayers[activeLayers.length - 1]
                          handleLayerRemove(lastItem)
                        }
                      }}
                    />
                  </FocusTrap>
                </Combobox.EventsTarget>
              </Pill.Group>
            </PillsInput>
          </Combobox.DropdownTarget>

          <Combobox.Dropdown>
            <Combobox.Options tt="capitalize">
              {layerInputOptions}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Popover.Dropdown>
    </Popover>
  )
}
