import { Image } from '@mantine/core'
import { getPropertyDataFields } from '@/api/results'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import { useLocalNavigation } from '@/features/SingleObjectView/hooks'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { formatDatetime } from '@/utils/format-datetime'
import type { HazardPropertyResult } from '@/utils/types/result-types'

import styles from './single-object-view.module.css'

export function HazardPropertyObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as HazardPropertyResult
  const images = item.properties.images ?? []

  const { localIndex, current, selectItem, toNext, toPrevious } =
    useLocalNavigation(images)

  return (
    <SingleObjectViewTemplate<HazardPropertyResult, string>
      {...{
        item,
        hero: {
          staticImage: images.length > 0 ? current : undefined,
          imageAlt: item.title,
          toNext,
          toPrevious,
          metaDetails: [formatDatetime(item.authoredOn)],
          metaAction:
            images.length > 0 && localIndex !== undefined
              ? `${localIndex + 1} of ${images.length}`
              : undefined,
        },
        content: {
          title: item.properties.address,
          details: item.properties.publicRemarks,
          data: getPropertyDataFields(item),
        },
        carousel: {
          items: images,
          title: 'All Property Images',
          renderCard: (image) => (
            <Image
              src={image}
              alt="ALS image"
              className={styles['prop-category-card']}
            />
          ),
          selectItem,
        },
      }}
    />
  )
}
