import { ROUTES } from '@/utils/constants'
import type { InsightResult } from '@/utils/types/result-types'
import type { BaseSection, SectionType } from '../types/insight-types'

function getSectionType(section: BaseSection): SectionType {
  // Handle nested sections first
  if (section.subsections && section.subsections.length > 0) {
    if (section.title === 'Whitespace Analytic Results') {
      return 'whitespace'
    }
    return 'nested'
  }

  // Then check array content
  if (Array.isArray(section.content)) {
    const contentArray = section.content
    // Check if array contains objects with title/content structure
    if (
      contentArray.every(
        (item) =>
          typeof item === 'object' && 'title' in item && 'content' in item,
      )
    ) {
      return 'keyValue'
    }
    const hasLinks = contentArray.every(
      (item) =>
        typeof item === 'string' &&
        (item.includes(ROUTES.explore) ||
          item.includes(ROUTES.results) ||
          item.includes('https://') ||
          item.includes('http://')),
    )
    return hasLinks ? 'links' : 'list'
  }

  if (typeof section.content === 'string') {
    return 'text'
  }

  if (section.content && typeof section.content === 'object') {
    const contentArray = Object.values(section.content)
    const hasLinks = contentArray.every(
      (item) =>
        typeof item === 'string' &&
        (item.includes(ROUTES.explore) ||
          item.includes(ROUTES.results) ||
          item.includes('https://') ||
          item.includes('http://')),
    )
    return hasLinks ? 'links' : 'keyValue'
  }

  return 'text'
}

function formatSubsection(section: BaseSection): BaseSection[] {
  const { subsections } = section
  const subsectionList: BaseSection[] = []

  if (subsections) {
    subsections.forEach((subsection: BaseSection) => {
      subsectionList.push({
        title: subsection.title,
        type: getSectionType(subsection),
        content: subsection.content,
        subsections: subsection.subsections ? formatSubsection(subsection) : [],
      })
    })
  }

  return subsectionList
}

function formatSection(section: BaseSection): BaseSection | null {
  const { title, content, subsections } = section

  // Skip empty sections
  if (
    !content ||
    (Array.isArray(content) && content.length === 0) ||
    (typeof content === 'object' && Object.keys(content).length === 0)
  ) {
    return null
  }

  return {
    title,
    type: getSectionType(section),
    content,
    subsections: subsections ? formatSubsection(section) : [],
  }
}

export function useInsightFormatter(result: InsightResult): BaseSection[] {
  if (!result?.description) return []

  const sections = Array.isArray(result.description)
    ? result.description
    : [result.description]

  return (sections as unknown[])
    .filter(
      (section): section is BaseSection =>
        typeof section === 'object' &&
        section !== null &&
        'title' in section &&
        'content' in section,
    )
    .map(formatSection)
    .filter((section): section is BaseSection => section !== null)
}
