import type { PostHogEvent } from '@/features/Analytics/types'
import type { CategoryTypes } from '@/utils/types/raw-result-types'
import type { ResultCategories } from '@/utils/types/result-types'

// This file is used to define the 3 types of events we send to our analytics:
// 1. Simple events send a string event name and no other data
// 2. Complex events include additional properties or dynamism (or both)
//    a. Static names with or without properties
//    b. Dynamic names based on variables with or without properties

// When creating events please keep the following in mind:
// 1. Event names:
//    - Use title case
//    - Use double quotes for UI text
//    - No user-created strings (e.g. collection names)
//    - Dynamic values must have their arguments defined in the variables array
// 2. Event properties:
//    - String values identical to the expected object properties
//    - No sensitive data (e.g. email addresses)
//    - Currently need to include every variable used in a dynamic event name

export const USER_EVENTS: Record<string, PostHogEvent> = {
  LOGIN: {
    CLICK: 'Clicked "Log in"',
    FORGOT_PASSWORD: 'Clicked "Forgot Password"',
    SUBMIT_OTP: 'Submitted One-Time-Code',
    LOGOUT: 'Logged out',
  },
  FEEDBACK: {
    INSIGHT_OPENED: 'Opened Insight Feedback',
    INSIGHT_CLOSED: 'Closed Insight Feedback',
    INSIGHT_SUBMITTED: {
      eventName: 'Submitted Insight Feedback',
      variables: ['id', 'queryId', 'feedback', 'customFeedback'],
      properties: ['id', 'queryId', 'feedback', 'customFeedback'],
    },
    SUBMITTED: 'Submitted Feedback',
  },
  HEADER: {
    CLICKED: {
      eventName: (headerItem: string) => `Clicked "${headerItem}" in Header`,
      variables: ['headerItem'],
      properties: ['headerItem'],
    },
  },
  USER_MENU: {
    CLICKED: {
      eventName: (menuItem: string) => `Clicked "${menuItem}" in User Menu`,
      variables: ['menuItem'],
      properties: ['menuItem'],
    },
  },
  EXPLORE: {
    ALL_OF_TYPE: {
      eventName: (category: CategoryTypes) => `Clicked "All ${category}"`,
      variables: ['category'],
      properties: ['category'],
    },
    EXPAND_MAP: 'Clicked "Expand map"',
    SORT_RELEVANCE: 'Sorted by "Relevance"',
    SORT_DATE: 'Sorted by "Date"',
    FILTER_BY: {
      eventName: (filterName: string) => `Filtered by "${filterName}"`,
      variables: ['filterName'],
      properties: ['filterName', 'value', 'isOn'],
    },
    ADVANCED_SEARCH: {
      eventName: 'Triggered "Advanced Search"',
      properties: ['filters'],
    },
    SAVE_SEARCH: 'Clicked "Save Search"',
  },
  RESULTS: {
    CLICKED: {
      eventName: (resultCategory: ResultCategories) =>
        `Clicked "${resultCategory}" result`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },
  },
  RESULT_MENU: {
    CLICKED: {
      eventName: (resultCategory: ResultCategories, actionLabel: string) =>
        `Clicked "${resultCategory}" result menu: "${actionLabel}"`,
      variables: ['resultCategory', 'actionLabel'],
      properties: ['resultCategory', 'actionLabel'],
    },
  },
  DETAIL: {
    NAV_NEXT: 'Navigated to next item',
    NAV_PREV: 'Navigated to previous item',
    ADD_TO_COLLECTION: {
      eventName: (resultCategory: ResultCategories) =>
        `Added "${resultCategory}" result to Saved Search`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },

    DOWNLOAD: {
      eventName: (resultCategory: ResultCategories) =>
        `Started Download of "${resultCategory}" result`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },
    ANALYZE: {
      eventName: (resultCategory: ResultCategories) =>
        `Started Analysis of "${resultCategory}" result`,
      variables: ['resultCategory'],
      properties: ['resultCategory'],
    },
  },
  COLLECTIONS: {
    ADD: 'New Saved Search created',
    SEARCH: {
      eventName: 'Searched Saved Searches',
      properties: ['searchQuery'],
    },
    SHARE: {
      eventName: 'Shared Saved Search',
      properties: ['collectionName', 'collectionId'],
    },
    FILTER: {
      eventName: (filterName: string) =>
        `Filtered Saved Searches by "${filterName}"`,
      variables: ['filterName'],
      properties: ['filterName', 'filterValue'],
    },
    EDIT_CONFIG: {
      eventName: 'Opened Saved Search config',
      properties: ['collectionName'],
    },
    DELETE: {
      eventName: 'Deleted Saved Search',
      properties: ['collectionName'],
    },
  },
  HISTORY: {
    SEARCH: 'Searched "History"',
    SELECT: 'Selected "History" item',
    REMOVE: 'Removed "History" item',
    REMOVE_ALL: 'Removed all "History" items',
    RERUN_SEARCH: 'Reran "History" search',
  },
  TIMELINE: {
    CLICKED: 'Clicked "Timeline"',
  },
  SEARCH: {
    SUBMIT: {
      eventName: 'Submitted Search Query',
      properties: ['query', 'filters'],
    },
  },
}
