import { IMAGE_SOURCES } from '@/utils/constants'
import type { OrthoImageryLayer } from '@/utils/types/ortho-imagery-types'
import type { ImageryResult } from '@/utils/types/result-types'
import * as Sentry from '@sentry/react'
import { isGegd, makeGegdOrthoLayer } from './gegd'
import { makePngImageOrthoLayer } from './make-png-image-ortho-layer'
import { isPlanet, makePlanetOrthoLayer } from './planet'
export const makeOrthoLayer = (
  ir: ImageryResult,
): OrthoImageryLayer | undefined => {
  if (!ir.thumbnail && Object.keys(ir.asset).length === 0) {
    return
  }
  /**
   * GEGD + Planet are the only tile providers and are handled as raster layers.
   * All other providers are rendered as image layers.
   *
   * GEGD is rendered by proxying Maxar's WMS service
   */
  if (isGegd(ir)) {
    return makeGegdOrthoLayer(ir)
  }
  /**
   * Planet has a thumbnail image for previews and a zxy tile service for rendering on the map.
   */
  if (isPlanet(ir)) {
    return makePlanetOrthoLayer(ir)
  } else if (ir.source in IMAGE_SOURCES) {
    /**
     * All other providers are static PNG images
     */
    return makePngImageOrthoLayer(ir)
  }

  Sentry.captureMessage(
    `Could not find ortho layer provider for Image ID ${ir.documentId}`,
  )
  return undefined
}
