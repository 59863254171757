import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Group,
  Stack,
  TagsInput,
  Text,
  Textarea,
  Title,
} from '@mantine/core'
import { isEmail } from '@mantine/form'
import type { ContextModalProps } from '@mantine/modals'
import type { ShareRoleType } from '@/api/collections'
import type { Collection } from '@/api/collections/types'
import { useUsers } from '@/api/user'
import { Icon } from '@/components/lib/Icon'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { ShareRoleSelect } from './ShareRoleSelect'
import { useShareModalData } from './use-share-modal-data'
import { UserList } from './UserList'

export function ShareModal({
  context,
  id,
  innerProps: { collection },
}: ContextModalProps<{ collection: Collection }>) {
  const shouldSupportAutocompletion = useFeatureFlag(
    FEATURE_FLAG_VALUES.accountManagement,
  )
  const { users, setSearchTerm, searchTerm } = useUsers()

  const {
    form,
    isErrorState,
    onSubmit,
    closeModal,
    handleRoleUpdate,
    handleUnshare,
  } = useShareModalData({
    context,
    id,
    innerProps: { collection },
  })

  const emailInputProps = form.getInputProps('emails')
  const emails = emailInputProps.value as string[]

  const roleInputProps = form.getInputProps('role')
  const role = roleInputProps.value as ShareRoleType
  const [searchValue, setSearchValue] = React.useState('')

  // If remaining search is a valid email, set it to emails
  const syncSearchValue = () => {
    const invalidEmail = isEmail('invalid email')(searchValue)
    if (!invalidEmail) {
      form.insertListItem('emails', searchValue)
      setSearchValue('')
    }
  }

  const syncSearchTerm = () => {
    const invalidEmail = isEmail('invalid email')(searchTerm)
    if (!invalidEmail) {
      form.insertListItem('emails', searchTerm)
      setSearchTerm('')
    }
  }

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    captureEvent(USER_EVENTS.COLLECTIONS.SHARE, {
      collectionName: collection.name,
      collectionId: collection.id,
    })

    onSubmit(event)
  }

  const userEmails = React.useMemo(
    () => (searchTerm === '' ? [] : users.map((user) => user.email)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users],
  )

  if (isErrorState) {
    return <>Error on submit</>
  }

  return (
    <form onSubmit={handleSubmit}>
      <Title mb="sm">Share</Title>
      <Stack gap="md">
        <Divider />
        <Box>
          <Group
            px="xs"
            gap="xs"
            bd="1px solid gray"
            wrap="nowrap"
            w="fit-content"
            maw="100%"
            style={{ borderRadius: 'var(--mantine-radius-lg)' }}
          >
            <Icon name="folder" size="xs" />
            <Text miw={0} truncate>
              {collection?.name}
            </Text>
          </Group>
        </Box>
        <Stack>
          <TagsInput
            searchValue={shouldSupportAutocompletion ? searchTerm : searchValue}
            onSearchChange={
              shouldSupportAutocompletion ? setSearchTerm : setSearchValue
            }
            onBlur={
              shouldSupportAutocompletion ? syncSearchTerm : syncSearchValue
            }
            data={userEmails}
            placeholder={emails.length > 0 ? '' : 'Add by email'}
            withAsterisk
            splitChars={[',', ' ']}
            error={emailInputProps.error as string | undefined}
            value={emails}
            onChange={(x) => {
              form.setFieldValue('emails', x)
            }}
            rightSectionWidth={118}
            leftSection={<Icon name="search" />}
            rightSection={
              <ShareRoleSelect
                role={role}
                setRole={(role) => form.setFieldValue('role', role)}
              />
            }
          />
        </Stack>
        <Divider />
        {emails.length === 0 ? (
          <UserList
            collection={collection}
            onRoleUpdate={handleRoleUpdate}
            onUnshare={handleUnshare}
          />
        ) : (
          <>
            <Textarea
              label={
                <Text size="md" fw="bold" mb="xs">
                  Message
                </Text>
              }
              placeholder="Instructions for Recipient"
              size="sm"
              {...form.getInputProps('message')}
              data-cy="share-collection-emails"
            />
            <Checkbox
              label="I acknowledge that recipients of this email are authorized to view this imagery"
              {...form.getInputProps('consent')}
            />
          </>
        )}

        <Divider />
        <Group justify="space-between">
          <Button variant="subtle" c="black" fw={500} onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" fw={500}>
            Done
          </Button>
        </Group>
      </Stack>
    </form>
  )
}
