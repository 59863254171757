import React from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import { openFileUploadModal } from '@/components/lib/FileUpload/opener'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { openCreateCollectionModal } from '@/features/AppLayout/Modals'
import { useCollectionsState } from '@/stores/collections'

export function useCollectionsHeader() {
  const { search } = useCollectionsState()

  const [searchTerm, setSearchTerm] = React.useState('')
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)

  React.useEffect(() => {
    if (debouncedSearchTerm !== '') {
      captureEvent(USER_EVENTS.COLLECTIONS.SEARCH, {
        searchQuery: debouncedSearchTerm,
      })
    }

    search(debouncedSearchTerm)
  }, [search, debouncedSearchTerm])

  const handleNewCollectionClick = openCreateCollectionModal

  return {
    handleNewCollectionClick,
    handleUploadFileClick: () => openFileUploadModal(null),
    searchTerm,
    setSearchTerm,
  }
}
