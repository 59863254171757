import {
  ActionIcon,
  type MantineColor,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuTarget,
} from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { Icon } from '@/components/lib/Icon'
import { useResultMenuActions } from '@/features/ResultActions/hooks'
import type { ActionMenuOption } from '@/features/ResultActions/types'

import styles from './result-actions.module.css'

export interface ResultActionsProps<Type> {
  hideActions: ActionMenuOption[]
  result: Type
  iconClassName?: string
  item?: CollectionItem
  color?: MantineColor
  centered?: boolean
}

export function ResultActions<Type>({
  hideActions,
  result,
  item,
  color = 'dark',
  centered = false,
}: ResultActionsProps<Type>) {
  const actions = useResultMenuActions({ result, hideActions, item })

  return (
    <Menu position="bottom-end" offset={2}>
      <MenuTarget>
        <ActionIcon
          {...(!centered && { pos: 'absolute', top: 4, right: 4 })}
          variant="filled"
          color={color}
          size="md"
          onClick={(event) => event.stopPropagation()}
        >
          <Icon name="more_vert" />
        </ActionIcon>
      </MenuTarget>
      <MenuDropdown
        bg="darken.7"
        styles={{
          dropdown: {
            border: 'none',
            backdropFilter: 'blur(1.5px)',
          },
        }}
      >
        {actions.map(({ label, icon, onClick, ...other }) => (
          <MenuItem
            key={label}
            {...other}
            leftSection={<Icon name={icon} />}
            onClick={onClick}
            classNames={{ item: styles.menuItem, itemLabel: styles.menuItem }}
          >
            {label}
          </MenuItem>
        ))}
      </MenuDropdown>
    </Menu>
  )
}
