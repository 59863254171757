import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SEARCH_STYLE } from '@/features/Search/constants'
import {
  useSetStoredLocation,
  useSetStoredSplitSearch,
} from '@/stores/queries-store'
import { ROUTES } from '@/utils/constants'
import { useAutoCompleteValues } from './use-autocomplete-values'
import { useInputFields } from './use-input-fields'
import { useLocationField } from './use-location-field'

interface UseSplitSearchProps {
  doSplitSearch: (
    formattedAddress: string,
    query: string,
    subject: string,
    location: {
      formattedAddress: string
      placeId: string
    },
  ) => void
  ref: React.Ref<unknown>
}

export const useSplitSearch = ({ doSplitSearch, ref }: UseSplitSearchProps) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const setStoredLocation = useSetStoredLocation()
  const setStoredSplitSearch = useSetStoredSplitSearch()

  const inputs = useInputFields()
  const autoComplete = useAutoCompleteValues(inputs.locationValue)
  const locationProps = useLocationField(inputs, autoComplete)

  const isExplore = pathname === '/'

  const clearLocationValue = () => {
    inputs.setLocationValue('')
    autoComplete.setSuggestionSelected(false)
    setStoredLocation(null)
    setStoredSplitSearch(null)
  }

  const splitSearchArgs = [
    autoComplete.formattedAddress,
    inputs.locationValue,
    inputs.subjectValue,
    autoComplete.searchArgs,
  ] as const

  const handleSetQuery = (value: string) => {
    if (!value) {
      return
    }
    void doSplitSearch(...splitSearchArgs)
    inputs.blur()
    const params = new URLSearchParams({ search: value })
    const pathname = window.location.pathname.toLowerCase()

    if (pathname.startsWith('/map')) {
      navigate(`/map?${params.toString()}`)
    } else {
      navigate(`${ROUTES.explore}?${params.toString()}`)
    }
  }

  const handleSubmit = (searchStyle: SEARCH_STYLE) => {
    let location = inputs.locationValue.trim()

    if (
      searchStyle == SEARCH_STYLE.Location &&
      !autoComplete.suggestionSelected
    ) {
      // If the user hasn't selected an autocomplete, auto select the highest relevant one
      const data = locationProps.data.filter((x) => x.value !== 'error')
      if (data.length > 0) {
        location = data[0].label
        locationProps.onChange(location)
        autoComplete.setErrorMessage('')
      } else {
        autoComplete.setErrorMessage('No locations found')
        return
      }
    }

    const subject = inputs.subjectValue ? `${inputs.subjectValue}` : ''
    const items = [location, subject].filter((x) => x.length > 0)

    if (items.length > 0) {
      setStoredSplitSearch({
        location,
        subject,
      })
      handleSetQuery(items.join(' - '))
    }
  }

  React.useImperativeHandle(ref, () => ({
    handleSubmit,
    focus: inputs.focus,
  }))

  return {
    inputs,
    autoComplete,
    locationProps,
    handleSetQuery,
    clearLocationValue,
    isExplore,
    handleSubmit,
  }
}
