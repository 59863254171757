import { Box } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import type { SymbolName } from '@/utils/types/material-icons.ts'
import { classes } from '@/utils/ui-helpers'

import styles from './bento-card.module.css'

export interface BentoCardIconParams {
  icon?: string
  svgIcon?: React.ReactNode
  socialIcon?: React.ReactNode
}
export const BentoCardIcon = ({
  icon,
  svgIcon,
  socialIcon,
}: BentoCardIconParams) => {
  let iconElement

  if (icon) {
    iconElement = (
      <Icon name={icon as SymbolName} size="2.666rem" className={styles.icon} />
    )
  } else if (svgIcon) {
    iconElement = (
      <Box className={classes(styles.icon, styles.svgIcon)}>{svgIcon}</Box>
    )
  } else if (socialIcon) {
    iconElement = (
      <Box className={classes(styles.icon)} m="xxs">
        {socialIcon}
      </Box>
    )
  }

  return iconElement
}

export default BentoCardIcon
