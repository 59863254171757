import { SCORE_COLORS } from '@/theme/colors'
import { RISK_ICONS } from '@/utils/svg-icons'
import type {
  AttomPermit,
  AttomResult,
  CoreLogicPropertyResult,
  CoreLogicRiskResult,
  HazardPropertyResult,
  HazardRisk,
  HazardRiskResult,
} from '@/utils/types/result-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

export const getPermitDescription = (result: AttomPermit) => {
  const { description } = result
  let out = description || ''
  if (out.startsWith(': ')) {
    out = out.slice(2)
  }
  return out.toLowerCase()
}

export const getPropertyDataFields = (item: HazardPropertyResult) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  const listPrice = item.properties.listPrice
    ? USDollar.format(Number.parseInt(item.properties.listPrice))
    : 'N/A'
  return [
    { title: 'bedrooms', value: item.properties.bedroomsTotal },
    { title: 'bathrooms', value: item.properties.bathsTotal },
    { title: 'type', value: item.properties.useCode },
    { title: 'hvac', value: item.properties.coolingFeatures },
    { title: 'list sale price', value: listPrice },
    { title: 'list date', value: item.properties.listDate },
    {},
  ]
}

export const getCoreLogicRiskDataFields = (item: CoreLogicRiskResult) => {
  return [
    // Crime Risk Section
    {
      title: 'Overall Crime Risk',
      value: `${item.properties.crimeRisk?.riskIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Property Crime',
      value: `${item.properties.crimeRisk?.propertyCrimeIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Burglary Risk',
      value: `${item.properties.crimeRisk?.propertyCrimeDetail?.burglaryIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Larceny Risk',
      value: `${item.properties.crimeRisk?.propertyCrimeDetail?.larcenyIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Violent Crime',
      value: `${item.properties.crimeRisk?.violentCrimeIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Murder Risk',
      value: `${item.properties.crimeRisk?.violentCrimeDetail?.murderIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Assault Risk',
      value: `${item.properties.crimeRisk?.violentCrimeDetail?.assaultIndex ?? 'N/A'}/100`,
    },
    {
      title: 'Robbery Risk',
      value: `${item.properties.crimeRisk?.violentCrimeDetail?.robberyIndex ?? 'N/A'}/100`,
    },
    // Natural Hazards Section
    {
      title: 'Earthquake Risk',
      value: item.properties.earthquakeRisk
        ? `${item.properties.earthquakeRisk.RiskDescription} (${item.properties.earthquakeRisk.RiskScore}/100)`
        : 'N/A',
    },
    {
      title: 'Earthquake Damage',
      value: item.properties.earthquakeRisk?.MMIDescriptionOfDamage ?? 'N/A',
    },
    {
      title: 'Flood Risk',
      value: item.properties.floodRisk
        ? `${item.properties.floodRisk.riskRating} (${item.properties.floodRisk.riskScore}/100)`
        : 'N/A',
    },
    {
      title: 'Flood Zone',
      value: item.properties.floodRisk?.floodZone ?? 'N/A',
    },
    {
      title: 'Hail Risk',
      value: item.properties.hailRisk
        ? `${item.properties.hailRisk.riskRating} (${item.properties.hailRisk.riskScore}/100)`
        : 'N/A',
    },
    {
      title: 'Hail Claim Frequency',
      value: item.properties.hailRisk?.claimFrequency
        ? `${item.properties.hailRisk.claimFrequency} claims/year`
        : 'N/A',
    },
    {
      title: 'Coastal Storm Risk',
      value: item.properties.coastalStormRisk?.riskDescription ?? 'N/A',
    },
  ]
}

export const getCoreLogicPropertyDataFields = (
  item: CoreLogicPropertyResult,
) => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return [
    // Building Characteristics
    {
      title: 'Market Value',
      value: item.properties.buildingCharacteristics?.marketValue
        ? USDollar.format(
            Number(item.properties.buildingCharacteristics.marketValue),
          )
        : 'N/A',
    },
    {
      title: 'Last Sale Price',
      value: item.properties.buildingCharacteristics?.lastSalePrice
        ? USDollar.format(
            Number(item.properties.buildingCharacteristics.lastSalePrice),
          )
        : 'N/A',
    },
    {
      title: 'Last Sale Date',
      value: item.properties.buildingCharacteristics?.lastSaleDate ?? 'N/A',
    },
    {
      title: 'Living Area',
      value: item.properties.buildingCharacteristics?.livingArea
        ? `${item.properties.buildingCharacteristics.livingArea} sq ft`
        : 'N/A',
    },
    {
      title: 'Bedrooms',
      value: item.properties.buildingCharacteristics?.bedrooms ?? 'N/A',
    },
    {
      title: 'Bathrooms',
      value: item.properties.buildingCharacteristics?.baths ?? 'N/A',
    },
    {
      title: 'Year Built',
      value: item.properties.buildingCharacteristics?.yrBuilt ?? 'N/A',
    },
    {
      title: 'Owner',
      value: item.properties.buildingCharacteristics?.owner ?? 'N/A',
    },
    {
      title: 'Owner Occupied',
      value:
        item.properties.buildingCharacteristics?.ownerOccupiedIndicator === 'Y'
          ? 'Yes'
          : 'No',
    },

    // Location Details
    {
      title: 'Distance to Shore',
      value: item.properties.distanceToShore?.attributes.distancefeet
        ? `${Math.round(item.properties.distanceToShore.attributes.distancefeet)} ft`
        : 'N/A',
    },
    {
      title: 'Nearest Fire Station',
      value: item.properties.distanceToFirestation?.distance
        ? `${item.properties.distanceToFirestation.distance} miles`
        : 'N/A',
    },

    // Building Features
    {
      title: 'Foundation Type',
      value: item.properties.foundationType?.foundationType ?? 'N/A',
    },
    {
      title: 'Roof Age',
      value: item.properties.roofAge?.ageOfRoof
        ? `${item.properties.roofAge.ageOfRoof} years`
        : 'N/A',
    },
  ]
}

export const getSortedRiskKeys = (result: HazardRiskResult) => {
  const { risks } = result.properties
  if (!risks) return []
  const riskKeys = Object.keys(risks)
  return riskKeys.sort((a: string, b: string) => {
    const scoreA = risks[a]?.score || ''
    const scoreB = risks[b]?.score || ''
    return scoreB.localeCompare(scoreA)
  })
}

export const getHighestRisks = (result: HazardRiskResult) => {
  const { risks } = result.properties
  const sortedRiskKeys = getSortedRiskKeys(result)
  const highestRisk = risks[sortedRiskKeys[0]]
  return sortedRiskKeys.filter((riskKey) => {
    const risk = result.properties.risks[riskKey]
    return risk.score === highestRisk.score
  })
}

export const getRiskColor = (risk: { score?: string }) =>
  risk.score ? SCORE_COLORS[risk.score] : SCORE_COLORS.F

export const getRiskCardDetails = (risk: HazardRisk) => ({
  title: risk.title,
  icon: RISK_ICONS[risk.key as keyof typeof RISK_ICONS],
  iconColor: getRiskColor(risk),
  leftDetail: risk.score,
  rightDetail: risk.risk,
})

export function getSortedPermits(result: AttomResult) {
  const permits = result.properties.buildingPermits || []
  return permits.sort((a: AttomPermit, b: AttomPermit) => {
    return dayjs(b.effectiveDate).unix() - dayjs(a.effectiveDate).unix()
  })
}
