import {
  isComplexEvent,
  isDynamicEventName,
  isStaticEventName,
} from '@/features/Analytics/type-checkers'
import type {
  EventProperties,
  PostHogEventObject,
} from '@/features/Analytics/types'
import type { User } from 'oidc-client-ts'
import { posthog } from 'posthog-js'

export function identifyUser(user: User) {
  posthog.identify(user.profile.sub, {
    email: user.profile.email,
    name: user.profile.name,
  })
}

function hasAllProps(properties: EventProperties, requiredProps: string[]) {
  return requiredProps.every((prop) => prop in properties)
}

function logEventPropertyError(eventName: string, properties: string[]) {
  console.error(
    `The following properties are required for the '${eventName}' event:`,
    properties.join(', '),
  )
}

function posthogCapture(event: string, properties?: EventProperties) {
  const propsWithPage = { pageName: window.document.title, ...properties }
  return posthog.capture(event, propsWithPage)
}

// This gets messy because the event names can be dynamic
export function captureEvent(
  event: PostHogEventObject,
  properties?: EventProperties,
) {
  if (isComplexEvent(event)) {
    if (isDynamicEventName(event)) {
      if (properties && hasAllProps(properties, event.properties)) {
        // Extract argument values from provided properties
        const args = event.variables.map((variable) => properties[variable])
        // Pass args to event name function
        const eventName = event.eventName(...args)
        return posthogCapture(eventName, properties)
      } else {
        // Error when properties are missing:
        const eventName = event.eventName(...event.variables)
        return logEventPropertyError(eventName, event.properties)
      }
    } else if (isStaticEventName(event)) {
      const eventName = event.eventName as string // Narrowing wasn't enough?
      return properties && hasAllProps(properties, event.properties)
        ? posthogCapture(eventName, properties)
        : logEventPropertyError(eventName, event.properties)
    }
  } else {
    // Otherwise, the event is a plain string
    return posthogCapture(event, properties)
  }
}
