import { useRef } from 'react'
import { Box, Divider } from '@mantine/core'
import {
  useMapboxMap,
  useOrthoLayers,
  usePointData,
} from '@/features/DantiMap/hooks'
import { mapboxToken } from '@/utils/constants'
import mapboxgl from 'mapbox-gl'
import {
  useBaseLayerSelection,
  useExtentsLayer,
  useFileDragging,
  useHandleMapToggling,
  useInitialBehavior,
  useLocationResponseLayer,
  useSearchDrawing,
  useZoomToNewOrtholayer,
} from './hooks'
import { MapControlPanel } from './MapControlPanel'
import { MapSearchActionButtons } from './MapSearchActionButtons'

import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import styles from '@/features/DantiMap/danti-map.module.css'

mapboxgl.accessToken = mapboxToken

export const Map = () => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const mapRef = useRef<mapboxgl.Map | null>(null)
  const searchHereRef = useRef<HTMLDivElement | null>(null)
  const { onClickCallback } = useSearchDrawing(mapRef, searchHereRef)
  const { getRootProps, getInputProps, open } = useFileDragging(mapRef)
  const { visiblePointLayers, showExtents } = useHandleMapToggling(mapRef)

  useMapboxMap(mapRef, mapContainerRef, { showMapControls: true })
  useInitialBehavior(mapRef)
  useLocationResponseLayer(mapRef)
  useExtentsLayer({ map: mapRef, showExtents })
  useBaseLayerSelection(mapRef)
  useOrthoLayers(mapRef, showExtents)
  usePointData(mapRef, visiblePointLayers)
  useZoomToNewOrtholayer(mapRef)

  return (
    <>
      <MapControlPanel map={mapRef} openFileDialog={open} />
      <Divider className={styles.divider} />
      <Box h="inherit" {...getRootProps()}>
        <input {...getInputProps()} />
        <Box h="100%" ref={mapContainerRef} />
      </Box>
      <Box display="none">
        <Box ref={searchHereRef}>
          <MapSearchActionButtons onClick={onClickCallback} />
        </Box>
      </Box>
    </>
  )
}
