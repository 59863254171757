/* eslint-disable no-case-declarations */
import { type MutableRefObject, useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toastError } from '@/components/toasts.ts'
import { emitter, EVENTS } from '@/events'
import { MAP_DRAW_MODES } from '@/features/DantiMap/constants.ts'
import { actOnMap, makeMapboxDraw } from '@/features/DantiMap/utils'
import { useSearch } from '@/features/Search/hooks'
import {
  UPLOADED_FEATURE_ID,
  useClearSearchHerePopup,
  useCurrentSearchGeometry,
  useDrawController,
  useDrawMode,
  useSearchHerePopup,
  useSetCurrentSearchGeometry,
  useSetDrawController,
  useSetIsDrawing,
  useSetSearchHerePopup,
  useSetUploadedFeature,
  useStopDrawingShape,
  useUploadedFeature,
} from '@/stores/map-draw-store'
import type { RadiusApiFilterValue } from '@/utils/types/filter-types.ts'
import type {
  DrawCreateEvent,
  DrawEvent,
  DrawUpdateEvent,
} from '@mapbox/mapbox-gl-draw'
import * as turf from '@turf/turf'
import type { Point, Polygon } from 'geojson'
import { type LngLatLike, type Map, Popup } from 'mapbox-gl'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import * as MapboxDrawGeodesic from 'mapbox-gl-draw-geodesic'

import './search-here-popup.css'

export const useSearchDrawing = (
  map: MutableRefObject<Map | null>,
  searchHereDiv: MutableRefObject<HTMLDivElement | null>,
) => {
  const searchHerePopup = useSearchHerePopup()
  const setSearchHerePopup = useSetSearchHerePopup()
  const clearSearchHerePopup = useClearSearchHerePopup()
  const uploadedFeature = useUploadedFeature()
  const setUploadedFeature = useSetUploadedFeature()
  const drawController = useDrawController()
  const setDrawController = useSetDrawController()
  const setIsDrawing = useSetIsDrawing()
  const setCurrentSearchGeometry = useSetCurrentSearchGeometry()
  const currentSearchGeometry = useCurrentSearchGeometry()
  const drawMode = useDrawMode()
  const stopDrawingShape = useStopDrawingShape()
  const [, setSearchParams] = useSearchParams()

  const processDrawEvent = useCallback<(event: DrawEvent) => void>(
    (event: DrawEvent) => {
      switch (event.type) {
        case 'draw.create':
        case 'draw.update':
          const drawEvent = event as DrawUpdateEvent | DrawCreateEvent
          const polygon = drawEvent.features[0].geometry as Polygon
          const geojson = drawEvent.features[0]
          setIsDrawing(false)
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
          if (MapboxDrawGeodesic.isCircle(geojson)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            const center = MapboxDrawGeodesic.getCircleCenter(geojson)
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            const radius = MapboxDrawGeodesic.getCircleRadius(geojson)
            setCurrentSearchGeometry({
              type: 'Point',
              coordinates: center as number[][],
              radius: radius as number,
            })
          } else {
            setCurrentSearchGeometry(polygon)
          }

          break
      }
    },
    [setCurrentSearchGeometry, setIsDrawing],
  )

  const { doLocationSearch, doRadiusSearch } = useSearch()

  // Clean up function
  useEffect(() => {
    return () => {
      setCurrentSearchGeometry(null)
      stopDrawingShape()
      setUploadedFeature(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addSearchHerePopup = useCallback(() => {
    const geometry =
      (currentSearchGeometry as Polygon | Point) || uploadedFeature
    if (!geometry || !map.current) return
    try {
      const bbox = turf.bbox(geometry)
      let coords = [bbox[2], bbox[1]]
      if ('radius' in geometry && geometry.type === 'Point') {
        const bottom = turf.destination(
          geometry.coordinates,
          geometry.radius as number,
          180,
        )
        coords = bottom.geometry.coordinates
      }

      const popup = new Popup({
        className: 'search-here-popup',
        anchor: 'top',
        maxWidth: 'none',
        closeButton: false,
        closeOnClick: false,
      })

      actOnMap(() => {
        popup.setLngLat(coords as LngLatLike)
        popup.setDOMContent(searchHereDiv.current as HTMLElement)
        popup.addTo(map.current as Map)
      })
      setSearchHerePopup(popup)
    } catch {
      toastError({
        message:
          'There was an error processing the file. Please make sure it is valid GEOJSON.',
      })
    }
  }, [
    currentSearchGeometry,
    map,
    searchHereDiv,
    setSearchHerePopup,
    uploadedFeature,
  ])

  useEffect(() => {
    if (map.current && (currentSearchGeometry || uploadedFeature)) {
      addSearchHerePopup()
    } else if (!currentSearchGeometry && searchHerePopup) {
      clearSearchHerePopup()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchGeometry, uploadedFeature])

  useEffect(() => {
    if (uploadedFeature && drawController !== null) {
      actOnMap(() => {
        drawController?.set(turf.featureCollection([uploadedFeature]))
        setIsDrawing(false)
        drawController?.changeMode('direct_select', {
          featureId: UPLOADED_FEATURE_ID,
        })
      })
    }
  }, [map, drawController, setIsDrawing, uploadedFeature])

  useEffect(() => {
    const handleLoad = () => {
      actOnMap(() => {
        if (!map.current?.getSource('mapbox-gl-draw-cold')) {
          stopDrawingShape()
          const drawController = makeMapboxDraw(map.current!)
          setDrawController(drawController)
          map.current?.addControl(drawController)
        }
      })
    }

    emitter.on(EVENTS.map.load, handleLoad)
    emitter.on(EVENTS.map.drawCreated, processDrawEvent)
    emitter.on(EVENTS.map.drawUpdated, processDrawEvent)

    return () => {
      emitter.off(EVENTS.map.load, handleLoad)
      emitter.off(EVENTS.map.drawCreated, processDrawEvent)
      emitter.off(EVENTS.map.drawUpdated, processDrawEvent)
    }
  }, [
    drawController,
    map,
    processDrawEvent,
    setDrawController,
    stopDrawingShape,
  ])

  const onClickCallback = useCallback(
    (query?: string) => {
      const { coordinates } = currentSearchGeometry as Polygon | Point
      const isPointSearch = currentSearchGeometry?.type === 'Point'
      const locationType = isPointSearch ? 'latLong' : 'polygon'

      const radiusFilter = {
        type: 'RADIUS',
        geometry: {
          type: 'Point',
          coordinates: currentSearchGeometry?.coordinates,
        },
        radius: currentSearchGeometry?.radius as number,
        radiusUnits: 'kilometers',
      }
      const positions = currentSearchGeometry
        ? isPointSearch
          ? [coordinates[1], coordinates[0]]
          : currentSearchGeometry.coordinates[0]
        : (uploadedFeature?.geometry as Polygon).coordinates[0]

      if (positions) {
        actOnMap(() => {
          searchHereDiv.current?.remove()
          if (drawMode === MAP_DRAW_MODES.CIRCLE) {
            doRadiusSearch(radiusFilter as RadiusApiFilterValue, query)
          } else {
            doLocationSearch(JSON.stringify(positions), locationType, query)
          }
          clearSearchHerePopup()
          setSearchParams((previousParams) => {
            previousParams.delete('search')
            return previousParams
          })
          setCurrentSearchGeometry(null)
        })
      }
    },
    [
      currentSearchGeometry,
      uploadedFeature?.geometry,
      searchHereDiv,
      drawMode,
      clearSearchHerePopup,
      setSearchParams,
      setCurrentSearchGeometry,
      doRadiusSearch,
      doLocationSearch,
    ],
  )

  return { onClickCallback }
}
