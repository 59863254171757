import { useLocation, useNavigate } from 'react-router-dom'
import { Button, Container, SimpleGrid, Stack, Title } from '@mantine/core'
import { APP_PADDING, FOOTER_HEIGHT, HEADER_HEIGHT } from '@/features/AppLayout'
import RotatingBackground from '@/features/AppLayout/RotatingBackground/RotatingBackground.tsx'
import { SearchBar } from '@/features/Search'
import { SEARCH_SUGGESTIONS } from '@/features/Search/constants'
import type { SearchSuggestion } from '@/features/Search/types'
import { SearchSuggestionCell } from '@/features/Search/views/SearchSuggestion/SearchSuggestion'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useSetStoredSplitSearch } from '@/stores/queries-store'
import { FEATURE_FLAG_VALUES, ROUTES } from '@/utils/constants'

export function Explore() {
  const location = useLocation()
  const isLogIn = location.pathname === '/log-in'
  const { logIn, isAuthenticated } = useDantiAuth()
  const navigate = useNavigate()

  const setStoredSplitSearch = useSetStoredSplitSearch()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)

  // TODO: Restore to prevent routing to Login if the user is already logged in
  // useEffect(() => {
  //   if (isAuthenticated && isLogIn) {
  //     navigate(ROUTES.root)
  //   }
  // }, [isAuthenticated, isLogIn, navigate])

  const handleSuggestionClick = (suggestion: SearchSuggestion) => {
    const { location, subject } = suggestion
    const { formattedAddress } = location
    const search = [formattedAddress, subject].join(' - ')
    const params = new URLSearchParams({ search })

    setStoredSplitSearch({
      location: formattedAddress,
      subject,
    })
    navigate(`${ROUTES.explore}?${params.toString()}`)
  }

  const nonContentHeight = HEADER_HEIGHT + FOOTER_HEIGHT + APP_PADDING * 2
  const title = `Search ${isCommercial ? 'property' : `Earth's`} data like never before.`
  const titleProps = {
    c: 'white',
    lh: 1,
    mb: 'sm',
    style: {
      textShadow: '3px 3px 7px var(--mantine-color-darken-4)',
      letterSpacing: '-0.03em',
    },
  }

  return (
    <Container>
      <Stack h={`calc(100vh - ${nonContentHeight}px)`} justify="flex-end">
        <Title hiddenFrom="sm" size={55} p="md" {...titleProps}>
          {title}
        </Title>
        <Title visibleFrom="sm" size={98} {...titleProps}>
          {title}
        </Title>
        {isCommercial && !isAuthenticated && (
          <Button variant="primary" onClick={logIn} w={140} ml={8} mt={-24}>
            Start Exploring
          </Button>
        )}
        {isCommercial && isAuthenticated && (
          <SimpleGrid visibleFrom="md" cols={3} spacing="1rem">
            {SEARCH_SUGGESTIONS.map((x) => (
              <SearchSuggestionCell
                key={x.title}
                suggestion={x}
                onClick={handleSuggestionClick}
              />
            ))}
          </SimpleGrid>
        )}
        {!isLogIn && <SearchBar />}
      </Stack>
      <RotatingBackground />
    </Container>
  )
}
