import { Table } from '@mantine/core'
import type { CollectionSortType } from '@/api/collections'
import type { CollectionItem } from '@/api/collections/types'
import {
  useNavigateToCollection,
  useNavigateToCollectionItem,
} from '@/features/AppRouting/hooks'
import { CategoryTypes } from '@/utils/types/raw-result-types'
import { isSubCollection } from '@/utils/types/result-type-checkers'
import {
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { CollectionDetailTableColumns } from './CollectionDetailTableColumns'

export interface CollectionDetailTableProps {
  items: CollectionItem[]
  setSort: (sort: CollectionSortType) => void
  onCategoryToggle: (categoryTypes: CategoryTypes) => void
  columnFilters?: ColumnFiltersState | undefined
}

export function CollectionDetailTable({
  items,
  setSort,
  onCategoryToggle,
  columnFilters,
}: CollectionDetailTableProps) {
  const table = useReactTable({
    data: items,
    columns: CollectionDetailTableColumns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: (collection) => collection.id,
    state: { columnFilters },
    meta: {
      setSort: (sort: CollectionSortType) => {
        setSort(sort)
      },
      onCategoryToggle: (categoryTypes: CategoryTypes) => {
        onCategoryToggle(categoryTypes)
      },
    },
  })

  const navigateToCollectionItem = useNavigateToCollectionItem()
  const navigateToCollection = useNavigateToCollection()

  const handleOnClick = (item: CollectionItem) => {
    if (isSubCollection(item)) {
      return navigateToCollection(item.refId)
    }
    navigateToCollectionItem(item.collectionId, item.id)
  }

  return (
    <Table highlightOnHover visibleFrom="sm">
      <Table.Thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Tr key={headerGroup.id}>
            {headerGroup.headers.map((h) => (
              <Table.Th key={h.id} w={h.getSize()}>
                {h.isPlaceholder
                  ? null
                  : flexRender(h.column.columnDef.header, h.getContext())}
              </Table.Th>
            ))}
          </Table.Tr>
        ))}
      </Table.Thead>
      <Table.Tbody>
        {table.getRowModel().rows.map((row) => {
          const isFileResult = row.original.category === CategoryTypes.FILE
          const onClick = isFileResult
            ? undefined
            : () => handleOnClick(items[row.index])
          return (
            <Table.Tr
              style={{ cursor: 'pointer' }}
              key={row.id}
              onClick={onClick}
            >
              {row.getVisibleCells().map((h) => (
                <Table.Td key={h.id}>
                  {flexRender(h.column.columnDef.cell, h.getContext())}
                </Table.Td>
              ))}
            </Table.Tr>
          )
        })}
      </Table.Tbody>
    </Table>
  )
}
