import { Select } from '@mantine/core'
import { useQueryId } from '@/api/results'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import {
  type SortPreferenceOption,
  useSavedSearchId,
  useSetRootQueryId,
  useSetSortPreference,
  useSortPreference,
} from '@/stores/queries-store'

interface SortSelectProps {
  sortPreference?: SortPreferenceOption
  setSortPreference?: (value: SortPreferenceOption) => void
}

const SORT_EVENTS = {
  authoredOn: USER_EVENTS.EXPLORE.SORT_DATE,
  score: USER_EVENTS.EXPLORE.SORT_RELEVANCE,
} as const

export function SortSelect(props: SortSelectProps) {
  const isExploringSavedSearch = useSavedSearchId() !== ''
  const defaultSortPreference = useSortPreference()
  const defaultSetSortPreference = useSetSortPreference()
  const sortPreference = props.sortPreference ?? defaultSortPreference
  const setSortPreference = props.setSortPreference ?? defaultSetSortPreference
  const currentQueryId = useQueryId()
  const setRootQueryId = useSetRootQueryId()

  function handleSortChange(value: string | null) {
    if (value) {
      const typedValue = value as keyof typeof SORT_EVENTS
      captureEvent(SORT_EVENTS[typedValue])
      setRootQueryId(currentQueryId)
      setSortPreference(typedValue)
      return
    }
    setSortPreference('score')
  }

  return (
    <Select
      disabled={isExploringSavedSearch}
      title={
        isExploringSavedSearch
          ? 'Sort is disabled for saved searches'
          : 'Sort results'
      }
      size="xs"
      value={sortPreference}
      onChange={handleSortChange}
      data={[
        { label: 'Sort by Date', value: 'authoredOn' },
        { label: 'Sort by Relevance', value: 'score' },
      ]}
      maw={140}
    />
  )
}
