import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Avatar, Button, Center, Menu, Modal } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { SearchHelp } from '@/features/SearchHelp'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useShowScores, useToggleShowScores } from '@/stores/config-store'
import { useAvatarDetails } from '@/stores/user-store'
import { FEATURE_FLAG_VALUES, ROUTES } from '@/utils/constants'
import { HeaderMenuItem } from './HeaderMenuItem'
import { SentryFeedbackButton } from './SentryFeedbackButton'

const handleClick = (menuItem: string) => {
  captureEvent(USER_EVENTS.USER_MENU.CLICKED, { menuItem })
}

export function HeaderUserMenu() {
  const [displaySearchHelp, setDisplaySearchHelp] = useState(false)
  const navigate = useNavigate()
  const { logOut } = useDantiAuth()
  const shouldShowAccount = useFeatureFlag(
    FEATURE_FLAG_VALUES.accountManagement,
  )
  const shouldShowScores = useFeatureFlag(FEATURE_FLAG_VALUES.showScores)
  const isShowingScores = useShowScores()
  const toggleShowScores = useToggleShowScores()
  const { avatarBackgroundColor, userInitials } = useAvatarDetails()
  const [searchParams, setSearchParams] = useSearchParams()
  const itemHeight = '2.5rem'

  return (
    <>
      <Menu offset={15} keepMounted>
        <Menu.Target>
          <Center style={{ cursor: 'pointer' }} inline>
            <Avatar
              size={32}
              variant="filled"
              color={avatarBackgroundColor}
              radius="sm"
            >
              {userInitials}
            </Avatar>
          </Center>
        </Menu.Target>
        <Menu.Dropdown
          bg="darken.7"
          styles={{
            dropdown: {
              border: 'none',
              backdropFilter: 'blur(1.5px)',
            },
          }}
        >
          {shouldShowAccount && (
            <HeaderMenuItem
              h={itemHeight}
              leftSection={<Icon name="person" filled />}
              onClick={() => {
                handleClick('Account')
                navigate(ROUTES.account)
              }}
            >
              Account
            </HeaderMenuItem>
          )}
          <HeaderMenuItem
            h={itemHeight}
            iconName="question_mark"
            onClick={() => {
              handleClick('Help')
              navigate(ROUTES.help)
            }}
          >
            Help
          </HeaderMenuItem>
          {shouldShowScores && (
            <HeaderMenuItem
              h={itemHeight}
              iconName="speed"
              onClick={toggleShowScores}
            >
              {isShowingScores ? 'Hide' : 'Show'} Result Scores
            </HeaderMenuItem>
          )}
          <SentryFeedbackButton h={itemHeight} />
          <HeaderMenuItem
            h={itemHeight}
            iconName="search"
            onClick={() => setDisplaySearchHelp(true)}
          >
            Search Suggestions
          </HeaderMenuItem>
          <HeaderMenuItem
            h={itemHeight}
            iconName="logout"
            onClick={() => {
              captureEvent(USER_EVENTS.LOGIN.LOGOUT)
              searchParams.delete('search')
              setSearchParams(searchParams)
              void logOut()
            }}
          >
            Log Out
          </HeaderMenuItem>
        </Menu.Dropdown>
      </Menu>
      <Modal
        opened={displaySearchHelp}
        onClose={() => setDisplaySearchHelp(false)}
        size="lg"
        withCloseButton={false}
      >
        <SearchHelp />
        <Button onClick={() => setDisplaySearchHelp(false)} mt="sm" size="xs">
          Close
        </Button>
      </Modal>
    </>
  )
}
