import { Image } from '@mantine/core'

export type StaticImageHeroProps = {
  image: string
  title: string
}

export function StaticImageHero({ image, title }: StaticImageHeroProps) {
  return <Image src={image} alt={title} />
}
