import { useParams } from 'react-router-dom'
import { ActionIcon, Anchor } from '@mantine/core'
import { useSingleObjectViewItem } from '@/api/collections/collection-items/use-single-object-view-item.ts'
import ErrorIcon from '@/assets/error-circles.svg?react'
import FolderIcon from '@/assets/folder-circles.svg?react'
import { Icon } from '@/components/lib/Icon.tsx'
import { StatusBlock } from '@/components/lib/StatusBlock'
import { QueryHeader } from '@/features/AppLayout'
import { useBackNavigation } from '@/features/AppRouting'
import { renderers } from '@/features/SingleObjectView/views'

export function SingleObjectView() {
  const { itemId, collectionId, terrapinItemId } = useParams()
  const {
    item,
    resultCategory = '',
    isLoading,
  } = useSingleObjectViewItem({
    itemId,
    collectionId,
    terrapinItemId,
  })
  const onGoBack = useBackNavigation()
  // variable to determine if SOV is rendering in collections view
  const isSavedSearch = collectionId || terrapinItemId
  const Renderer = renderers[resultCategory]
  const hasItem = item && resultCategory && Renderer

  const NoItems = () => {
    return isLoading ? (
      <StatusBlock
        title="Please wait"
        icon={<FolderIcon />}
        description="Danti is gathering your result"
      />
    ) : (
      <StatusBlock
        title="Unable to locate your search result"
        icon={<ErrorIcon />}
        description={
          <>
            If the problem persists, email{' '}
            <Anchor href="mailto:support@danti.ai">support@danti.ai</Anchor>
          </>
        }
      />
    )
  }

  const itemRenderer = hasItem ? <Renderer item={item} /> : <NoItems />
  return isSavedSearch ? (
    <>
      <ActionIcon onClick={onGoBack} variant="subtle" c="dark">
        <Icon size={24} name="arrow_left_alt" />
      </ActionIcon>
      {itemRenderer}
    </>
  ) : (
    <>
      <QueryHeader showBackNav />
      {itemRenderer}
    </>
  )
}
