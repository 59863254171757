import { useNavigate } from 'react-router-dom'
import { ActionIcon, Anchor, Group, Table } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { type HistoryItem, useDeleteHistoryMutation } from '@/api/history'
import { AoiIcon } from '@/components/AoiIcon'
import { Icon } from '@/components/lib/Icon.tsx'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { useSearch } from '@/features/Search/hooks'
import { ROUTES } from '@/utils/constants'
import { formatTime } from '@/utils/format-datetime.ts'

interface HistoryRowProps {
  item: HistoryItem
}

export function HistoryRow({ item }: HistoryRowProps) {
  const navigate = useNavigate()
  const deleteHistoryMutation = useDeleteHistoryMutation()
  const { doSearchFromHistory } = useSearch()

  const handleItemClick = () => {
    captureEvent(USER_EVENTS.HISTORY.RERUN_SEARCH)

    doSearchFromHistory(item)

    const params = new URLSearchParams({ search: item.query })
    navigate(`${ROUTES.explore}?${params.toString()}`)
  }

  const handleItemDelete = () => {
    captureEvent(USER_EVENTS.HISTORY.REMOVE)

    deleteHistoryMutation.mutate(
      { ids: [item.id] },
      {
        onSuccess: () => {
          notifications.show({
            title: 'Success',
            color: 'green',
            message: 'Search history item deleted',
          })
        },
        onError: () => {
          notifications.show({
            title: 'Error',
            color: 'red',
            message: 'Failed to delete search history item',
          })
        },
      },
    )
  }

  return (
    <Table.Tr>
      <Table.Td w={100}>{formatTime(item.queryTime)}</Table.Td>
      <Table.Td>
        <Group gap="xs">
          <Anchor
            c="dark"
            component="button"
            onClick={handleItemClick}
            disabled={deleteHistoryMutation.isPending}
          >
            {item.query}
          </Anchor>
          <AoiIcon item={item} />
        </Group>
      </Table.Td>
      <Table.Td w={10}>
        <ActionIcon
          variant="subtle"
          c="dark"
          onClick={handleItemDelete}
          loading={deleteHistoryMutation.isPending}
        >
          <Icon name="delete" />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  )
}
