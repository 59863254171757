import React from 'react'
import { Container, Loader, Stack } from '@mantine/core'
import { type HistoryItem, useHistory } from '@/api/history'
import ErrorIcon from '@/assets/error-circles.svg?react'
import { StatusBlock } from '@/components/lib/StatusBlock'
import {
  formatDateWithWordDay,
  isToday,
  isYesterday,
} from '@/utils/format-datetime.ts'
import { HistoryDateSection } from './HistoryDateSection'
import { HistoryDetailHeader } from './HistoryDetailHeader'

interface HistorySectionData {
  date: Date
  items: HistoryItem[]
}

const getFormattedDateTitle = (date: Date) => {
  const formattedDate = formatDateWithWordDay(date)

  if (isToday(date)) {
    return `Today, ${formattedDate}`
  } else if (isYesterday(date)) {
    return `Yesterday, ${formattedDate}`
  } else {
    return formattedDate
  }
}

export function HistoryDetail() {
  const { pagedItems, isLoading } = useHistory()

  const itemsByDate = React.useMemo(() => {
    const dateMap = new Map<string, HistorySectionData>()
    pagedItems.forEach((x) => {
      const date = new Date(x.queryTime)
      const formattedDate = getFormattedDateTitle(date)

      const existingDate = dateMap.get(formattedDate)
      if (existingDate) {
        existingDate.items.push(x)
      } else {
        dateMap.set(formattedDate, {
          date,
          items: [x],
        })
      }
    })

    return dateMap
  }, [pagedItems])

  const dates = React.useMemo(() => {
    return [...itemsByDate.keys()]
  }, [itemsByDate])

  return (
    <Container>
      <Stack gap="lg">
        <HistoryDetailHeader />
        {isLoading ? (
          <StatusBlock
            title="Please wait"
            icon={<Loader />}
            description="Danti is gathering your previous searches"
          />
        ) : dates.length === 0 ? (
          <StatusBlock
            title="No results found"
            icon={<ErrorIcon />}
            description="Danti was unable to find any previous searches"
          />
        ) : (
          dates.map((date) => (
            <HistoryDateSection
              key={date}
              title={date}
              date={itemsByDate.get(date)?.date}
              items={itemsByDate.get(date)?.items}
            />
          ))
        )}
      </Stack>
    </Container>
  )
}
