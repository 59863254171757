import {
  ActionIcon,
  BackgroundImage,
  CopyButton,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core'
import { default as waveHeaderRed } from '@/assets/images/wave-header-red.webp'
import { Icon } from '@/components/lib/Icon'
import { searchHelpSections } from './constants'

const Section = ({ children }: { children: React.ReactNode }) => (
  <Stack gap="xs" align="start">
    {children}
  </Stack>
)

const SectionHeader = ({ text }: { text: string }) => (
  <Text fw={700}>{text}</Text>
)

const SectionContent = ({ children }: { children: React.ReactNode }) => (
  <Group gap="xs">{children}</Group>
)

const Suggestion = ({ children }: { children: React.ReactNode }) => (
  <Paper withBorder p="xs" w="inherit" bg="blue.0">
    {children}
  </Paper>
)

const SuggestionTitle = ({ children }: { children: React.ReactNode }) => (
  <Group justify="space-between">{children}</Group>
)

const SuggestionContent = ({ children }: { children: React.ReactNode }) => (
  <Group gap="0">{children}</Group>
)

const Copy = ({ value }: { value: string }) => (
  <CopyButton value={value} timeout={2000}>
    {({ copied, copy }) => (
      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
        <ActionIcon
          color={copied ? 'green.4' : 'black'}
          variant="transparent"
          onClick={copy}
          size="xs"
        >
          {copied ? (
            <Icon name="check_circle" size={16} />
          ) : (
            <Icon name="content_copy" size={16} />
          )}
        </ActionIcon>
      </Tooltip>
    )}
  </CopyButton>
)

export function SearchHelp() {
  return (
    <Stack gap="sm">
      <BackgroundImage src={waveHeaderRed} radius="lg" p="sm">
        <Title c="white">Search Suggestions</Title>
        <Text c="white">
          Here are some ideas for how to create a great search query
        </Text>
      </BackgroundImage>
      <Stack>
        {searchHelpSections.map((section) => (
          <Section key={section.sectionTitle}>
            <SectionHeader text={section.sectionTitle} />
            <SectionContent>
              {section.suggestions.map((suggestion) => (
                <Suggestion key={suggestion.title}>
                  <SuggestionTitle>
                    <Text size="xs" tt="uppercase" fw="bold">
                      {suggestion.title}
                    </Text>
                    {suggestion.copyContent && (
                      <Copy value={suggestion.copyContent} />
                    )}
                  </SuggestionTitle>
                  <SuggestionContent>
                    <Text size="sm" mr="xs">
                      {suggestion.content}
                    </Text>
                    {suggestion.helper && (
                      <>
                        <Text fz="10" fw="bold">
                          {suggestion.helper}
                        </Text>
                      </>
                    )}
                  </SuggestionContent>
                </Suggestion>
              ))}
            </SectionContent>
          </Section>
        ))}
      </Stack>
    </Stack>
  )
}
