import { Stack } from '@mantine/core'
import { InsightFeedbackPanel } from '@/features/InsightPanel/components/InsightFeedback'
import type { InsightsPanelProps } from '@/features/InsightPanel/types'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import { useInsightFormatter } from '../hooks/use-insight-formatter'
import { InsightSection } from './sections/InsightSection'

export function InsightPanel({ result }: InsightsPanelProps) {
  const isFeedbackEnabled = useFeatureFlag(FEATURE_FLAG_VALUES.insightFeedback)
  const sections = useInsightFormatter(result)

  return (
    <Stack pr="md">
      {sections.map((section) => (
        <InsightSection key={section.title} section={section} />
      ))}
      {isFeedbackEnabled && <InsightFeedbackPanel />}
    </Stack>
  )
}
