import { Box } from '@mantine/core'
import type { CollectionItem } from '@/api/collections'
import { getShipDetailCardProps, SEARCH_CATEGORIES } from '@/api/results'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view'
import type { ShipResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const ShipReferenceCard = ({
  result,
  withActions,
  item,
  isActive = false,
}: {
  result: ShipResult
  withActions?: boolean
  item?: CollectionItem
  isActive?: boolean
}) => {
  const { title, geometry, datetime, latitude, longitude } =
    getShipDetailCardProps(result)
  const { targetRef } = useSubscribeScrollIntoView(isActive)

  return (
    <Box ref={targetRef}>
      <ReferenceCard
        item={item}
        heroGeometry={geometry}
        title={title}
        authoredOn={datetime}
        {...(latitude && longitude && { details: `${latitude}, ${longitude}` })}
        result={result}
        category={SEARCH_CATEGORIES.SHIP}
        withActions={withActions}
        isActive={isActive}
      />
    </Box>
  )
}
