import { ImageReferenceCard } from '@/features/ResultCards'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view.ts'
import { useSetHoveredLayerId } from '@/stores/map-store'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types'
import type React from 'react'
export interface ThumbnailProps extends React.HTMLAttributes<HTMLImageElement> {
  item: OrthoImageryLayerGroup
  isActive?: boolean
}

export const OrthoThumbnail = ({ item, isActive = false }: ThumbnailProps) => {
  const { id, layers } = item
  const setHoveredLayerId = useSetHoveredLayerId()
  const { targetRef } = useSubscribeScrollIntoView(isActive)
  const result = layers[0].imageResult

  return (
    <div
      onMouseEnter={() => setHoveredLayerId(id)}
      onMouseLeave={() => setHoveredLayerId(null)}
      ref={targetRef}
    >
      <ImageReferenceCard result={result} isActive={isActive} />
    </div>
  )
}
