import { useMemo, useRef } from 'react'
import { Box, Stack } from '@mantine/core'
import { useMapboxMap } from '@/features/DantiMap/hooks'
import type { TerrapinLocation } from '@/hooks/use-terrapin'
import { debug } from '@/utils/debug'
import * as turf from '@turf/turf'
import type { Point } from 'geojson'
import { useTerrapinLocationsLayer } from './use-terrapin-locations-layer'

export interface TerrapinDynamicMapDisplayProps {
  locations: TerrapinLocation[]
}

const WORLD_BBOX: mapboxgl.LngLatBoundsLike = [
  [-180, -90],
  [180, 90],
]

const MAP_BOUNDS_PADDING = 30

export const TerrapinDynamicMap = ({
  locations,
}: TerrapinDynamicMapDisplayProps) => {
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const mapRef = useRef<mapboxgl.Map | null>(null)

  const TerrapinMapData = useMemo(() => {
    const geojsonList: Point[] = []

    locations?.map((aoi) => {
      try {
        if (aoi.metadata?.geometry) {
          geojsonList.push(aoi.metadata.geometry)
        }
      } catch (error) {
        debug(error)
      }
    })

    const features = geojsonList.map((geojson) => turf.feature(geojson))
    const featureCollection = turf.featureCollection(features)

    const bbox =
      geojsonList.length > 0
        ? (turf.bbox(featureCollection) as mapboxgl.LngLatBoundsLike)
        : WORLD_BBOX

    return {
      data: featureCollection,
      bbox,
    }
  }, [locations])

  useMapboxMap(mapRef, mapContainerRef, {
    bounds: TerrapinMapData.bbox,
    fitBoundsOptions: { padding: MAP_BOUNDS_PADDING },
  })
  useTerrapinLocationsLayer(mapRef, turf.flip(TerrapinMapData.data))

  return (
    <Stack>
      <Box ref={mapContainerRef} h={400} w="100%" />
    </Stack>
  )
}
