import { useEffect, useRef } from 'react'
import { createStaticMapUrl } from '@/api/mapbox'
import { useMapboxMap } from '@/features/DantiMap/hooks'
import { formatMapboxStyle } from '@/features/DantiMap/utils'
import { useBaseLayer, useSetBaseLayer } from '@/stores/map-store'
import { MAP_BASE_LAYERS } from '@/utils/constants'
import type { ImageryResult } from '@/utils/types/result-types'
import { useAnalyticLayer, useSingleOrthoLayer } from './hooks'

import styles from '@/features/DantiMap/danti-map.module.css'

interface SingleImageMapProps {
  item: ImageryResult
}

export function SingleImageMap({ item }: SingleImageMapProps) {
  const mapContainerRef = useRef<HTMLDivElement | null>(null)
  const mapRef = useRef<mapboxgl.Map | null>(null)
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()
  useMapboxMap(mapRef, mapContainerRef)
  useSingleOrthoLayer(mapRef, item)
  useAnalyticLayer(mapRef, item)

  useEffect(() => {
    mapRef.current?.setStyle(formatMapboxStyle(baseLayer))
  }, [baseLayer, mapRef])

  const imgSource = createStaticMapUrl({
    geometry: item.geometry,
    baseMap:
      baseLayer === MAP_BASE_LAYERS.dantiStreets
        ? MAP_BASE_LAYERS.satellite
        : MAP_BASE_LAYERS.dantiStreets,
    size: [50, 50],
  })

  const toggleLayer = () => {
    void setBaseLayer(
      baseLayer === MAP_BASE_LAYERS.dantiStreets
        ? MAP_BASE_LAYERS.satellite
        : MAP_BASE_LAYERS.dantiStreets,
    )
  }

  return (
    <div ref={mapContainerRef} className={styles.mapContainer}>
      <img
        src={imgSource}
        alt="map"
        className={styles.mapImage}
        onClick={toggleLayer}
      />
    </div>
  )
}
