import { appEnv } from '@/utils/constants'

export type Mutation = {
  mutateAsync: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any,
    options: { onSuccess: () => void },
  ) => Promise<unknown>
}
export const makeApiEvent =
  <T>(mutation: Mutation, { onSuccess }: { onSuccess: () => void }) =>
  async (params: T) => {
    const response = await mutation.mutateAsync(params, { onSuccess })
    return response
  }

export const normalizeDownloadParams = (param: string) => {
  // Remove whitespace characters and make it lowercase
  return param.replaceAll(/\s+/g, '_').toLowerCase()
}
export const downloadFromURL = (url: string, downloadName: string) => {
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = url
  a.download = downloadName
  document.body.append(a)
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export const normalizeUrl = (url: string) => {
  const normalizedUrl = new URL(url)
  // Resolve image urls that are referencing an s3 object url to maintain backwards compatibility
  // TODO: Remove this block once data has been corrected
  if (url.includes('s3') && url.includes('danti-data-assets')) {
    normalizedUrl.hostname =
      appEnv === 'test' || appEnv === 'local'
        ? `api.dev.danti.ai`
        : `api.${appEnv}.danti.ai`
    normalizedUrl.pathname = `/provider${normalizedUrl.pathname}`
  }
  // Ensure api url reflects the correct environment, knowing test -> api.dev.danti
  // Images that are not proxied (sentinel 1) should not have hostname rewritten
  if (appEnv != 'local' && appEnv != 'test' && url.includes('danti.ai')) {
    normalizedUrl.hostname = `api.${appEnv}.danti.ai`
  }
  return normalizedUrl.toString()
}
