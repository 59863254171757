import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@/utils/constants'
import type { ResultWithId } from '@/utils/types/result-types.ts'
import { useExploreUrl } from './use-explore-url'

export const useNavigateToResult = () => {
  const navigate = useNavigate()
  return (result: ResultWithId, index?: number) => {
    let route = ROUTES.resultsItem.replace(':itemId', result.id)
    if (index) {
      route += `?i=${index}`
    }
    navigate(route)
  }
}

export const useNavigateToResultCallback = () => {
  const navigateToResult = useNavigateToResult()
  return (result: ResultWithId, index?: number) => () => {
    navigateToResult(result, index)
  }
}

export const useNavigateToSearchResults = () => {
  const navigate = useNavigate()
  const exploreUrl = useExploreUrl()
  return () => {
    navigate(exploreUrl)
  }
}

export const useNavigateToCollection = () => {
  const navigate = useNavigate()
  return (collectionId: string) => {
    navigate(ROUTES.collectionDetail.replace(':collectionId', collectionId))
  }
}

export const useNavigateToCollectionItem = () => {
  const navigate = useNavigate()
  return (collectionId: string, id: string) => {
    navigate(
      ROUTES.collectionItemDetail
        .replace(':itemId', id)
        .replace(':collectionId', collectionId),
    )
  }
}

export const useBackNavigation = () => {
  const navigate = useNavigate()
  return () => {
    navigate(-1)
  }
}
