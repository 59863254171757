import { SimpleGrid } from '@mantine/core'
import { useAllResults, useResultTotals } from '@/api/results'
import { BentoSection } from '@/features/BentoResults/components/BentoBox'
import { useBentoResults } from '@/features/BentoResults/hooks'
import type { BentoResultsProps } from '@/features/BentoResults/types'
import { renderers } from '@/features/BentoResults/views'

import styles from '@/features/BentoResults/components/bento-results.module.css'

export function BentoResults({ isLoading }: BentoResultsProps) {
  const { cardData } = useBentoResults()

  const allResults = useAllResults()
  const hasResults = allResults.length > 0
  const totals = useResultTotals()

  const cardChildren = cardData.map((_, index) => {
    if (isLoading || !hasResults) {
      return
    }

    const card = cardData[index]

    if (!card) {
      return
    }

    const Renderer = renderers[card.type]
    const key = `bento-card-${index}`
    return (
      <Renderer
        key={key}
        // @ts-expect-error: Incompatible types :shrug:
        result={card.result}
        subEntryIndex={card.subEntryIndex}
        totalResults={totals[card.type].value}
      />
    )
  }) as JSX.Element[]

  const cellProps = (index: number) => ({
    isLoading,
    'data-testid': `bento-cell-${index + 1}`,
    children: cardChildren[index],
  })

  const SPACING = 'sm'

  return (
    <SimpleGrid
      className={styles.bentoGrid}
      spacing={SPACING}
      verticalSpacing={SPACING}
    >
      <BentoSection {...cellProps(0)} />
      <BentoSection {...cellProps(1)} />

      <SimpleGrid
        className={styles.bentoSubGrid}
        spacing={SPACING}
        verticalSpacing={SPACING}
      >
        <BentoSection {...cellProps(2)} />
        <BentoSection {...cellProps(3)} />
        <BentoSection {...cellProps(4)} />
        <BentoSection {...cellProps(5)} />
      </SimpleGrid>
    </SimpleGrid>
  )
}
