import { Button, Group, Text } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { notifications } from '@mantine/notifications'
import { useDeleteHistoryMutation } from '@/api/history'

export function RemoveAllHistoryModal({
  context,
  id: modalId,
}: ContextModalProps) {
  const deleteHistoryMutation = useDeleteHistoryMutation()

  const handleRemoveAll = () => {
    deleteHistoryMutation.mutate(
      { all: true },
      {
        onSuccess: () => {
          context.closeModal(modalId)
          notifications.show({
            title: 'Success',
            color: 'green',
            message: `Deleted all search history`,
          })
        },
        onError: () => {
          notifications.show({
            title: 'Error',
            color: 'red',
            message: 'Failed to delete all search history',
          })
        },
      },
    )
  }

  return (
    <>
      <Text>Are you sure you want to delete all search history?</Text>
      <Text>This action cannot be undone.</Text>
      <Group justify="space-between" mt="md">
        <Button
          size="xs"
          variant="subtle"
          color="dark"
          onClick={() => context.closeModal(modalId)}
          disabled={deleteHistoryMutation.isPending}
        >
          Cancel
        </Button>
        <Button
          size="xs"
          color="red"
          onClick={handleRemoveAll}
          loading={deleteHistoryMutation.isPending}
        >
          Yes, delete all history
        </Button>
      </Group>
    </>
  )
}
