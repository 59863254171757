import { SegmentedControl } from '@mantine/core'
import type { ViewType } from '@/features/Collections/types'
import { iconSize } from '@/utils/icon-size'
import { MdOutlineGridView, MdOutlineViewList } from 'react-icons/md'

export const TypeControl = ({
  viewType,
  setViewType,
}: {
  viewType: ViewType
  setViewType: (value: ViewType) => void
}) => {
  const size = iconSize('xxl')
  const icons = {
    list: <MdOutlineViewList color="var(--mantine-color-black)" size={size} />,
    grid: <MdOutlineGridView size={size} />,
  }

  return (
    <SegmentedControl
      bg="gray.4"
      size="xs"
      value={viewType}
      onChange={setViewType}
      styles={{ label: { height: 28 } }}
      data={[
        { value: 'list', label: icons.list },
        { value: 'grid', label: icons.grid },
      ]}
    />
  )
}
