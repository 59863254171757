import { Box, Skeleton, Stack, Text } from '@mantine/core'
import { usePaginatedResults, useSearchStatus } from '@/api/results'
import type { Results } from '@/api/results/types'
import { ResultsTimelineBarGraph } from '@/features/ResultsTimeline/components'
import { useHistogramData } from '@/features/ResultsTimeline/hooks'
import { formatDateRange } from '@/features/ResultsTimeline/utils'
import type { FC } from 'react'

type ResultsTimelineProps = {
  isSmall: boolean
  type?: keyof Partial<Results>
}

export const ResultsTimeline: FC<ResultsTimelineProps> = ({
  isSmall,
  type,
}) => {
  const { results, queries } = usePaginatedResults()
  const isLoadingPaginatedResult =
    queries[type as keyof typeof queries]?.isLoading
  const searchStatus = useSearchStatus()
  const isReadyForInteraction =
    ['success', 'idle'].includes(searchStatus || '') &&
    !isLoadingPaginatedResult
  const effectiveResults = type ? { [type]: results[type] } : results
  const { data, categories, dateRange } = useHistogramData(effectiveResults)

  return (
    <Skeleton visible={!isReadyForInteraction}>
      <Stack>
        <Box>
          <Stack gap="xs">
            <Text size="sm" c="darken" ta="left">
              {formatDateRange(dateRange.min, dateRange.max)}
            </Text>
            <ResultsTimelineBarGraph
              small={isSmall}
              categories={categories}
              data={data}
              dateRange={dateRange}
            />
          </Stack>
        </Box>
      </Stack>
    </Skeleton>
  )
}
