import * as React from 'react'
import { createStaticMapUrl } from '@/api/mapbox'
import type { MultiPolygon, Point, Polygon } from 'geojson'

const DEFAULT_WIDTH = 200
const DEFAULT_HEIGHT = 480
const MAX_DIMENSION = 1280 // Max value set by Mapbox Static Images API

export const useGeometryStaticDisplay = (
  geometry?: Point | Polygon | MultiPolygon,
  color?: string,
) => {
  const ref = React.useRef<HTMLDivElement | null>(null)
  const [staticMapUrl, setStaticMapUrl] = React.useState<string | null>(null)

  React.useLayoutEffect(() => {
    if (ref.current && geometry) {
      const elementSize = ref.current.getBoundingClientRect()
      const width = Math.min(elementSize.width, MAX_DIMENSION) || DEFAULT_WIDTH
      const height =
        Math.min(elementSize.height, MAX_DIMENSION) || DEFAULT_HEIGHT

      setStaticMapUrl(
        createStaticMapUrl({
          geometry,
          size: [width, height],
          color,
        }),
      )
    }
  }, [ref, geometry, color])

  return { ref, staticMapUrl }
}
