import type { Collection } from '@/api/collections/types'
import { useNavigateToCollection } from '@/features/AppRouting/hooks'
import { ListItem } from '@/features/Collections/components'
import { iconSize } from '@/utils/icon-size'
import { MdOutlineFolderSpecial, MdSpaceDashboard } from 'react-icons/md'

export interface CollectionListItemProps {
  data: Collection
}

export function CollectionListItem({ data }: CollectionListItemProps) {
  const { id, name, lastActivity, status } = data
  const isTerrapin = Boolean(data.refId)
  const navigateToCollection = useNavigateToCollection()
  const handleClick = () => navigateToCollection(id)

  const Icon = isTerrapin ? MdOutlineFolderSpecial : MdSpaceDashboard
  const sizedIcon = <Icon size={iconSize('xxl')} />

  return (
    <ListItem
      onClick={handleClick}
      data={data}
      icon={sizedIcon}
      date={lastActivity.toString()}
      name={name}
      status={status}
    />
  )
}
