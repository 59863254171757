import { maintainRatio } from '@/utils/ui-helpers.tsx'

interface LogoProps extends React.SVGProps<SVGSVGElement> {
  width?: string | number
  height?: string | number
  wordColor?: string
  markColor?: string
}

export function DantiWordMark({ width, height = 24, ...props }: LogoProps) {
  const { width: newWidth, height: newHeight } = maintainRatio(
    {
      width,
      height,
    },
    145,
    39,
  )

  const wordColor = props.wordColor ?? 'var(--mantine-color-white)'
  const markColor =
    props.markColor ?? props.wordColor ?? 'var(--mantine-color-yellow-3)'

  return (
    <svg
      width={newWidth ?? 145}
      height={newHeight ?? 39}
      viewBox="0 0 145 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: 'pointer' }}
      {...props}
    >
      <path
        d="M67.4527 27.3088C72.2535 27.3088 75.7692 24.1462 75.7692 19.4626C75.7692 14.7791 72.2535 11.6165 67.4527 11.6165H58.3846V27.3088H67.4527ZM62.6277 23.2529V15.6482H67.7437C69.9501 15.6482 71.5261 17.1933 71.5261 19.4626C71.5261 21.7078 69.9501 23.2529 67.7437 23.2529H62.6277Z"
        fill={wordColor}
      />
      <path
        d="M97.6923 27.3087L89.6447 12.7753C89.2084 12.0027 88.5781 11.6164 87.754 11.6164H86.7844C85.9602 11.6164 85.33 12.0027 84.8937 12.7753L76.8461 27.3087H81.5244L87.2934 16.4931L89.984 21.5388H86.1784L84.3847 24.8945H91.7778L93.0867 27.3087H97.6923Z"
        fill={wordColor}
      />
      <path
        d="M115.663 27.3087C116.7 27.3087 117.231 26.7535 117.231 25.6912V11.6164H113.156V20.7663L105.753 12.7753C105.029 11.9786 104.499 11.6164 103.197 11.6164H102.184C100.882 11.6164 100.231 12.22 100.231 13.4029V27.3087H104.33V17.2415L112.408 25.9809C113.228 26.8983 113.686 27.3087 114.819 27.3087H115.663Z"
        fill={wordColor}
      />
      <path
        d="M131.459 27.3087V15.6482H137.231V11.6164H121.462V15.6482H127.233V27.3087H131.459Z"
        fill={wordColor}
      />
      <path d="M145 27.3087V11.6164H140.769V27.3087H145Z" fill={wordColor} />
      <path
        d="M15.9242 8.29487L11.4616 0.153702H33.6879L45 19.5061L33.6879 38.846H11.4616L15.9242 30.7173H28.7156L35.4656 19.5061L28.7156 8.29487H15.9242Z"
        fill={markColor}
      />
      <path
        d="M-7.62939e-06 30.6576L4.42997 38.923L15.8461 19.5792L4.55406 -4.19617e-05L-7.62939e-06 8.20342L6.44021 19.5792L-7.62939e-06 30.6576Z"
        fill={markColor}
      />
    </svg>
  )
}
