import { useImageById } from '@/api/results'
import { SingleImageMap } from '@/features/DantiMap'

export const TimelineCompare = ({
  selectedImages,
}: {
  selectedImages: {
    primary: string | null
    secondary: string | null
  }
  setSelectedImages: {
    primary: (image: string | null) => void
    secondary: (image: string | null) => void
  }
}) => {
  const primary = useImageById(selectedImages.primary)
  const secondary = useImageById(selectedImages.secondary)
  return (
    <div>
      <h2>Compare Component</h2>
      {primary && <SingleImageMap key={primary.id} item={primary} />}
      {secondary && <SingleImageMap key={secondary.id} item={secondary} />}
    </div>
  )
}
