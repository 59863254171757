import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Center, Group, Loader, Menu, Stack, Text } from '@mantine/core'
import { type HistoryItem, useHistory } from '@/api/history'
import { AoiIcon } from '@/components/AoiIcon'
import { Icon } from '@/components/lib/Icon'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { useSearch } from '@/features/Search/hooks'
import { useSetStoredSplitSearch } from '@/stores/queries-store'
import { ROUTES } from '@/utils/constants'
import { HeaderMenuItem } from './HeaderMenuItem'

import styles from '../header.module.css'

export function HeaderLinkHistory() {
  const navigate = useNavigate()
  const { isRecentHistoryLoading, recentItems } = useHistory()
  const setStoredSplitSearch = useSetStoredSplitSearch()
  const location = useLocation()
  const isOnHistoryPage = location.pathname === ROUTES.history
  const [isHistoryActive, setIsHistoryActive] = useState(isOnHistoryPage)
  const { doSearchFromHistory } = useSearch()

  useEffect(() => {
    setIsHistoryActive(isOnHistoryPage)
  }, [isOnHistoryPage])

  const dropdownRef = useRef(null)

  const handleItemClick = (item: HistoryItem) => {
    captureEvent(USER_EVENTS.HISTORY.RERUN_SEARCH)
    setStoredSplitSearch(null)

    doSearchFromHistory(item)

    // Set param & navigate
    const params = new URLSearchParams(window.location.search)
    params.set('search', item.query)
    navigate(`${ROUTES.explore}?${params.toString()}`)
  }

  return (
    <Menu
      onOpen={() => setIsHistoryActive(true)}
      onClose={() => setIsHistoryActive(isOnHistoryPage)}
      offset={15}
      position="bottom-start"
    >
      <Menu.Target>
        <Group
          {...(isHistoryActive && { 'data-active': true })}
          className={styles.link}
          gap="xs"
          c="white"
          py="xxs"
        >
          <Icon name="history" />
          <Text size="sm" tt="uppercase">
            History
          </Text>
        </Group>
      </Menu.Target>
      <Menu.Dropdown
        ref={dropdownRef}
        bg="darken.7"
        mah={500}
        maw={400}
        styles={{
          dropdown: {
            border: 'none',
            backdropFilter: 'blur(1.5px)',
            overflowY: 'auto',
          },
        }}
      >
        <HeaderMenuItem
          onClick={() => {
            captureEvent(USER_EVENTS.HEADER.CLICKED, {
              headerItem: 'All History',
            })
            navigate(ROUTES.history)
          }}
        >
          View All History
        </HeaderMenuItem>
        {recentItems?.length ? (
          recentItems.map((item) => (
            <HeaderMenuItem
              key={item.id}
              c="gray.5"
              rightSection={<AoiIcon item={item} />}
              onClick={() => handleItemClick(item)}
            >
              {item.query}
            </HeaderMenuItem>
          ))
        ) : (
          <Stack gap="sm" px="sm" py="xs">
            <Text size="xs" c="gray.5">
              Your previous searches will appear here
            </Text>
            {isRecentHistoryLoading && (
              <Center p="md">
                <Loader size="sm" color="white" />
              </Center>
            )}
          </Stack>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
