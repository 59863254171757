import { usePaginatedResults, useQueryId } from '@/api/results'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import type { Results } from '@/api/results/types'
import { useSortPreference } from '@/stores/queries-store'
import { useTimelineSelectedIds } from '@/stores/results-filter-store'
import type {
  AttomResult,
  CoreLogicPropertyResult,
  CoreLogicRiskResult,
  FireResult,
  HazardPropertyResult,
  HazardRiskResult,
  ImageryResult,
  PublicationResult,
  RegridResult,
  ResultWithId,
  ShipResult,
  SocialMediaResult,
} from '@/utils/types/result-types'
import { orderBy } from 'lodash'

export const useTimelineFilteredResults = (): {
  ids: string[]
  categorized: Results
  currentQueryId: string
} => {
  const timelineSelectedIds = useTimelineSelectedIds()
  const currentQueryId = useQueryId() || ''
  const { results, ids } = usePaginatedResults()

  const sortPreference = useSortPreference()
  const sorted = <T>(group: T[]) => orderBy(group, sortPreference, 'desc')

  const sortedResults = {
    [SEARCH_CATEGORIES.ATTOM]: sorted<AttomResult>(
      results[SEARCH_CATEGORIES.ATTOM],
    ),
    [SEARCH_CATEGORIES.FIRE]: sorted<FireResult>(
      results[SEARCH_CATEGORIES.FIRE],
    ),
    [SEARCH_CATEGORIES.HAZARD_PROPERTY]: sorted<HazardPropertyResult>(
      results[SEARCH_CATEGORIES.HAZARD_PROPERTY],
    ),
    [SEARCH_CATEGORIES.HAZARD_RISK]: sorted<HazardRiskResult>(
      results[SEARCH_CATEGORIES.HAZARD_RISK],
    ),
    [SEARCH_CATEGORIES.CORELOGIC_PROPERTY]: sorted<CoreLogicPropertyResult>(
      results[SEARCH_CATEGORIES.CORELOGIC_PROPERTY],
    ),
    [SEARCH_CATEGORIES.CORELOGIC_RISK]: sorted<CoreLogicRiskResult>(
      results[SEARCH_CATEGORIES.CORELOGIC_RISK],
    ),
    [SEARCH_CATEGORIES.REGRID]: sorted<RegridResult>(
      results[SEARCH_CATEGORIES.REGRID],
    ),
    [SEARCH_CATEGORIES.IMAGE]: sorted<ImageryResult>(
      results[SEARCH_CATEGORIES.IMAGE],
    ),
    [SEARCH_CATEGORIES.PUBLICATION]: sorted<PublicationResult>(
      results[SEARCH_CATEGORIES.PUBLICATION],
    ),
    [SEARCH_CATEGORIES.SHIP]: sorted<ShipResult>(
      results[SEARCH_CATEGORIES.SHIP],
    ),
    [SEARCH_CATEGORIES.SOCIAL_MEDIA]: sorted<SocialMediaResult>(
      results[SEARCH_CATEGORIES.SOCIAL_MEDIA],
    ),
    [SEARCH_CATEGORIES.VEXCEL]: sorted(results[SEARCH_CATEGORIES.VEXCEL]),
  }

  if (!timelineSelectedIds) {
    return {
      ids,
      currentQueryId,
      categorized: {
        [SEARCH_CATEGORIES.INSIGHT]: results[SEARCH_CATEGORIES.INSIGHT],
        ...sortedResults,
      },
    }
  }

  const predicate = (result: ResultWithId) =>
    timelineSelectedIds.includes(result.id)
  const filtered = <T>(group: T[]) =>
    group.filter((result: T) => predicate(result as ResultWithId))
  return {
    ids,
    currentQueryId,
    categorized: {
      [SEARCH_CATEGORIES.INSIGHT]: results[SEARCH_CATEGORIES.INSIGHT],
      [SEARCH_CATEGORIES.ATTOM]: filtered(
        sortedResults[SEARCH_CATEGORIES.ATTOM],
      ),
      [SEARCH_CATEGORIES.FIRE]: filtered(sortedResults[SEARCH_CATEGORIES.FIRE]),
      [SEARCH_CATEGORIES.HAZARD_PROPERTY]: filtered(
        sortedResults[SEARCH_CATEGORIES.HAZARD_PROPERTY],
      ),
      [SEARCH_CATEGORIES.HAZARD_RISK]: filtered(
        sortedResults[SEARCH_CATEGORIES.HAZARD_RISK],
      ),
      [SEARCH_CATEGORIES.CORELOGIC_PROPERTY]: filtered(
        sortedResults[SEARCH_CATEGORIES.CORELOGIC_PROPERTY],
      ),
      [SEARCH_CATEGORIES.CORELOGIC_RISK]: filtered(
        sortedResults[SEARCH_CATEGORIES.CORELOGIC_RISK],
      ),
      [SEARCH_CATEGORIES.REGRID]: filtered(
        sortedResults[SEARCH_CATEGORIES.REGRID],
      ),
      [SEARCH_CATEGORIES.IMAGE]: filtered(
        sortedResults[SEARCH_CATEGORIES.IMAGE],
      ),
      [SEARCH_CATEGORIES.PUBLICATION]: filtered(
        sortedResults[SEARCH_CATEGORIES.PUBLICATION],
      ),
      [SEARCH_CATEGORIES.SHIP]: filtered(sortedResults[SEARCH_CATEGORIES.SHIP]),
      [SEARCH_CATEGORIES.SOCIAL_MEDIA]: filtered(
        sortedResults[SEARCH_CATEGORIES.SOCIAL_MEDIA],
      ),
      [SEARCH_CATEGORIES.VEXCEL]: filtered(
        sortedResults[SEARCH_CATEGORIES.VEXCEL],
      ),
    },
  }
}
