import {
  formatImageQuality as formatQuality,
  getImageMetaDetails,
} from '@/api/results'
import { ImageReferenceCard } from '@/features/ResultCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { extractProvenance } from '@/stores/results-filter-store/utils.ts'
import { ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { isVexcel } from '@/utils/types/result-type-checkers'
import type { ImageryResult } from '@/utils/types/result-types'
import { useResultNavigation } from '../hooks'
import { ImageDetailsModal } from './ImageDetailsModal'

export function ImageObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as ImageryResult
  const metaDetails = getImageMetaDetails(item)
  const { selectItem, items } = useResultNavigation<ImageryResult>(item)

  return (
    <SingleObjectViewTemplate<ImageryResult, ImageryResult>
      {...{
        item,
        hero: {
          ...(isVexcel(item)
            ? { vexcelImage: item.thumbnail, vexcelSource: item.source }
            : { mapImage: item }),
          metaDetails: [
            formatDatetime(item.authoredOn),
            extractProvenance(item),
            formatQuality(item.properties._dQuality) || '',
          ],
          metaAction: (
            <ImageDetailsModal details={metaDetails} geometry={item.geometry} />
          ),
        },
        content: {},
        carousel: {
          title: 'More Images',
          items,
          selectItem,
          categoryPageLink: {
            url: `${ROUTES.explore}?tab=images`,
            label: 'All Images',
          },
          renderCard: (image: ImageryResult) => (
            <ImageReferenceCard result={image} />
          ),
        },
      }}
    />
  )
}
