import React from 'react'
import { useLocation } from 'react-router-dom'
import {
  Group,
  Loader,
  Pagination,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Tabs,
  Text,
  Title,
} from '@mantine/core'
import {
  PAGE_SIZE,
  SEARCH_CATEGORIES,
  useAllResults,
  useInsightResults,
  useInsightStatus,
  useIsInsightLoading,
  usePaginatedResults,
  useResultTotals,
  useSearchStatus,
} from '@/api/results'
import SearchIcon from '@/assets/search-circles.svg?react'
import { StatusBlock } from '@/components/lib/StatusBlock'
import { ScrollableContainer } from '@/components/ScrollableContainer'
import { QueryHeader } from '@/features/AppLayout'
import { BentoResults } from '@/features/BentoResults/components'
import { FilterToggleButton } from '@/features/Filters'
import { InsightPanel } from '@/features/InsightPanel'
import { ResultsTimeline } from '@/features/ResultsTimeline'
import { useSearch } from '@/features/Search/hooks'
import { SearchHelp } from '@/features/SearchHelp'
import { SortSelect } from '@/features/SortSelect'
import { usePageTitle } from '@/hooks/use-page-title'
import { type TabValue, useResultTabs } from '@/hooks/use-result-tabs.tsx'
import { checkIsLoading } from '@/pages/ResultsOverview/check-is-loading'
import { NoResults } from '@/pages/ResultsOverview/components/NoResults'
import { StatusAwareSkeleton } from '@/pages/ResultsOverview/components/StatusAwareSkeleton'
import { useHaveFiltersUpdated } from '@/stores/filters-store'
import { useActiveOrthoLayerGroupIds } from '@/stores/map-store'
import {
  useCurrentQuery,
  useSavedSearchId,
  useSetCategoryPage,
} from '@/stores/queries-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import { RESULT_CATEGORIES } from '@/utils/constants.ts'
import { wordBreak } from '@/utils/ui-helpers'

import styles from '../results-overview.module.css'

const loadingResultsProps = {
  title: 'Gathering results',
  icon: <Loader />,
  description: 'Please wait while we gather your results.',
}

const noResultsProps = {
  title: 'No results found',
  icon: <SearchIcon />,
  description:
    'No results match the filter criteria. Clear all filters and try again.',
}

// Only want to shorten padding between md & lg as app goes full-screen below md
const TAB_RESPONSIVE_PADDING = { base: 'md', md: 'sm', lg: 'md' }

export function ResultsOverview() {
  const insightResults = useInsightResults()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const search = params.get('search')
  const tab = params.get('tab') as TabValue

  const currentQuery = useCurrentQuery()
  const { doTextSearch } = useSearch()
  const insightStatus = useInsightStatus()
  const isInsightLoading = useIsInsightLoading() || insightStatus === 'pending'

  const { categorized: timelineCategorized } = useTimelineFilteredResults()
  const { activeCategory, activeTab, handleTabChange, orderedTabs } =
    useResultTabs(tab)

  const numberOfSelectedImages = useActiveOrthoLayerGroupIds().length ?? 0

  // Pagination related
  const setCurrentPage = useSetCategoryPage()
  const totals = useResultTotals()
  const activeCategoryTotal = totals[activeCategory]?.value
  const paginatedResults = timelineCategorized[activeCategory]
  const { pages, queries } = usePaginatedResults()
  const page = pages[activeCategory] || 1
  const paginationLabel = `${PAGE_SIZE * (page - 1) + 1} – ${Math.min(activeCategoryTotal, PAGE_SIZE * page)} of ${activeCategoryTotal}`
  const showPagination = paginatedResults.length > 0 && activeCategoryTotal > 0

  const allResults = useAllResults()
  const hasResults = allResults.some(
    (r) => r.category !== RESULT_CATEGORIES.INSIGHT,
  )

  // Loading state related
  const isLoadingPaginatedResult =
    queries[activeCategory as keyof typeof queries]?.isLoading
  const searchStatus = useSearchStatus()

  const isLoading = searchStatus === 'pending' || isLoadingPaginatedResult
  const noResults =
    searchStatus === 'error' || (searchStatus === 'success' && !hasResults)
  const emptyResultsProps = isLoading ? loadingResultsProps : noResultsProps

  // Empty Page related (no search initiated)
  const haveFiltersUpdated = useHaveFiltersUpdated()
  const savedSearchId = useSavedSearchId()
  const isSavedSearch = savedSearchId !== ''
  const noSearchInitiated =
    !isSavedSearch && !(currentQuery || haveFiltersUpdated)

  usePageTitle(`${currentQuery} - Danti Search`)

  React.useEffect(() => {
    if (search && search !== currentQuery) {
      void doTextSearch(search)
      handleTabChange(null)
    }

    if (tab !== activeTab) {
      handleTabChange(tab)
    }
    // This `useLocation` stateful hook is updated anytime the URL changes.
    // We use location as a dependency here because we want to update the query
    // when the user navigates to the same page with a new query or tab value.
    // This ensures expected behavior for forward/back history navigation.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, currentQuery])

  const hasInsights = insightResults.length > 0

  if (noResults) {
    return (
      <ScrollableContainer px={{ base: 'sm', lg: 'md' }} pt="sm">
        <NoResults />
      </ScrollableContainer>
    )
  }

  if (noSearchInitiated) {
    return (
      <ScrollableContainer px={{ base: 'sm', lg: 'md' }} pt="sm">
        <SearchHelp />
      </ScrollableContainer>
    )
  }

  return (
    <>
      <QueryHeader />
      <ScrollableContainer px={{ base: 'sm', lg: 'md' }} pt="sm">
        <Tabs value={activeTab} onChange={handleTabChange} w="100%">
          <Tabs.List visibleFrom="xs">
            <Tabs.Tab value="summary" px={TAB_RESPONSIVE_PADDING}>
              Summary
            </Tabs.Tab>
            {orderedTabs.map((tab) => (
              <Tabs.Tab
                key={tab.value}
                value={tab.value}
                tt="capitalize"
                px={TAB_RESPONSIVE_PADDING}
              >
                {tab.value}
              </Tabs.Tab>
            ))}
          </Tabs.List>
          <Select
            value={activeTab}
            data={['summary', ...orderedTabs.map((tab) => tab.value)]}
            hiddenFrom="xs"
            styles={{
              input: { textTransform: 'capitalize' },
              option: { textTransform: 'capitalize' },
            }}
            onChange={handleTabChange}
          />
          {activeTab !== 'summary' && (
            <>
              {(Boolean(activeCategoryTotal) || isLoading) && (
                <Paper withBorder mt="sm" p="xs" radius="md">
                  <ResultsTimeline isSmall type={activeCategory} />
                </Paper>
              )}
              <Group my="sm" justify="space-between">
                <Group gap="xs">
                  <SortSelect />
                  <FilterToggleButton size="xs" variant="default">
                    Filters
                  </FilterToggleButton>
                  {activeCategory === SEARCH_CATEGORIES.IMAGE && (
                    <Text size="xs">{numberOfSelectedImages} Selected</Text>
                  )}
                </Group>
                {showPagination && (
                  <Pagination.Root
                    total={Math.ceil(
                      Math.min(activeCategoryTotal, 500) / PAGE_SIZE,
                    )}
                    value={page}
                    onChange={(newPage) =>
                      setCurrentPage(activeCategory, newPage)
                    }
                  >
                    <Group gap="0">
                      <Text size="xs">{paginationLabel}</Text>
                      <Pagination.Previous bd="none" />
                      <Pagination.Next bd="none" />
                    </Group>
                  </Pagination.Root>
                )}
              </Group>
            </>
          )}
          <Tabs.Panel value="summary" pt="md">
            <Stack gap="md">
              <BentoResults isLoading={checkIsLoading(status)} />
              <StatusAwareSkeleton
                condition={!isInsightLoading}
                fallback={<NoResults />}
              >
                <Paper radius="md" p="md" withBorder>
                  {hasInsights && (
                    <Title lineClamp={3} size="h4" mb="md" pr="md">
                      {wordBreak(insightResults[0].title)}
                    </Title>
                  )}
                  {insightResults.map((result) => (
                    <InsightPanel key={result.id} result={result} />
                  ))}
                </Paper>
              </StatusAwareSkeleton>
            </Stack>
          </Tabs.Panel>
          {orderedTabs.map((tab) => (
            <Tabs.Panel key={tab.value} value={tab.value} pb="md">
              {activeCategoryTotal && !isLoading ? (
                <SimpleGrid className={styles.categoryGrid}>
                  {tab.content}
                </SimpleGrid>
              ) : (
                <StatusBlock {...emptyResultsProps} />
              )}
            </Tabs.Panel>
          ))}
        </Tabs>
      </ScrollableContainer>
    </>
  )
}
