import { forwardRef, useState } from 'react'
import { Badge, Card, type CardProps } from '@mantine/core'
import type { CollectionItem } from '@/api/collections/types'
import { ResultActions } from '@/features/ResultActions'
import { MENU_OPTION_KEYS } from '@/features/ResultActions/constants'
import { useMenuOptionActions } from '@/features/ResultActions/hooks'
import type { ActionMenuOption } from '@/features/ResultActions/types'
import type { ResultWithId } from '@/utils/types/result-types'
import { classes } from '@/utils/ui-helpers'
import {
  type BentoCardContentParams,
  default as BentoCardContent,
} from './BentoCardContent'

import styles from './bento-card.module.css'

export interface BentoCardParams extends CardProps, BentoCardContentParams {
  totalResults?: number
  resultCategory: string
  item?: CollectionItem
  result: ResultWithId
  index?: number
  hideActions?: ActionMenuOption[]
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  className?: string
  subEntryIndex?: number
}

export const BentoCard = forwardRef(
  (
    params: BentoCardParams,
    forwardedRef: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [actionsVisible, setActionsVisible] = useState(false)

    const {
      item,
      result,
      index,

      socialIcon,
      icon,
      svgIcon,

      content,
      geometry,
      image,

      resultCategory,
      totalResults,
      onMouseEnter,
      onMouseLeave,

      className,
      hideActions = [],
      subEntryIndex,
      ...props
    } = params

    const allActions = useMenuOptionActions({ result, index })

    const totalString = totalResults
      ? totalResults > 1000
        ? `${totalResults / 1000}k`
        : totalResults
      : ''

    return (
      <Card
        ref={forwardedRef}
        className={classes(styles.bentoCard, className)}
        onClick={allActions[MENU_OPTION_KEYS.viewDetails]}
        onMouseEnter={() => {
          if (onMouseEnter) onMouseEnter()
          setActionsVisible(true)
        }}
        onMouseLeave={() => {
          if (onMouseLeave) onMouseLeave()
          setActionsVisible(false)
        }}
        bg={content?.color}
        {...props}
      >
        <BentoCardContent
          {...{
            content,
            geometry,
            icon,
            image,
            socialIcon,
            svgIcon,
          }}
        />
        {actionsVisible && (
          <ResultActions hideActions={hideActions} result={result} />
        )}
        {totalResults && totalResults > 1 && (
          <Badge
            pos="absolute"
            bottom={4}
            right={4}
            color="darken.5"
            radius="md"
            px="xxs"
          >
            +{totalString}
          </Badge>
        )}
      </Card>
    )
  },
)
BentoCard.displayName = 'BentoCard'

export default BentoCard
