import { Text, Title } from '@mantine/core'
import { SOVDataDisplay } from './DataDisplay'

export interface SOVContentProps {
  title?: React.ReactNode
  details?: string
  detailsLabel?: string
  detailsElement?: React.ReactNode
  data?: {
    title?: React.ReactNode
    value?: React.ReactNode
  }[]
}

export function SOVContent({
  data,
  details,
  detailsLabel,
  detailsElement,
  title,
}: SOVContentProps) {
  return (
    <>
      {title && (
        <Title order={2} mt="sm">
          {title}
        </Title>
      )}
      {detailsLabel && details && <Text fw="bold">{detailsLabel}</Text>}
      {details && <Text>{details}</Text>}
      {detailsElement}
      {data && <SOVDataDisplay fields={data} />}
    </>
  )
}
