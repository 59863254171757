import { getFireDataFields } from '@/api/results'
import { FireReferenceCard } from '@/features/ResultCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { FireResult } from '@/utils/types/result-types'
import { useResultNavigation } from '../hooks'

export function FireObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as FireResult
  const color = RESULT_CATEGORY_COLORS.fire.toLowerCase().slice(1)
  const { selectItem, items } = useResultNavigation<FireResult>(item)

  return (
    <SingleObjectViewTemplate<FireResult, FireResult>
      {...{
        item,
        hero: {
          geometry: item.geometry,
          color,
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: {
          title: 'Wildfire',
          data: getFireDataFields(item),
        },
        carousel: {
          title: 'More Fires',
          items,
          selectItem,
          categoryPageLink: {
            url: `${ROUTES.explore}?tab=fire`,
            label: 'All Fires',
          },
          renderCard: (fire: FireResult) => <FireReferenceCard result={fire} />,
        },
      }}
    />
  )
}
