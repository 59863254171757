import { Box, Text } from '@mantine/core'
import { classes } from '@/utils/ui-helpers'
import type { ReactNode } from 'react'

import styles from './bento-card.module.css'

interface BentoCardTextContentParams {
  content?: {
    heading?: ReactNode
    subHeading?: ReactNode
    color?: string
    fontColor?: string
  }
}

export const BentoCardTextContent = ({ content }: BentoCardTextContentParams) =>
  content && (
    <Box pos="absolute" bottom={8} left={8}>
      {content.heading && (
        <Text
          className={styles.heading}
          lineClamp={2}
          c={content.fontColor || 'white'}
        >
          {content.heading}
        </Text>
      )}
      {content.subHeading && (
        <Text
          className={classes(
            styles.subHeading,
            Boolean(content.heading) && styles.responsiveSubHeading,
          )}
          lineClamp={2}
          c={content.fontColor || 'white'}
        >
          {content.subHeading}
        </Text>
      )}
    </Box>
  )

export default BentoCardTextContent
