import { executeQuery, getQueryResultsByQueryId } from '@/api/results/api'
import { SEARCH_CATEGORIES, SORT_VALUES } from '@/api/results/constants'
import type { SearchResult } from '@/api/results/types'
import {
  useCurrentQuery,
  useCurrentQueryFilters,
  useCurrentQueryStart,
  useRootQueryId,
  useSavedSearchId,
  useSortPreference,
} from '@/stores/queries-store'
import { useQuery } from '@tanstack/react-query'

export const useSearchQuery = () => {
  const queryStart = useCurrentQueryStart()
  const rootQueryId = useRootQueryId()
  const sortPreference =
    useSortPreference() === 'score'
      ? SORT_VALUES.SCORE
      : SORT_VALUES.AUTHORED_ON
  const savedSearchId = useSavedSearchId()
  const params = {
    query: useCurrentQuery(),
    filters: useCurrentQueryFilters(),
    ...(rootQueryId && { rootQueryId }),
    order: {
      [SEARCH_CATEGORIES.IMAGE]: sortPreference,
      [SEARCH_CATEGORIES.FIRE]: sortPreference,
      [SEARCH_CATEGORIES.SHIP]: sortPreference,
      [SEARCH_CATEGORIES.PUBLICATION]: sortPreference,
      [SEARCH_CATEGORIES.SOCIAL_MEDIA]: sortPreference,
    },
  }
  const isSavedSearch = savedSearchId !== ''
  const isQueryEnabled = () => {
    if (isSavedSearch) {
      return true
    }
    return (
      (Boolean(queryStart) && params.query !== '') || params.filters.length > 0
    )
  }
  const queryKey = ['search', params, queryStart]
  if (isSavedSearch) {
    queryKey.push(savedSearchId)
  }

  return useQuery({
    queryKey,
    queryFn: () =>
      isSavedSearch
        ? getQueryResultsByQueryId(savedSearchId)
        : executeQuery(params),
    enabled: isQueryEnabled(),
    retry: false,
  })
}

export const useSearchData = () => useSearchQuery().data as SearchResult
export const useSearchResults = () => useSearchData()?.resultDocuments || []
export const useSearchMetadata = () => useSearchData()?.queryMetadata
export const useSearchStatus = () => useSearchQuery().status || 'idle'
export const useQueryId = () => useSearchData()?.queryId || ''
