import { Group, Text } from '@mantine/core'
import type * as React from 'react'
import { SOVHeroContent, type SOVHeroContentProps } from './HeroContent'
import { isContentAvailable } from './is-content-available'

export interface SOVHeroProps<Type> extends SOVHeroContentProps<Type> {
  item: Type
  toNext?: () => void
  toPrevious?: () => void
  metaDetails?: string[]
  metaAction?: React.ReactNode
}

export function SOVHero<T>(props: SOVHeroProps<T>) {
  const { metaDetails = [], metaAction } = props

  return isContentAvailable(props) ? (
    <>
      <SOVHeroContent {...props} />
      <Group justify="space-between">
        <Group gap="sm">
          {metaDetails.map((detail) => (
            <Text size="sm" c="gray.7" key={detail}>
              {detail}
            </Text>
          ))}
        </Group>
        {metaAction}
      </Group>
    </>
  ) : null
}
