import { useState } from 'react'
import {
  Anchor,
  Button,
  Group,
  List,
  Modal,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import EmptyBentoBoxes from '@/assets/empty-bento-boxes.svg?react'
import { SearchHelp } from '@/features/SearchHelp'

export function NoResults() {
  const [displaySearchHelp, setDisplaySearchHelp] = useState(false)
  return (
    <>
      <Stack
        align="center"
        justify="center"
        gap="xs"
        p="xl"
        bg="gray.1"
        style={{
          borderRadius: 'var(--mantine-radius-lg)',
        }}
      >
        <EmptyBentoBoxes />
        <Text ta="center">
          Danti couldn&#39;t determine a set of results for your query
        </Text>
      </Stack>
      <Stack
        mt="xs"
        gap="sm"
        p="md"
        style={{
          border: '1px solid var(--mantine-color-gray-3)',
          borderRadius: 'var(--mantine-radius-lg)',
        }}
      >
        <Title fw="normal" size="lg">
          Why couldn&#39;t Danti provide results?
        </Title>

        <List size="sm">
          <List.Item>
            <b>Your query was not specific enough</b> and there were too many
            matches to determine a result set.
          </List.Item>

          <List withPadding size="xs" listStyleType="disc">
            <List.Item>
              Try adding a date range or a smaller location for better results.
            </List.Item>
            <List.Item>
              For example, “Hanoi Typhoon Yagi September 2024”
            </List.Item>
          </List>

          <List.Item pt="sm">
            <b>Your query was too specific</b> and there were no results that
            matched.
          </List.Item>
          <List withPadding size="xs" listStyleType="disc">
            <List.Item>
              Try broadening the date range and location for to see if more
              results appear.
            </List.Item>
            <List.Item>For example, “Tasmania brushfires 2025”</List.Item>
          </List>

          <List.Item pt="sm">
            A timeout occurred or our system encountered an error.
          </List.Item>
          <List withPadding size="xs" listStyleType="disc">
            <List.Item>
              Click the Feedback button under your user menu to provide Danti
              with details about the error.
            </List.Item>
            <List.Item>
              Email{' '}
              <Anchor size="xs" href="mailto:support@danti.ai">
                support@danti.ai
              </Anchor>
            </List.Item>
          </List>
        </List>

        <Group>
          <Button
            variant="outline"
            color="gray.7"
            fw="normal"
            onClick={() => setDisplaySearchHelp(true)}
          >
            Open search suggestions
          </Button>
        </Group>
      </Stack>

      <Modal
        opened={displaySearchHelp}
        onClose={() => setDisplaySearchHelp(false)}
        size="lg"
        withCloseButton={false}
      >
        <SearchHelp />
        <Button onClick={() => setDisplaySearchHelp(false)} mt="sm" size="xs">
          Close
        </Button>
      </Modal>
    </>
  )
}
