import type { CollectionItem } from '@/api/collections'
import { formatImageQuality, SEARCH_CATEGORIES } from '@/api/results'
import { extractProvenance } from '@/stores/results-filter-store/utils'
import type { ImageryResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const ImageReferenceCard = ({
  result,
  withActions,
  item,
  isActive,
}: {
  result: ImageryResult
  withActions?: boolean
  item?: CollectionItem
  isActive?: boolean
}) => {
  const quality = formatImageQuality(result.properties._dQuality)
  const title = extractProvenance(result, true)

  return (
    <ReferenceCard
      item={item}
      heroMapImage={result}
      title={title}
      authoredOn={result.properties.datetime}
      description={quality || ''}
      withActions={withActions}
      category={SEARCH_CATEGORIES.IMAGE}
      result={result}
      isActive={isActive}
    />
  )
}
