import * as React from 'react'
import { useQueryId } from '@/api/results'
import { useCurrentQuery } from '@/stores/queries-store'
import { ROUTES } from '@/utils/constants'

export function useExploreUrl() {
  const currentQuery = useCurrentQuery()
  const currentQueryId = useQueryId()

  const [exploreUrl, setExploreUrl] = React.useState(ROUTES.explore)

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (currentQuery) {
      params.set('search', currentQuery)
    }

    if (params.toString()) {
      setExploreUrl(`${ROUTES.explore}?${params.toString()}`)
    } else {
      setExploreUrl(ROUTES.explore)
    }
  }, [currentQuery, currentQueryId])
  return exploreUrl
}
