export const KEYS = {
  recent: 'recent',
  history: 'history',
}
export const PATHS = {
  history: `/${KEYS.history}`,
  recent: `/${KEYS.history}/${KEYS.recent}`,
}

export const PAGE_SIZE = 50
