type SearchHelpSection = {
  sectionTitle: string
  suggestions: {
    title: string
    content: string
    copyContent?: string
    helper?: string
  }[]
}

export const searchHelpSections: SearchHelpSection[] = [
  {
    sectionTitle: 'Location',
    suggestions: [
      {
        title: 'Comma separated country / state / city pairings',
        content:
          '"Los Angeles, California", "Paris, France", "British Columbia, Canada"',
        copyContent: 'Los Angeles, California',
      },
      {
        title: 'Comma separated Latitude / Longitude',
        content: '"40.705272, -73.996182"',
        copyContent: '40.705272, -73.996182',
      },
    ],
  },
  {
    sectionTitle: 'Add time period to the end of search',
    suggestions: [
      {
        title: 'Specific Date',
        content: '"Paris France July 26th 2024, New Orleans 02/09/25"',
        copyContent: 'Paris France July 26th 2024',
      },
      {
        title: 'Specific Range',
        content: '"Hanoi September 7th - September 14th"',
        copyContent: 'Hanoi September 7th - September 14th',
      },
      {
        title: 'Relative Range',
        content: '"Washington DC Past 30 days", "Denmark past 5 months"',
        copyContent: 'Denmark past 5 months',
      },
    ],
  },
  {
    sectionTitle: 'Object detection',
    suggestions: [
      {
        title: 'Danti Content Type + Location',
        content: '"Ships in the Port of Djibouti", "Fires in Tasmania"',
        copyContent: 'Ships in the Port of Djibouti',
      },
    ],
  },
  {
    sectionTitle: 'Topic, event, or natural disaster',
    suggestions: [
      {
        title: 'Natural Disaster',
        content: '"Denizli Wildfire"',
        copyContent: 'Denizli Wildfire',
      },
      {
        title: 'Specific Event',
        content: `"Macy's Day Parade"`,
        copyContent: `Macy's Day Parade`,
      },
    ],
  },
  {
    sectionTitle: 'Combine terms to further refine',
    suggestions: [
      {
        title: 'Topic + Location',
        content: '"Rail gauges in Ireland"',
        copyContent: 'Rail gauges in Ireland',
      },
      {
        title: 'Danti Content Type + Location + Time',
        content: '"Ships in the Port of Rotterdam 6/12/24 - 7/12/24"',
        copyContent: 'Ships in the Port of Rotterdam 6/12/24 - 7/12/24',
        helper:
          'Please note our AIS data is for demo purposes only and is limited to two time periods: 2/8/24 - 4/20/24 and 6/12/24 - 7/12/24',
      },
    ],
  },
  {
    sectionTitle: 'You can also use the map to search',
    suggestions: [
      {
        title: 'File',
        content: 'Upload a GeoJSON file',
      },
      {
        title: 'By Shape',
        content:
          'Draw a polygon, circle, square or search the visible map area',
      },
    ],
  },
]
