import { useState } from 'react'
import {
  Center,
  Group,
  List,
  rem,
  Stack,
  Table,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { type BaseSection, isStringRecord } from '../../types/insight-types'
import { BaseSection as BaseSectionComponent } from './BaseSection'
import { LinkSection } from './LinkSection'
interface ThProps {
  children: React.ReactNode
  reversed: boolean
  sorted: boolean
  onSort: () => void
}

const Th = ({ children, reversed, sorted, onSort }: ThProps) => {
  const icon = sorted
    ? reversed
      ? 'keyboard_arrow_up'
      : 'keyboard_arrow_down'
    : 'unfold_more'

  return (
    <Table.Th>
      <UnstyledButton onClick={() => onSort()} style={{ width: '100%' }}>
        <Group justify="space-between">
          <Text fw={500} size="md">
            {children}
          </Text>
          <Center>
            <Icon name={icon} size="1rem" />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  )
}

interface SectionItemProps {
  title: string
  children: React.ReactNode
}

function SectionItem({ title, children }: SectionItemProps) {
  return (
    <Stack gap="xs">
      <Text fw={500}>{title}</Text>
      {children}
    </Stack>
  )
}

export function WhitespaceSection({
  title,
  content,
  subsections,
}: BaseSection) {
  if (!subsections) {
    return null
  }

  const StatTable = ({
    title,
    data,
  }: {
    title: string
    data: Record<string, string>
  }) => {
    const [sortBy, setSortBy] = useState<'name' | 'count'>('count')
    const [reversedSort, setReversedSort] = useState(false)

    const sortData = (entries: [string, string][]) => {
      const sorted = [...entries]
      const multiplier = reversedSort ? -1 : 1

      return sorted.sort(([keyA, valueA], [keyB, valueB]) => {
        if (sortBy === 'name') {
          return keyA.localeCompare(keyB) * multiplier
        }
        return (Number.parseInt(valueB) - Number.parseInt(valueA)) * multiplier
      })
    }

    const toggleSort = (field: 'name' | 'count') => {
      if (sortBy === field) {
        setReversedSort(!reversedSort)
      } else {
        setSortBy(field)
        setReversedSort(false)
      }
    }

    const entries = Object.entries(data)
    const rowHeight = 40 // height per row in pixels
    const headerHeight = 40 // height of header in pixels
    const tableHeight = Math.min(
      entries.length * rowHeight + headerHeight,
      6 * rowHeight,
    )

    return (
      <Stack gap="xs">
        <Text fw={500} size="md">
          {title}
        </Text>
        <Table.ScrollContainer
          minWidth={400}
          h={rem(tableHeight)}
          type={entries.length > 5 ? 'scrollarea' : 'native'}
        >
          {/* Dynamic height based on number of rows, max 5 rows + header */}
          <Table
            striped
            highlightOnHover
            withTableBorder
            verticalSpacing="xs"
            layout="fixed"
          >
            <Table.Thead>
              <Table.Tr>
                <Th
                  sorted={sortBy === 'name'}
                  reversed={reversedSort}
                  onSort={() => toggleSort('name')}
                >
                  {title === 'Vessel Flag Statistics'
                    ? 'Country/Region'
                    : 'Vessel Type'}
                </Th>
                <Th
                  sorted={sortBy === 'count'}
                  reversed={reversedSort}
                  onSort={() => toggleSort('count')}
                >
                  Count
                </Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {sortData(Object.entries(data)).map(([key, value]) => (
                <Table.Tr key={key}>
                  <Table.Td>{key}</Table.Td>
                  <Table.Td>{value}</Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Table.ScrollContainer>
      </Stack>
    )
  }

  const renderSection = (section: BaseSection) => {
    switch (section.type) {
      case 'text':
        return (
          <List.Item key={section.title} mb="sm">
            <SectionItem title={section.title}>
              <Text>{section.content as string}</Text>
            </SectionItem>
          </List.Item>
        )

      case 'keyValue':
        return (
          <List.Item key={section.title} mb="sm">
            <StatTable
              title={section.title}
              data={section.content as Record<string, string>}
            />
          </List.Item>
        )

      case 'links':
        return (
          <List.Item key={section.title} mb="sm">
            <SectionItem title={section.title}>
              <List withPadding listStyleType="disc" spacing="xs">
                <LinkSection
                  title={section.title}
                  type={section.type}
                  content={
                    Array.isArray(section.content) ||
                    isStringRecord(section.content)
                      ? section.content
                      : []
                  }
                  isNested
                />
              </List>
            </SectionItem>
          </List.Item>
        )

      default:
        return null
    }
  }

  return (
    <BaseSectionComponent title={title}>
      <List withPadding listStyleType="disc">
        {typeof content === 'string' && !subsections && (
          <List.Item>
            <Text>{content}</Text>
          </List.Item>
        )}
        {subsections?.map((subsection) => renderSection(subsection))}
      </List>
    </BaseSectionComponent>
  )
}
