import {
  type FC,
  type MouseEventHandler,
  type MutableRefObject,
  useEffect,
} from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ActionIcon,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Menu,
  SegmentedControl,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { MAP_DRAW_MODES } from '@/features/DantiMap/constants'
import { useSearch } from '@/features/Search/hooks'
import { useClearAll } from '@/hooks/use-clear-all'
import { useHaveFiltersUpdated } from '@/stores/filters-store'
import {
  useDrawController,
  useDrawMode,
  useIsDrawing,
  useSetDrawMode,
  useStartDrawingShape,
  useStopDrawingShape,
} from '@/stores/map-draw-store'
import {
  type BaseLayerOption,
  useBaseLayer,
  useSetBaseLayer,
} from '@/stores/map-store'
import { useCurrentQuery, useSavedSearchId } from '@/stores/queries-store'
import { MAP_BASE_LAYERS, ROUTES } from '@/utils/constants'
import { LayerPicker } from './LayerPicker'

type MapControlPanelProps = {
  openFileDialog: MouseEventHandler<HTMLElement>
  map: MutableRefObject<mapboxgl.Map | null>
}

const DISABLED_BUTTON_STYLE = {
  size: 'xs',
  variant: 'light',
}

const BUTTON_STYLE = {
  size: 'xs',
  variant: 'light',
  color: 'gray',
  c: 'gray.7',
}

export const MapControlPanel: FC<MapControlPanelProps> = ({
  map,
  openFileDialog,
}) => {
  const currentQuery = useCurrentQuery()
  const baseLayer = useBaseLayer()
  const setBaseLayer = useSetBaseLayer()
  const isDrawing = useIsDrawing()
  const startDrawingShape = useStartDrawingShape()
  const stopDrawingShape = useStopDrawingShape()
  const setDrawMode = useSetDrawMode()
  const { doLocationSearch } = useSearch()
  const clearAll = useClearAll()
  const haveFiltersUpdated = useHaveFiltersUpdated()
  const drawController = useDrawController()
  const drawMode = useDrawMode()
  const savedSearchId = useSavedSearchId()
  const isSavedSearch = savedSearchId !== ''
  const navigate = useNavigate()

  const isClearButtonDisabled =
    !isSavedSearch && !(currentQuery || haveFiltersUpdated)
  const clearButtonStyle = isClearButtonDisabled
    ? DISABLED_BUTTON_STYLE
    : BUTTON_STYLE

  const handleClearClick = () => {
    clearAll()
    navigate(ROUTES.explore)
  }

  useEffect(() => {
    drawController?.changeMode(drawMode)
  }, [drawController, drawMode])

  const doSearchInWindow = () => {
    const b = map.current?.getBounds()
    if (b) {
      const poly = [
        [b.getNorthWest().lng, b.getNorthWest().lat],
        [b.getNorthEast().lng, b.getNorthEast().lat],
        [b.getSouthEast().lng, b.getSouthEast().lat],
        [b.getSouthWest().lng, b.getSouthWest().lat],
        [b.getNorthWest().lng, b.getNorthWest().lat],
      ]
      doLocationSearch(JSON.stringify(poly), 'polygon')
    }
  }

  return (
    <Group
      p="xs"
      wrap="nowrap"
      style={{ borderLeft: '1px solid var(--mantine-color-default-border)' }}
    >
      <Group gap="xs">
        <Button.Group>
          <Button
            {...BUTTON_STYLE}
            leftSection={<Icon name="upload_file" size={16} />}
            onClick={openFileDialog}
            visibleFrom="md"
          >
            File
          </Button>
          <ActionIcon
            {...BUTTON_STYLE}
            size="md"
            hiddenFrom="md"
            onClick={openFileDialog}
          >
            <Icon name="upload_file" size={16} />
          </ActionIcon>
          <Divider orientation="vertical" />
          <Menu>
            <Menu.Target>
              <Box>
                <Button
                  {...BUTTON_STYLE}
                  leftSection={<Icon name="polyline" size={16} />}
                  visibleFrom="md"
                >
                  Shape
                </Button>
                <ActionIcon {...BUTTON_STYLE} size="md" hiddenFrom="md">
                  <Icon name="polyline" size={16} />
                </ActionIcon>
              </Box>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                leftSection={<Icon name="polyline" />}
                onClick={() => {
                  if (isDrawing && drawMode === MAP_DRAW_MODES.POLYGON) {
                    stopDrawingShape()
                  } else {
                    setDrawMode(MAP_DRAW_MODES.POLYGON)
                    startDrawingShape()
                  }
                }}
                {...(isDrawing &&
                  drawMode === MAP_DRAW_MODES.POLYGON && {
                    'data-active': true,
                  })}
              >
                Polygon
              </Menu.Item>
              <Menu.Item
                leftSection={<Icon name="screen_search_desktop" />}
                onClick={() => void doSearchInWindow()}
              >
                Current View
              </Menu.Item>
              <Menu.Item
                leftSection={<Icon name="location_on" />}
                onClick={() => {
                  if (isDrawing && drawMode === MAP_DRAW_MODES.POINT) {
                    stopDrawingShape()
                  } else {
                    setDrawMode(MAP_DRAW_MODES.POINT)
                    startDrawingShape()
                  }
                }}
                {...(isDrawing &&
                  drawMode === MAP_DRAW_MODES.POINT && {
                    'data-active': true,
                  })}
              >
                Point
              </Menu.Item>
              <Menu.Item
                leftSection={<Icon name="circle" />}
                onClick={() => {
                  if (isDrawing && drawMode === MAP_DRAW_MODES.CIRCLE) {
                    stopDrawingShape()
                  } else {
                    setDrawMode(MAP_DRAW_MODES.CIRCLE)
                    startDrawingShape()
                  }
                }}
                {...(isDrawing &&
                  drawMode === MAP_DRAW_MODES.CIRCLE && {
                    'data-active': true,
                  })}
              >
                Circle
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Button.Group>
      </Group>
      <LayerPicker buttonStyle={BUTTON_STYLE} />
      <SegmentedControl
        size="xs"
        value={baseLayer}
        onChange={(value) => setBaseLayer(value as BaseLayerOption)}
        data={[
          {
            value: MAP_BASE_LAYERS.dantiStreets,
            label: (
              <Flex align="center" fw="bold" gap="xs">
                <Icon name="map" size={16} />
                <Box visibleFrom="lg">Default</Box>
              </Flex>
            ),
          },
          {
            value: MAP_BASE_LAYERS.satellite,
            label: (
              <Flex align="center" fw="bold" gap="xs">
                <Icon name="public" size={16} />
                <Box visibleFrom="lg">Satellite</Box>
              </Flex>
            ),
          },
        ]}
      />
      <Flex justify="flex-end" flex={1}>
        <Button
          {...clearButtonStyle}
          visibleFrom="xl"
          leftSection={<Icon name="cancel" size={16} />}
          disabled={isClearButtonDisabled}
          onClick={handleClearClick}
        >
          Clear Search
        </Button>
        <ActionIcon
          {...clearButtonStyle}
          size="md"
          hiddenFrom="xl"
          disabled={isClearButtonDisabled}
          onClick={handleClearClick}
        >
          <Icon name="cancel" size={16} />
        </ActionIcon>
      </Flex>
    </Group>
  )
}
