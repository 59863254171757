import React from 'react'
import {
  Autocomplete,
  Box,
  type ComboboxParsedItem,
  Loader,
  Text,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import {
  AUTOCOMPLETE_INPUT_PROPS,
  AUTOCOMPLETE_LOCATION_PLACEHOLDER,
} from '@/features/Search/constants'
import type { AutoCompleteValues, LocationField } from '@/features/Search/types'
import { classes } from '@/utils/ui-helpers'

import styles from '@/features/Search/search-bar.module.css'

export const NO_RESULT_TEXT = 'No results found'

export interface LocationAutocompleteProps {
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  autoComplete: AutoCompleteValues
  locationProps: LocationField
  clearLocationValue: () => void
  compact?: boolean
}

export const LocationAutocomplete = React.forwardRef<
  HTMLDivElement,
  LocationAutocompleteProps
>(
  (
    {
      handleInlineHotkeyPress,
      autoComplete,
      locationProps,
      clearLocationValue,
      compact,
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false)

    const onLocationKeyDown = (event: KeyboardEvent | React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        if (locationProps.isLoading) {
          event.preventDefault()
          return
        }

        // If the user hasn't selected an autocomplete, auto select the highest relevance one
        const data = locationProps.data.filter((x) => x.value !== 'error')
        if (data.length > 0) {
          locationProps.onChange(data[0].label)
          autoComplete.setErrorMessage('')
        }
        return
      }
      handleInlineHotkeyPress(event)
    }

    const locationIndicator = locationProps.isLoading ? (
      <Loader size="xs" color="gray" />
    ) : (
      <Icon name="my_location" />
    )

    const { isLoading, ...locationElementProps } = locationProps

    // Results returned from the API with highest relevant is on top of array
    // Reversing it will display the highest relevant cloest to the <Autocomplete /> input field
    const reversedData = [...locationElementProps.data].reverse()

    const noResultsFound =
      isFocused &&
      !isLoading &&
      locationElementProps.data.length === 0 &&
      autoComplete.debouncedLocationValue.trim().length > 2

    return (
      <Box
        className={classes(
          styles.locationInputContainer,
          compact && styles.compact,
        )}
        ref={ref}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {noResultsFound && (
          <Text className={styles.noResultFound} top={-42} size="xs">
            {NO_RESULT_TEXT}
          </Text>
        )}
        <Autocomplete
          id="location-search"
          required
          placeholder={AUTOCOMPLETE_LOCATION_PLACEHOLDER}
          onKeyDown={onLocationKeyDown}
          leftSection={locationIndicator}
          classNames={{
            input: styles.locationInput,
            root: styles.locationInputWrapper,
            option: styles.option,
          }}
          filter={({ options }: { options: ComboboxParsedItem[] }) => options}
          rightSection={
            autoComplete.suggestionSelected && (
              <Icon
                name="highlight_off"
                onClick={clearLocationValue}
                style={{ cursor: 'pointer' }}
              />
            )
          }
          {...AUTOCOMPLETE_INPUT_PROPS}
          {...locationElementProps}
          data={reversedData}
          comboboxProps={{
            position: 'top',
            middlewares: { flip: false, shift: false },
          }}
        />
      </Box>
    )
  },
)

LocationAutocomplete.displayName = 'LocationAutocomplete'
