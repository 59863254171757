import { Flex, Group, LoadingOverlay, Stack } from '@mantine/core'
import { usePaginatedResults, useSearchStatus } from '@/api/results'
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '@/features/AppLayout'
import { Map } from '@/features/DantiMap'
import { SearchBar } from '@/features/Search'
import { useHaveFiltersUpdated } from '@/stores/filters-store'
import { useCurrentQuery, useSavedSearchId } from '@/stores/queries-store'

import styles from './split-screen-wrapper.module.css'

export function SplitScreenWrapper({
  children,
}: {
  children: React.ReactNode
}) {
  const { queries } = usePaginatedResults()
  const searchStatus = useSearchStatus()
  const haveFiltersUpdated = useHaveFiltersUpdated()
  const savedSearchId = useSavedSearchId()
  const currentQuery = useCurrentQuery()
  const isLoadingPaginatedResult = Object.values(queries).some(
    (q) => q.isLoading,
  )
  const isSavedSearch = savedSearchId !== ''
  const noSearchInitiated =
    !isSavedSearch && !(currentQuery || haveFiltersUpdated)
  const isSearchLoading =
    (searchStatus === 'pending' || isLoadingPaginatedResult) &&
    !noSearchInitiated

  return (
    <Group
      gap={0}
      align="flex-start"
      pt={HEADER_HEIGHT}
      h={`calc(100vh - ${HEADER_HEIGHT}px)`}
    >
      <Stack gap={0} h="inherit" className={styles.contentContainer}>
        {children}
        <Flex h={FOOTER_HEIGHT} px="md" align="center" justify="center">
          <SearchBar compact />
        </Flex>
      </Stack>
      <Stack gap={0} h="inherit" pos="relative" className={styles.mapContainer}>
        <LoadingOverlay
          visible={isSearchLoading}
          zIndex="var(--mantine-z-index-app)"
          overlayProps={{ bg: 'transparent', blur: 3 }}
        />
        <Map />
      </Stack>
    </Group>
  )
}
