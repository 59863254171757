import type {
  BaseSection,
  KeyValueSectionProps,
  LinkSectionProps,
  ListSectionProps,
  NestedSectionProps,
  TextSectionProps,
  WhitespaceSectionProps,
} from '../../types/insight-types'
import {
  KeyValueSection,
  LinkSection,
  ListSection,
  NestedSection,
  TextSection,
  WhitespaceSection,
} from './index'

export function InsightSection({ section }: { section: BaseSection }) {
  switch (section.type) {
    case 'list':
      return <ListSection {...(section as ListSectionProps)} />
    case 'links':
      return <LinkSection {...(section as LinkSectionProps)} />
    case 'text':
      return <TextSection {...(section as TextSectionProps)} />
    case 'nested':
      return <NestedSection {...(section as NestedSectionProps)} />
    case 'keyValue':
      return <KeyValueSection {...(section as KeyValueSectionProps)} />
    case 'whitespace':
      return <WhitespaceSection {...(section as WhitespaceSectionProps)} />
  }
}
