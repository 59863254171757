import { forwardRef } from 'react'
import { Button, type ButtonProps } from '@mantine/core'
import { ConditionalTooltip } from '@/components/lib/ConditionalTooltip'
import { Icon } from '@/components/lib/Icon'
import { SAVED_SEARCH_DISABLED_MESSAGE } from '@/features/Collections/constants.ts'
import { openSearchFiltersModal } from '@/features/Filters/open-search-filters-modal'
import { useSavedSearchId } from '@/stores/queries-store'

type FilterToggleButtonProps = ButtonProps & {
  activeColor?: null | string
}
export const FilterToggleButton = forwardRef<
  HTMLButtonElement,
  FilterToggleButtonProps
>(
  (
    {
      children = null,
      size = 'sm',
      variant = 'subtle',
      activeColor = 'dark',
      ...buttonProps
    },
    ref,
  ) => {
    const savedSearchId = useSavedSearchId()
    const isSavedSearch = savedSearchId !== ''
    const defaultSize = children ? size : 'md'

    const icon = <Icon size={defaultSize} name="filter_alt" />

    return (
      <ConditionalTooltip
        label={isSavedSearch ? SAVED_SEARCH_DISABLED_MESSAGE : undefined}
      >
        <Button
          ref={ref}
          variant={variant}
          size={defaultSize}
          fw="normal"
          color={activeColor ?? buttonProps.color ?? 'blue'}
          onClick={() => openSearchFiltersModal({ isPostFilter: true })}
          {...(children && { leftSection: icon })}
          {...buttonProps}
          disabled={isSavedSearch}
        >
          {children || icon}
        </Button>
      </ConditionalTooltip>
    )
  },
)

FilterToggleButton.displayName = 'FilterToggleButton'
