import { Button, Stack, Text } from '@mantine/core'
import { toastError, toastSuccess } from '@/components/toasts.ts'
import { graphql } from '@/gql'
import { useMutation, useQuery } from '@apollo/client'

export const UserIDQuery = graphql(`
  query UserID {
    me {
      id
    }
  }
`)

export const ResetPasswordMutation = graphql(`
  mutation ResetPassword($userId: ID!) {
    resetPassword(userId: $userId)
  }
`)

export function Password() {
  const { data } = useQuery(UserIDQuery)
  const [resetPassword] = useMutation(ResetPasswordMutation)
  const passwordResetText = `Click the button below to reset your password. You will receive an email with instructions.`

  const handleResetPassword = () => {
    void resetPassword({
      variables: {
        userId: data?.me?.id ?? '',
      },
      onCompleted: () => {
        toastSuccess({
          message: 'Reset password instructions sent to your email',
        })
      },
      onError: () => {
        toastError({ message: 'Error resetting password' })
      },
    })
  }

  return (
    <Stack>
      <Stack style={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)' }}>
        <Text fs="20px" fw="500" lh="160%" lts="0.15px">
          Password
        </Text>
      </Stack>
      <Stack>
        <Text fs="18px" fw="400">
          {passwordResetText}
        </Text>
        <Button
          onClick={handleResetPassword}
          style={{ alignSelf: 'flex-start' }}
        >
          Reset Password
        </Button>
      </Stack>
    </Stack>
  )
}
