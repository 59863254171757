import { Button } from '@mantine/core'
import { DantiWordMark } from '@/components/lib/DantiWordMark'
import { useDantiAuth } from '@/hooks/use-danti-auth'

export function HeaderUnauthenticated() {
  const { logIn } = useDantiAuth()

  return (
    <>
      <DantiWordMark />
      <Button size="xs" onClick={logIn}>
        Log In
      </Button>
    </>
  )
}
