import { Title } from '@mantine/core'
import { modals } from '@mantine/modals'
import type { Collection, CollectionItem } from '@/api/collections/types'
import type { ImageryResult, ResultWithId } from '@/utils/types/result-types'
import type { AnnouncementModalProps } from './AnnouncementModal'
import type { SaveSearchSettingsModalParams } from './SaveSearchModal'

export function openCreateCollectionModal(): void {
  modals.openContextModal({
    modal: 'createCollection',
    title: 'Create a new Saved Search',
    centered: true,
    innerProps: {},
  })
}

export function openUpdateCollectionModal(collection: Collection): void {
  modals.openContextModal({
    modal: 'updateCollection',
    title: 'Update Saved Search',
    centered: true,
    innerProps: { collection },
  })
}

export function openAddToCollectionModal(
  item: ResultWithId,
  collectionId?: string,
): void {
  modals.openContextModal({
    modal: 'addToCollection',
    title: 'Select a Saved Search',
    centered: true,
    innerProps: { item, collectionId },
  })
}

export function openRemoveFromCollectionModal(
  collectionId: string,
  itemId: string,
): void {
  modals.openContextModal({
    modal: 'removeFromCollection',
    title: 'Remove from Saved Search',
    centered: true,
    innerProps: { collectionId, itemId },
  })
}

export function openDeleteCollectionModal(collectionId: string): void {
  modals.openContextModal({
    modal: 'deleteCollection',
    title: 'Delete Saved Search',
    centered: true,
    innerProps: { collectionId },
  })
}

export function openShareCollectionModal(collectionId: string): void {
  modals.openContextModal({
    modal: 'shareCollection',
    title: 'Share Saved Search',
    centered: true,
    innerProps: { collectionId },
  })
}

export function openInviteUserModal(): void {
  modals.openContextModal({
    innerProps: undefined,
    modal: 'inviteUser',
    size: '512px',
    title: (
      <Title size={32} pt={20} pl={16} mb={-24}>
        Invite users to your organization
      </Title>
    ),
    centered: true,
  })
}

export function openAnalyzeCollectionModal(collectionId: string): void {
  modals.openContextModal({
    modal: 'analyzeCollection',
    title: 'What would you like to identify?',
    centered: true,
    innerProps: { collectionId },
  })
}

export function openAnalyzeItemModal(item: ImageryResult): void {
  modals.openContextModal({
    modal: 'analyzeItem',
    title: 'What would you like to identify?',
    centered: true,
    innerProps: { item },
  })
}

export function openDownloadItemModal(item: ImageryResult): void {
  modals.openContextModal({
    modal: 'downloadItem',
    title: 'Request Download Transfer',
    centered: true,
    innerProps: { item },
  })
}

export function openShareModal(collection: Collection | CollectionItem): void {
  modals.openContextModal({
    modal: 'share',
    size: '512px',
    centered: true,
    withCloseButton: false,
    innerProps: { collection },
  })
}

export function openResultDetailsModal(result: ResultWithId): void {
  modals.openContextModal({
    modal: 'resultDetails',
    title: 'Article Details',
    size: 'xl',
    centered: true,
    innerProps: { result },
  })
}

export function openSaveSearchModal({
  refId,
  isNew,
  providers,
  params,
}: SaveSearchSettingsModalParams): void {
  modals.openContextModal({
    modal: 'saveSearch',
    title: (
      <span style={{ fontSize: '32px', fontWeight: 700 }}>
        Configure your Saved Search
      </span>
    ),
    size: 'lg',
    centered: true,
    innerProps: { refId, providers, isNew, params },
  })
}

export function openSessionConfirmationModal(): void {
  modals.openContextModal({
    modal: 'sessionConfirmation',
    title: 'Your session has expired',
    centered: true,
    innerProps: undefined,
    withCloseButton: false,
  })
}

export function openRemoveAllHistoryModal() {
  modals.openContextModal({
    modal: 'removeAllHistory',
    title: <Title size="h3">Delete all history</Title>,
    centered: true,
    withCloseButton: false,
    innerProps: undefined,
  })
}

export function openAnnouncementModal({
  setAcknowledged,
  forceInteraction = false,
}: AnnouncementModalProps) {
  modals.openContextModal({
    modal: 'announcement',
    centered: true,
    withCloseButton: false,
    size: '900px',
    closeOnEscape: !forceInteraction,
    closeOnClickOutside: !forceInteraction,
    onClose: () => setAcknowledged(true),
    innerProps: { setAcknowledged },
  })
}
