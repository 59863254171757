import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
} from 'react-router-dom'
import { MainLayout } from '@/features/AppLayout/MainLayout'
import { AppContainer } from '@/features/AppLayout/MainLayout/AppContainer'
import {
  Authenticated,
  DantiRoute,
  RequireSubscription,
} from '@/features/AppRouting/components'
import {
  APP_ROUTES,
  ROOT_ROUTES,
  ROUTES_BY_PATH,
} from '@/features/AppRouting/constants'
import { AccountRoutes } from '@/pages/Account/routes'
import { ResultsOverview } from '@/pages/ResultsOverview'
import { SplitScreenWrapper } from '@/pages/ResultsOverview/components/SplitScreenWrapper.tsx'
import { SingleObjectView } from '@/pages/SingleObjectView'
import { ROUTES } from '@/utils/constants'
import { wrapCreateBrowserRouterV6 } from '@sentry/react'

export const renderRoute = (path: string) => {
  const route = ROUTES_BY_PATH[path]
  return (
    <Route
      key={path}
      data-testid={path}
      path={path}
      element={
        <AppContainer>
          <DantiRoute {...route} />
        </AppContainer>
      }
    />
  )
}

export const router = wrapCreateBrowserRouterV6(createBrowserRouter)(
  createRoutesFromElements(
    <Route data-testid="main-layout-route" element={<MainLayout />}>
      {ROOT_ROUTES.map(renderRoute)}
      <Route
        data-testid="authenticated-routes"
        element={
          <Authenticated>
            <RequireSubscription>
              <Outlet />
            </RequireSubscription>
          </Authenticated>
        }
      >
        <Route
          element={
            <SplitScreenWrapper>
              <Outlet />
            </SplitScreenWrapper>
          }
        >
          <Route path={ROUTES.explore} element={<ResultsOverview />} />
          <Route path={ROUTES.resultsItem} element={<SingleObjectView />} />
        </Route>
        {APP_ROUTES.map(renderRoute)}
        {AccountRoutes}
      </Route>
      <Route
        data-testid="authenticated-no-subscription-routes"
        element={
          <Authenticated>
            <Outlet />
          </Authenticated>
        }
      >
        {renderRoute(ROUTES.planSelection)}
      </Route>
    </Route>,
  ),
)
