import { BarChart } from '@mantine/charts'
import { CHART_HEIGHT } from '@/features/ResultsTimeline/constants'
import type {
  HistogramDataPoint,
  TimelineDoctypes,
  TooltipPayload,
} from '@/features/ResultsTimeline/types'
import {
  formatXAxisTick,
  getCategoryColor,
} from '@/features/ResultsTimeline/utils'
import { ResultsTimelineTooltip } from './ResultsTimelineTooltip'

interface ResultsTimelineBarGraphProps {
  data: HistogramDataPoint[]
  categories: TimelineDoctypes[]
  small: boolean
  dateRange: {
    min: Date
    max: Date
  }
}

export function ResultsTimelineBarGraph({
  data,
  categories,
  dateRange,
}: ResultsTimelineBarGraphProps) {
  const series = categories.map((name) => ({
    name,
    color: getCategoryColor(name),
  }))
  return (
    <BarChart
      h={CHART_HEIGHT}
      data={data}
      dataKey="date"
      type="stacked"
      orientation="horizontal"
      tickLine="x"
      series={series}
      gridAxis="none"
      xAxisProps={{
        tickFormatter: formatXAxisTick,
        axisLine: { stroke: 'var(--mantine-color-gray-4)' },
        tick: {
          stroke: 'var(--mantine-color-gray-7)',
          fontSize: 10,
          fontWeight: 100,
          letterSpacing: 1,
        },
      }}
      withYAxis={false}
      tooltipProps={{
        // forwarded props for Tooltip component
        // disables cursor (highlight) and sets custom tooltip content
        cursor: false,
        content: ({ payload }: { payload?: TooltipPayload[] }) => (
          <ResultsTimelineTooltip {...{ payload, data, dateRange }} />
        ),
      }}
    />
  )
}
