import { type LinkProps, NavLink } from 'react-router-dom'
import { Group, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import type { SymbolName } from '@/utils/types/material-icons'
import { classes } from '@/utils/ui-helpers.tsx'

import styles from '../header.module.css'

export function HeaderLink({
  to,
  title,
  icon,
}: LinkProps & { icon: SymbolName; title: string }) {
  return (
    <NavLink
      to={to}
      onClick={() =>
        captureEvent(USER_EVENTS.HEADER.CLICKED, { headerItem: title })
      }
      className={({ isActive }) =>
        classes(styles.link, isActive && styles.active)
      }
    >
      <Group gap="xs" c="white" py="xxs">
        <Icon name={icon} />
        <Text size="sm" tt="uppercase">
          {title}
        </Text>
      </Group>
    </NavLink>
  )
}
