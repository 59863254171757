import {
  Button,
  type ButtonProps,
  FileButton,
  type FileButtonProps,
} from '@mantine/core'
import { ACCEPTED_FILE_FORMATS } from '@/components/lib/FileUpload/constants'
import { openFileUploadModal } from '@/components/lib/FileUpload/opener'
import { useAddFiles } from '@/components/lib/FileUpload/state'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ReactNode } from 'react'

interface FileUploadButtonProps
  extends Omit<FileButtonProps, 'children' | 'onChange'>,
    Omit<ButtonProps, 'children'> {
  collectionId: string | null
  iconName?: SymbolName
  children: ReactNode
}

export function FileUploadButton({
  children,
  collectionId,
  iconName = 'file_upload',
  variant = 'outline',
  ...buttonProps
}: FileUploadButtonProps) {
  const addFiles = useAddFiles()

  return (
    <FileButton
      {...buttonProps}
      onChange={(data) => {
        addFiles(data)
        openFileUploadModal(collectionId)
      }}
      accept={ACCEPTED_FILE_FORMATS.join(',')}
      multiple
    >
      {(props) => (
        <Button
          {...props}
          leftSection={<Icon name={iconName} />}
          size="xs"
          variant={variant}
        >
          {children}
        </Button>
      )}
    </FileButton>
  )
}
