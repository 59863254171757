import { getNewsImages } from '@/api/results'
import { ExternalLink } from '@/features/ExternalLink'
import { NewsReferenceCard } from '@/features/ResultCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { PublicationResult } from '@/utils/types/result-types'
import { useResultNavigation } from '../hooks'

export function NewsObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as PublicationResult
  const images = getNewsImages(item)
  const { selectItem, items } = useResultNavigation<PublicationResult>(item)

  const metaDetails = [formatDatetime(item.authoredOn)]
  if (item.properties._dSource) {
    metaDetails.push(item.properties._dSource)
  }

  return (
    <SingleObjectViewTemplate<PublicationResult, PublicationResult>
      {...{
        item,
        hero: {
          staticImage: images.length > 0 ? images[0].url : null,
          fallbackIcon: 'newspaper',
          imageAlt: images.length > 0 ? images[0].caption || '' : undefined,
          metaDetails,
          metaAction: (
            <ExternalLink href={item.properties.link}>Source</ExternalLink>
          ),
        },
        content: {
          title: item.title,
          details: item.properties.summary,
          detailsLabel: 'Danti Summary',
        },
        carousel: {
          title: 'More News',
          items,
          selectItem,
          categoryPageLink: {
            url: `${ROUTES.explore}?tab=news`,
            label: 'All News',
          },
          renderCard: (article: PublicationResult) => (
            <NewsReferenceCard result={article} />
          ),
        },
      }}
    />
  )
}
