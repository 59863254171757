import { useNavigate } from 'react-router-dom'
import { Anchor, List } from '@mantine/core'
import { warnExternalLink } from '@/features/ExternalLink/components/warn-external-link'
import type { BaseSection } from '../../types/insight-types'
import { BaseSection as BaseSectionComponent } from './BaseSection'

type LinkSectionProps = BaseSection & {
  content: string[] | Record<string, string>
}

export function LinkSection({
  title,
  content,
  isNested = false,
}: LinkSectionProps & { isNested?: boolean }) {
  const navigate = useNavigate()

  const handleClick =
    (url: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()
      if (url.startsWith('/')) {
        navigate(url)
      } else {
        warnExternalLink(url)
      }
    }

  // Filter and transform items before rendering
  const links = Array.isArray(content)
    ? content
        .filter((item): item is string => typeof item === 'string')
        .map((item) => item.split('|'))
        .filter((parts): parts is [string, string] => parts.length === 2)
    : Object.entries(content as Record<string, string>).map(([text, url]) => [
        text,
        url,
      ])

  const linkItems = links.map(([text, url]) => (
    <List.Item key={`${text}-${url}`}>
      <Anchor href={url} onClick={handleClick(url)}>
        {text}
      </Anchor>
    </List.Item>
  ))

  if (isNested) {
    return <>{linkItems}</>
  }

  return (
    <BaseSectionComponent title={title}>
      <List spacing="xs">{linkItems}</List>
    </BaseSectionComponent>
  )
}
