import {
  Center,
  Container,
  Divider,
  Group,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ReactNode } from 'react'

import styles from './hero.module.css'

export interface SOVHeroDetailsProps {
  details?: {
    title: string
    icon?: ReactNode
    iconName?: SymbolName
    color: string
    leftDetail?: string
    rightDetail?: string
    detailsList?: ReactNode[]
  }
}

const applyRandomIds = (column: ReactNode[]) =>
  column.map((node) => ({ id: crypto.randomUUID(), detail: node }))

export function SOVHeroDetails({ details }: SOVHeroDetailsProps) {
  if (!details) {
    return null
  }

  const detailsListIds = applyRandomIds(details.detailsList || [])

  return (
    <Container bg="gray.3" w="100%" p="sm" display="flex">
      <Stack
        gap="xs"
        bg="white"
        p="sm"
        mb="xs"
        w="100%"
        style={{
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.25)',
        }}
      >
        <Center
          p="sm"
          w={80}
          h={80}
          className={styles.heroIcon}
          style={{ backgroundColor: details.color }}
        >
          {details.icon}
          {details.iconName && <Icon name={details.iconName} />}
        </Center>
        <Title order={3} tt="capitalize">
          {details.title}
        </Title>
        <Group gap="xs" justify="space-between">
          <Title order={4}>{details.leftDetail}</Title>
          <Title order={4}>{details.rightDetail}</Title>
        </Group>
        <Divider />
        {detailsListIds.map(({ id, detail }) => (
          <Text key={id}>{detail}</Text>
        ))}
      </Stack>
    </Container>
  )
}
