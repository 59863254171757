import { Button, MantineProvider, Stack, Text } from '@mantine/core'
import { formatDate } from '@/utils/format-datetime'
import type { ResultWithId } from '@/utils/types/result-types'
import type React from 'react'

type MapPointInfoTooltipProps = {
  result: ResultWithId
  navigateToResult: (result: ResultWithId) => void
}

export const MapPointInfoTooltip: React.FC<MapPointInfoTooltipProps> = ({
  result,
  navigateToResult,
}) => {
  return (
    <MantineProvider>
      <Stack gap="xs">
        <Text c="white" size="xs">
          {formatDate(result?.properties.datetime)}
        </Text>
        <Text c="white" size="sm">
          {result?.title}
        </Text>
        <Button
          size="xs"
          variant="subtle"
          style={{ alignSelf: 'end' }}
          onClick={() => navigateToResult(result)}
        >
          View Details
        </Button>
      </Stack>
    </MantineProvider>
  )
}
