import { ActionIcon, Button, Group, Text, Tooltip } from '@mantine/core'
import { usePropertyResults, useSearchStatus } from '@/api/results'
import { Icon } from '@/components/lib/Icon'
import { QUERY_HEADER_HEIGHT } from '@/features/AppLayout'
import { openSaveSearchModal } from '@/features/AppLayout/Modals'
import { useBackNavigation } from '@/features/AppRouting'
import { useProviders } from '@/features/Filters'
import {
  useCurrentQuery,
  useSavedSearchId,
  useSavedSearchName,
} from '@/stores/queries-store'
import { SAVED_SEARCH_MODES } from '@/utils/constants'

export function QueryHeader({
  showBackNav = false,
}: {
  showBackNav?: boolean
}) {
  const currentQuery = useCurrentQuery()
  const savedSearchId = useSavedSearchId()
  const savedSearchName = useSavedSearchName()
  const { newProviders } = useProviders()
  const onGoBack = useBackNavigation()
  const isSavedSearch = savedSearchId !== ''
  const isProperty = usePropertyResults().length > 0
  const searchStatus = useSearchStatus()

  const getTooltipLabel = () => {
    if (isProperty) {
      return 'Saving a search is not available on property results'
    }
    if (isSavedSearch) {
      return 'This search is already saved'
    }
  }

  return (
    <Group
      justify="space-between"
      h={QUERY_HEADER_HEIGHT}
      px={{ base: 'sm', lg: 'md' }}
      style={{ borderBottom: '1px solid var(--mantine-color-default-border)' }}
      wrap="nowrap"
    >
      <Group gap="xs" miw={0} wrap="nowrap">
        {showBackNav && (
          <ActionIcon onClick={onGoBack} variant="subtle" c="dark">
            <Icon size={24} name="arrow_left_alt" />
          </ActionIcon>
        )}
        <Text title={currentQuery} fw="bold" truncate="end">
          {isSavedSearch ? `Exploring ${savedSearchName}` : currentQuery}
        </Text>
      </Group>
      <Group gap="xs" justify="flex-end">
        {/* <ActionIcon
          variant="filled"
          color="var(--mantine-color-gray-2)"
          c="dark"
        >
          <Icon size={20} name="share" />
        </ActionIcon> */}
        <Tooltip
          label={getTooltipLabel()}
          position="bottom"
          disabled={
            searchStatus === 'pending' || !(isProperty || isSavedSearch)
          }
        >
          <Button
            size="xs"
            disabled={searchStatus === 'pending' || isProperty || isSavedSearch}
            onClick={() =>
              openSaveSearchModal({
                isNew: true,
                providers: newProviders,
                params: {
                  query: currentQuery,
                  name: currentQuery,
                  emailNotification: '',
                  mode: SAVED_SEARCH_MODES.search,
                  locations: [],
                },
              })
            }
          >
            Save Search
          </Button>
        </Tooltip>
      </Group>
    </Group>
  )
}
