import { RESULT_CATEGORIES } from '@/utils/constants'
import { AttomBentoCard, type AttomBentoCardProps } from './AttomBentoCard'
import {
  CoreLogicPropertyBentoCard,
  type CoreLogicPropertyBentoCardProps,
} from './CoreLogicPropertyBentoCard'
import {
  CoreLogicRiskBentoCard,
  type CoreLogicRiskBentoCardProps,
} from './CoreLogicRiskBentoCard'
import { FireBentoCard, type FireBentoCardProps } from './FireBentoCard'
import {
  HazardPropertyBentoCard,
  type HazardPropertyBentoCardProps,
} from './HazardPropertyBentoCard'
import {
  HazardRiskBentoCard,
  type HazardRiskBentoCardProps,
} from './HazardRiskBentoCard'
import { ImageBentoCard, type ImageBentoCardProps } from './ImageBentoCard'
import { NewsBentoCard, type NewsBentoCardProps } from './NewsBentoCard'
import { RegridBentoCard, type RegridBentoCardProps } from './RegridBentoCard'
import { ShipBentoCard, type ShipBentoCardProps } from './ShipBentoCard'
import { SocialBentoCard, type SocialBentoCardProps } from './SocialBentoCard'
import { VexcelBentoCard, type VexelBentoCardProps } from './VexcelBentoCard'

type BentoCard =
  | AttomBentoCardProps
  | FireBentoCardProps
  | HazardPropertyBentoCardProps
  | VexelBentoCardProps
  | HazardRiskBentoCardProps
  | CoreLogicPropertyBentoCardProps
  | CoreLogicRiskBentoCardProps
  | ImageBentoCardProps
  | NewsBentoCardProps
  | RegridBentoCardProps
  | ShipBentoCardProps
  | SocialBentoCardProps

export const renderers = {
  [RESULT_CATEGORIES.ATTOM]: AttomBentoCard,
  [RESULT_CATEGORIES.FIRE]: FireBentoCard,
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: HazardPropertyBentoCard,
  [RESULT_CATEGORIES.VEXCEL]: VexcelBentoCard,
  [RESULT_CATEGORIES.HAZARD_RISK]: HazardRiskBentoCard,
  [RESULT_CATEGORIES.CORELOGIC_PROPERTY]: CoreLogicPropertyBentoCard,
  [RESULT_CATEGORIES.CORELOGIC_RISK]: CoreLogicRiskBentoCard,
  [RESULT_CATEGORIES.IMAGE]: ImageBentoCard,
  [RESULT_CATEGORIES.PUBLICATION]: NewsBentoCard,
  [RESULT_CATEGORIES.REGRID]: RegridBentoCard,
  [RESULT_CATEGORIES.SHIP]: ShipBentoCard,
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: SocialBentoCard,
} as { [key: string]: React.FC<BentoCard> }
