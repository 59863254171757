import { Group, Stack, Text } from '@mantine/core'
import { getShipDataFields, getShipDetailsDisplayFields } from '@/api/results'
import { ShipReferenceCard } from '@/features/ResultCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { RESULT_CATEGORY_COLORS } from '@/theme/colors'
import { ROUTES } from '@/utils/constants'
import { formatDatetime } from '@/utils/format-datetime'
import type { ShipResult } from '@/utils/types/result-types'
import { useResultNavigation } from '../hooks'

import styles from './single-object-view.module.css'

export function ShipObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as ShipResult
  const details = getShipDetailsDisplayFields(item)
  const { selectItem, items } = useResultNavigation<ShipResult>(item)

  return (
    <SingleObjectViewTemplate<ShipResult, ShipResult>
      {...{
        item,
        hero: {
          geometry: item.geometry,
          color: RESULT_CATEGORY_COLORS.ship.toLowerCase().slice(1),
          imageAlt: details.name,
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: {
          title: `${details.unicodeFlagIcon} ${details.name}`,
          detailsElement: (
            <Group className={styles['sov-meta']}>
              <Stack gap={0} className={styles['ship-meta-column']}>
                <Text>{details.regionName}</Text>
                <Text>{details.shipType || '--'}</Text>
                <Text>Call Sign: {details.callSign || 'Unknown'}</Text>
              </Stack>
              <Stack gap={0} className={styles['ship-meta-column']}>
                <Group>
                  <Text className={styles['meta-label']}>IMO:</Text>
                  <Text>{details.imo || '--'}</Text>
                </Group>
                <Group>
                  <Text className={styles['meta-label']}>MMSI:</Text>
                  <Text>{details.mmsi || '--'}</Text>
                </Group>
              </Stack>
            </Group>
          ),
          data: getShipDataFields(item),
        },
        carousel: {
          title: 'More Ships',
          items,
          selectItem,
          categoryPageLink: {
            url: `${ROUTES.explore}?tab=ship`,
            label: 'All Ships',
          },
          renderCard: (ship: ShipResult) => <ShipReferenceCard result={ship} />,
        },
      }}
    />
  )
}
