import * as React from 'react'
import { useLocation } from 'react-router-dom'
import {
  ActionIcon,
  Button,
  Center,
  Drawer,
  Group,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { Icon } from '@/components/lib/Icon'
import { SEARCH_STYLE } from '@/features/Search/constants'
import { useSplitSearch } from '@/features/Search/hooks'
import { ROUTES } from '@/utils/constants'
import { classes } from '@/utils/ui-helpers'
import { SearchForm } from './SearchForm'

import styles from '@/features/Search/search-bar.module.css'

interface SplitSearchProps {
  handleInlineHotkeyPress: (event: KeyboardEvent | React.KeyboardEvent) => void
  doSplitSearch: (
    formattedAddress: string,
    query: string,
    subject: string,
    location: {
      formattedAddress: string
      placeId: string
    },
  ) => void
  compact?: boolean
}

const getLabel = (searchStyle: SEARCH_STYLE) => {
  switch (searchStyle) {
    case SEARCH_STYLE.General:
      return 'General'
    case SEARCH_STYLE.Location:
      return 'By Location'
  }
}

export const SplitSearch = React.forwardRef(
  (
    { doSplitSearch, handleInlineHotkeyPress, compact }: SplitSearchProps,
    ref,
  ) => {
    const {
      inputs,
      autoComplete,
      clearLocationValue,
      handleSubmit,
      locationProps,
    } = useSplitSearch({ doSplitSearch, ref })
    const [opened, { open, close }] = useDisclosure()
    const [searchStyle, setSearchStyle] = React.useState(SEARCH_STYLE.Location)
    const { pathname } = useLocation()
    const isExplore = pathname.includes(ROUTES.explore)

    const autoCompleteFormArgs = {
      inputs,
      autoComplete,
      clearLocationValue,
      handleInlineHotkeyPress,
      handleSubmit,
      locationProps,
      searchStyle,
    }

    return (
      <div style={{ width: '100%' }}>
        <Group
          visibleFrom="sm"
          gap={0}
          w="100%"
          className={styles.splitSearchGroup}
        >
          <SearchForm {...autoCompleteFormArgs} compact={compact} />
        </Group>

        <Group hiddenFrom="sm" w="100%" className={styles.splitSearchGroup}>
          <ActionIcon
            w={50}
            h={50}
            className={classes(
              opened
                ? styles.mobileSplitSearchCloseIcon
                : styles.mobileSplitSearchIcon,
              opened && searchStyle == SEARCH_STYLE.General
                ? isExplore
                  ? styles.mobileSplitSearchCloseIconGeneralOverview
                  : styles.mobileSplitSearchCloseIconGeneral
                : undefined,
              isExplore ? styles.mobileSplitSearchCloseIconOverview : undefined,
            )}
            component={Icon}
            name={opened ? 'close' : 'search'}
            onClick={opened ? close : open}
          />
          <Drawer
            opened={opened}
            onClose={close}
            position="bottom"
            withCloseButton={false}
            radius="md"
            overlayProps={{ backgroundOpacity: 0 }}
            classNames={{
              content: classes(
                styles.splitSearchDrawer,
                searchStyle == SEARCH_STYLE.General
                  ? styles.splitSearchDrawerGeneral
                  : styles.splitSearchDrawerLocation,
              ),
              body: styles.splitSearchDrawerBody,
            }}
          >
            <Stack px="xs" gap="xs" mt={-4}>
              <SearchForm {...autoCompleteFormArgs} />
              <Center>
                <Group gap="md" ml={-10}>
                  <Text c="gray.7" size="sm">
                    Search style
                  </Text>
                  {Object.values(SEARCH_STYLE).map((x) => (
                    <Button
                      classNames={{
                        label:
                          searchStyle === x
                            ? styles.searchStyleButtonSelected
                            : styles.searchStyleButton,
                      }}
                      key={x}
                      onClick={() => setSearchStyle(x as SEARCH_STYLE)}
                      variant="transparent"
                      p={0}
                    >
                      {getLabel(x as SEARCH_STYLE)}
                    </Button>
                  ))}
                </Group>
              </Center>
            </Stack>
          </Drawer>
        </Group>
      </div>
    )
  },
)

SplitSearch.displayName = 'SplitSearch'

export default SplitSearch
