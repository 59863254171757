import { isGov } from '@/utils/constants.ts'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isTodayPlugin from 'dayjs/plugin/isToday'
import isYesterdayPlugin from 'dayjs/plugin/isYesterday'
import minMax from 'dayjs/plugin/minMax'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(advancedFormat)
dayjs.extend(isTodayPlugin)
dayjs.extend(isYesterdayPlugin)
dayjs.extend(minMax)
dayjs.extend(relativeTime)
dayjs.extend(timezone)
dayjs.extend(utc)

type DateOrString = string | Date

export function utcOrLocal(datetime: DateOrString) {
  return isGov ? dayjs(datetime).utc() : dayjs(datetime)
}

export const zoneString = isGov ? '[UTC]' : 'z'

export const timeFormatString = isGov ? 'HH:mm' : 'hh:mm A'

export const formatDatetime = (datetime: DateOrString): string => {
  const formatString = `MM/DD/YYYY [at] ${timeFormatString} ${zoneString}`
  return utcOrLocal(datetime).format(formatString)
}

export const formatDate = (datetime: DateOrString): string =>
  utcOrLocal(datetime).format('YYYY-MM-DD')

export const formatTime = (datetime: DateOrString): string =>
  utcOrLocal(datetime).format(timeFormatString)

export const formatDateWithWordDay = (datetime: DateOrString): string =>
  utcOrLocal(datetime).format('dddd, MMMM D, YYYY')

export const formatDatetimeWithWordMonth = (datetime: DateOrString): string =>
  utcOrLocal(datetime).format(`MMM D, ${timeFormatString} ${zoneString}`)

export const formatDateWithWordMonth = (datetime: DateOrString): string =>
  utcOrLocal(datetime).format('MMM D, YYYY')

export const datetime = (entry: DateOrString) => utcOrLocal(entry)

export const isToday = (datetime: DateOrString): boolean =>
  utcOrLocal(datetime).isToday()

export const isYesterday = (datetime: DateOrString): boolean =>
  utcOrLocal(datetime).isYesterday()

// The following should not have `.utc()` applied to them:
// Comparators. No need to format first.
export const findMinDate = (dates: DateOrString[]) =>
  dayjs.min(dates.map((date) => dayjs(date)))

export const findMaxDate = (dates: DateOrString[]) =>
  dayjs.max(dates.map((date) => dayjs(date)))

// Validity checker. UTC and local time are both valid.
export const isValidDatetime = (datetime: DateOrString): boolean =>
  dayjs(datetime).isValid()

// Frequency ignores zone
export const hourlyFrequency = (hour: number) =>
  `DAILY(${dayjs().hour(hour).minute(0).format('HH:mm')})`

// Relative time returns a string and is the same with or without `.utc`
export const fromNow = (datetime: DateOrString): string =>
  dayjs(datetime).fromNow()
