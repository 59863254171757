import { deleteSearchRequest, getSearchRequest } from '@/api/danti-requests'
import { PATHS } from '@/api/history/constants'
import type {
  DeleteHistoryParams,
  GetHistoryParams,
  HistoryResponse,
} from '@/api/history/types'

export async function getRecentHistory(): Promise<HistoryResponse> {
  const response = await getSearchRequest<HistoryResponse>(PATHS.recent)
  return response.data
}

export async function getHistory(
  params: GetHistoryParams,
): Promise<HistoryResponse> {
  if (!params.term) {
    delete params.term
  }

  const response = await getSearchRequest<HistoryResponse>(PATHS.history, {
    params,
  })
  return response.data
}

export async function deleteHistoryItems(data: DeleteHistoryParams) {
  return deleteSearchRequest(PATHS.history, { data })
}
