import { ActionIcon, Group, Title, Tooltip } from '@mantine/core'
import type { Collection } from '@/api/collections/types'
import { openUpdateCollectionModal } from '@/features/AppLayout/Modals'
import { BackButton } from '@/features/Collections/components'
import { COLLECTION_REF_TYPES } from '@/features/Collections/constants'
import { useCollectionDetailHeader } from '@/features/Collections/hooks'
import pluralize from 'pluralize'
import type * as React from 'react'
import { MdOutlinePeopleAlt, MdOutlineSettings } from 'react-icons/md'

function IconButton({
  icon,
  label,
  onClick,
}: {
  icon: React.ReactNode
  label: string
  onClick: () => void
}) {
  return (
    <Tooltip
      label={label}
      withArrow
      position="bottom"
      color="rgba(97, 97, 97, 0.90)"
    >
      <ActionIcon color="#666" onClick={onClick} variant="subtle">
        {icon}
      </ActionIcon>
    </Tooltip>
  )
}

export function CollectionDetailHeader({
  collection,
  totalCount = 0,
  isGatheringResults,
}: {
  collection: Collection
  totalCount?: number
  isGatheringResults: (createdOn: Date) => boolean
}) {
  const { handleShareClick, openSettings, shareLabel } =
    useCollectionDetailHeader({ collection })

  const icons = {
    people: <MdOutlinePeopleAlt />,
    settings: <MdOutlineSettings />,
  }

  const handleSettingsClick = () => {
    if (collection.refId == '') {
      openUpdateCollectionModal(collection)
    } else {
      openSettings()
    }
  }

  return (
    <Group justify="space-between">
      <Group>
        {/* Toggle between two BackButtons depending on viewport size */}
        <BackButton pos="absolute" left="1rem" visibleFrom="md" />
        <BackButton hiddenFrom="md" />
        <Title c="gray.7" size="lg">
          Saved Searches
        </Title>
      </Group>

      <Group gap="xs">
        {totalCount === 0 || isGatheringResults(collection.createdOn) ? null : (
          <Title c="gray.7" size="lg">
            {`${totalCount} ${pluralize('item', totalCount)}`}
          </Title>
        )}
        <IconButton
          icon={icons.people}
          label={shareLabel}
          onClick={handleShareClick}
        />
        {collection.refType !== COLLECTION_REF_TYPES.bulkSearch && (
          <IconButton
            icon={icons.settings}
            label="Settings"
            onClick={handleSettingsClick}
          />
        )}
      </Group>
    </Group>
  )
}
