import { toastError } from '@/components/toasts.ts'
import { processDroppedFiles } from '@/features/DantiMap/utils'
import { useSetUploadedFeature } from '@/stores/map-draw-store'
import * as turf from '@turf/turf'
import type { MutableRefObject } from 'react'
import { useDropzone } from 'react-dropzone'

const jumpToCentroid = (
  uploadedFeature: turf.AllGeoJSON,
  mapRef: MutableRefObject<mapboxgl.Map | null>,
) => {
  try {
    const center = turf.centroid(uploadedFeature).geometry.coordinates
    if (Array.isArray(center) && center.length >= 2) {
      mapRef.current?.jumpTo({
        center: center as [number, number],
        zoom: 10,
      })
    }
  } catch (error) {
    console.error('Error calculating centroid:', error)
  }
}

export const useFileDragging = (
  mapRef: MutableRefObject<mapboxgl.Map | null>,
) => {
  const setUploadedFeature = useSetUploadedFeature()
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    onDrop: async (files: File[]) => {
      const droppedFeature = await processDroppedFiles(files)
      if (droppedFeature) {
        setUploadedFeature(droppedFeature)
        jumpToCentroid(droppedFeature, mapRef)
      } else {
        toastError({
          message:
            'There was an error processing the file. Please make sure it is valid GeoJSON.',
        })
      }
    },
    noClick: true,
    multiple: false,
    accept: { 'application/geo+json': ['.geojson'] },
  })

  return {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
  }
}
