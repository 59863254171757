import { useResults } from '@/api/results'
import {
  useNavigateToResult,
  useNavigateToResultCallback,
} from '@/features/AppRouting/hooks'
import type { ResultWithId } from '@/utils/types/result-types'

type ResultNavigation<T> = {
  toPrevious?: () => void
  toNext?: () => void
  selectItem: (item: T) => void
  items: T[]
}

export const useResultNavigation = <T>(item: T) => {
  const allResults = useResults()
  const itemId = (item as ResultWithId).id

  const navigateToResultCallback = useNavigateToResultCallback()
  const navigateToResult = useNavigateToResult()

  const category = (item as ResultWithId).category
  const results = allResults[category as keyof typeof allResults]
  const resultIndex = results.findIndex(
    (result: ResultWithId) => result.id === itemId,
  )

  const hasPrevious = resultIndex > 0
  const hasNext = resultIndex < results.length - 1

  const out = {
    selectItem: navigateToResult,
    items: results as T[],
  } as ResultNavigation<T>
  if (hasPrevious) {
    out.toPrevious = navigateToResultCallback(results[resultIndex - 1])
  }
  if (hasNext) {
    out.toNext = navigateToResultCallback(results[resultIndex + 1])
  }
  return out
}
