import React from 'react'
import type { CollectionItem } from '@/api/collections/types'
import { getRequest } from '@/api/danti-requests'
import { SEARCH_CATEGORIES } from '@/api/results'
import { getDocumentById } from '@/api/results/api.ts'
import {
  ingestTransforms,
  loadGenericProps,
} from '@/api/results/data-transforms'
import { RESULT_CATEGORIES } from '@/utils/constants'
import { getResultCategory } from '@/utils/types/result-type-checkers'
import type {
  RawSearchResult,
  ResultCategories,
  ResultWithId,
  VexcelImage,
} from '@/utils/types/result-types'

export interface UseSOVItemProps {
  itemId?: string
  collectionId?: string
  terrapinItemId?: string
}

export interface UseSOVItemReturn {
  item?: ResultWithId
  resultCategory?: ResultCategories
  isLoading: boolean
}

export const useSingleObjectViewItem = ({
  itemId,
  collectionId,
  terrapinItemId,
}: UseSOVItemProps): UseSOVItemReturn => {
  const [_item, setItem] = React.useState<ResultWithId>()
  const [isLoading, setIsLoading] = React.useState(true)
  const resultCategory = React.useMemo(() => {
    if (!_item) {
      return
    }
    return getResultCategory(_item)
  }, [_item])

  const item = React.useMemo<ResultWithId | undefined>(() => {
    if (!_item || !resultCategory) {
      return
    }

    const propertyResultCategories: ResultCategories[] = [
      SEARCH_CATEGORIES.VEXCEL,
      SEARCH_CATEGORIES.REGRID,
      RESULT_CATEGORIES.VEXCEL,
      SEARCH_CATEGORIES.ATTOM,
      SEARCH_CATEGORIES.HAZARD_PROPERTY,
      SEARCH_CATEGORIES.HAZARD_RISK,
      SEARCH_CATEGORIES.CORELOGIC_PROPERTY,
      SEARCH_CATEGORIES.CORELOGIC_RISK,
    ]

    if (!propertyResultCategories.includes(resultCategory)) {
      return _item
    }

    if (resultCategory == RESULT_CATEGORIES.VEXCEL) {
      const vexcelImages: VexcelImage[] =
        _item.properties.vexcel_images?.vexcel_images ?? []

      const [vexcelImage] = vexcelImages
      return {
        ..._item,
        // suffixing id with vexcel because otherwise result id will be identical to REGRID result
        id: `${_item.id}-vexcel`,
        properties: {
          ...loadGenericProps(_item),
          _dProvider: 'VEXCEL',
          resultCategory: RESULT_CATEGORIES.VEXCEL,
          collection: vexcelImage.collection,
          'estimate-date': vexcelImage['estimate-date'],
          url: vexcelImage.url,
          // eslint-disable-next-line camelcase
          vexcel_images: { vexcel_images: vexcelImages },
        },
      }
    }
    const transformProperty = ingestTransforms[RESULT_CATEGORIES.PROPERTY]
    return transformProperty(_item) as ResultWithId
  }, [_item, resultCategory])

  async function fetchItem(id: string) {
    setIsLoading(true)
    try {
      const response = await getRequest<RawSearchResult>(`/results/${id}`)
      setItem({ ...response.data, id: response.data.properties._dId })
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchCollectionItem(collectionId: string, itemId: string) {
    setIsLoading(true)
    try {
      const response = await getRequest<CollectionItem>(
        `/collection/${collectionId}/items/${itemId}`,
      )
      setItem(JSON.parse(response.data.doc) as ResultWithId)
    } finally {
      setIsLoading(false)
    }
  }

  async function fetchResultItem(itemId: string): Promise<void> {
    setIsLoading(true)
    try {
      const data = await getDocumentById(itemId)
      setItem({ ...data, id: data.properties._dId })
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (terrapinItemId) {
      void fetchItem(terrapinItemId)
      return
    }

    if (collectionId && itemId) {
      void fetchCollectionItem(collectionId, itemId)
      return
    }

    if (itemId) {
      void fetchResultItem(itemId)
      return
    }
  }, [itemId, collectionId, terrapinItemId])

  return { item, resultCategory, isLoading }
}
