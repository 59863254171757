import * as React from 'react'
import { Container, Stack, Title } from '@mantine/core'
import ErrorIcon from '@/assets/error-circles.svg?react'
import FolderIcon from '@/assets/folder-circles.svg?react'
import { StatusBlock } from '@/components/lib/StatusBlock'
import { VIEW_TYPES } from '@/features/Collections//constants'
import { ListDisplay } from '@/features/Collections/components'
import { useCollectionDetails } from '@/features/Collections/hooks'
import { useResetCollectionItemsState } from '@/stores/collection-items'
import { useCollectionItemsState } from '@/stores/collection-items/hooks'
import dayjs from 'dayjs'
import { CollectionDetailActions } from './CollectionDetailActions'
import { CollectionDetailGrid } from './CollectionDetailGrid'
import { CollectionDetailHeader } from './CollectionDetailHeader'
import { CollectionDetailListItem } from './CollectionDetailListItem'
import { CollectionDetailTable } from './CollectionDetailTable'

import styles from '../../collections.module.css'

const isGatheringResults = (createdOn: Date) => {
  const currentTime = dayjs()
  const newCollectionThresholdTime = dayjs(createdOn).add(5, 'minute')
  return currentTime.isBefore(newCollectionThresholdTime)
}

export function CollectionDetail() {
  const initialize = useResetCollectionItemsState()
  const { selectedCategories, setSort } = useCollectionItemsState()
  const {
    collection,
    columnFilters,
    handleToggleSelectedCategory,
    hasCollection,
    items,
    setViewType,
    totalCount,
    viewType,
    isLoading,
  } = useCollectionDetails()

  const listedItems = selectedCategories.length === 0 ? [] : items

  React.useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!hasCollection || !collection) {
    return <></>
  }

  const NoItems = () =>
    isGatheringResults(collection.createdOn) || isLoading ? (
      <StatusBlock
        title="Please wait"
        icon={<FolderIcon />}
        description={
          <>
            Danti is gathering the results of your Saved Search. <br />
            Results will appear here in a moment.
          </>
        }
      />
    ) : (
      <StatusBlock
        title="No results found"
        icon={<ErrorIcon />}
        description="Danti was unable to find any results for your Saved Search."
      />
    )

  const ItemsDisplay = () =>
    viewType === VIEW_TYPES.grid ? (
      <CollectionDetailGrid items={listedItems} />
    ) : (
      <>
        <ListDisplay
          items={listedItems}
          itemComponent={CollectionDetailListItem}
        />
        <CollectionDetailTable
          items={listedItems}
          columnFilters={columnFilters}
          setSort={setSort}
          onCategoryToggle={handleToggleSelectedCategory}
        />
      </>
    )

  return (
    <Container size={900} p={0} className={styles.contentContainer}>
      <Stack w="100%" mb="lg">
        <CollectionDetailHeader
          {...{ collection, totalCount, isGatheringResults }}
        />
        <Title c="gray.8" size="3rem">
          {collection.name}
        </Title>
        <CollectionDetailActions {...{ collection, viewType, setViewType }} />
      </Stack>
      {items.length === 0 ? <NoItems /> : <ItemsDisplay />}
    </Container>
  )
}
