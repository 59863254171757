import { useState } from 'react'
import { Button, Checkbox, Group, Stack, TextInput } from '@mantine/core'
import type { ContextModalProps } from '@mantine/modals'
import { CONFIGURATION_TABLE_PROPS as tableProps } from '@/features/AppLayout/Modals/SaveSearchModal'
import type { ProviderSource } from '@/features/Filters'
import type { CreateTerrapinParams } from '@/hooks/use-terrapin'
import { useTimePresetFilter } from '@/stores/filters-store'
import { ConfigureTimings } from './ConfigureTimings'
import { useConfigureSavedSearch } from './hooks'

export interface SaveSearchSettingsModalParams {
  params?: CreateTerrapinParams
  refId?: string
  isNew?: boolean
  providers?: ProviderSource[]
}

export function SaveSearchModal({
  context,
  id,
  innerProps: { refId, params: savedConfig },
}: ContextModalProps<SaveSearchSettingsModalParams>) {
  const {
    name,
    setName,
    isSaving,
    handleSave,
    expirationDate,
    setExpirationDate,
    emailNotification,
    setEmailNotification,
    backfill,
    setBackfill,
  } = useConfigureSavedSearch({ savedConfig, refId })
  const [isRecurring, setIsRecurring] = useState<boolean>(
    !!(savedConfig?.backfill || savedConfig?.emailNotification),
  )

  const timePreset = useTimePresetFilter()
  const noBackfillTimeSelected = backfill && timePreset === 'None'
  const isSaveDisabled = !name || isSaving || noBackfillTimeSelected

  return (
    <>
      <Stack gap="md">
        <Checkbox
          checked={isRecurring}
          label="Recurring (check this box if you want this search to run automatically)"
          onChange={(event) => setIsRecurring(event.currentTarget.checked)}
        />
        <TextInput
          value={name}
          label="Name this Saved Search"
          onChange={(event) => setName(event.target.value)}
          data-cy="save-search-name"
          required
        />
        {isRecurring && (
          <ConfigureTimings
            savedConfig={savedConfig}
            tableProps={tableProps}
            expirationDate={expirationDate}
            setExpirationDate={setExpirationDate}
            emailNotification={emailNotification}
            setEmailNotification={setEmailNotification}
            backfill={backfill}
            setBackfill={setBackfill}
          />
        )}
      </Stack>
      <Group justify="space-between" mt="md">
        <Button
          variant="subtle"
          size="xs"
          color="gray.9"
          onClick={() => context.closeModal(id)}
        >
          CANCEL
        </Button>
        <Button
          size="xs"
          loading={isSaving}
          onClick={() => void handleSave(() => context.closeModal(id))}
          disabled={isSaveDisabled}
        >
          SAVE
        </Button>
      </Group>
    </>
  )
}
