import { Center } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import type { SymbolName } from '@/utils/types/material-icons'
import { SocialIcon } from 'react-social-icons'

import styles from './hero.module.css'

export interface SOVHeroIconProps {
  icon?: string
  svgIcon?: React.ReactNode
  socialIconName?: string
  color?: string
}
export const SOVHeroIcon = ({
  icon,
  color,
  svgIcon,
  socialIconName,
}: SOVHeroIconProps) => {
  if (!(icon || svgIcon || socialIconName)) {
    return null
  }

  const iconElement = icon && (
    <Icon name={icon as SymbolName} className={styles.heroIcon} />
  )

  return (
    <Center w="100%" p="lg" h={480} style={{ backgroundColor: color }}>
      {icon && iconElement}
      {svgIcon}
      {socialIconName && (
        <SocialIcon
          network={socialIconName}
          style={{ width: '200px', height: '200px' }}
        />
      )}
    </Center>
  )
}

export default SOVHeroIcon
