import { Box } from '@mantine/core'
import type { CollectionItem } from '@/api/collections'
import {
  getNewsDetailCardProps,
  getNewsImages,
  SEARCH_CATEGORIES,
} from '@/api/results'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view'
import { ICONS } from '@/utils/constants'
import type { PublicationResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const NewsReferenceCard = ({
  result,
  withActions,
  item,
  isActive = false,
}: {
  result: PublicationResult
  withActions?: boolean
  item?: CollectionItem
  isActive?: boolean
}) => {
  const images = getNewsImages(result)
  const image = images.length > 0 ? images[0].url : null
  const { datetime, title, source } = getNewsDetailCardProps(result)
  const { targetRef } = useSubscribeScrollIntoView(isActive)

  return (
    <Box ref={targetRef}>
      <ReferenceCard
        item={item}
        heroIconName={ICONS.newspaper}
        authoredOn={datetime}
        title={source}
        heroImageUrl={image}
        description={title}
        result={result}
        category={SEARCH_CATEGORIES.PUBLICATION}
        withActions={withActions}
        isActive={isActive}
      />
    </Box>
  )
}
