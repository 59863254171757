import { List } from '@mantine/core'
import type { BaseSection } from '../../types/insight-types'
import { BaseSection as BaseSectionComponent } from './BaseSection'

interface ListSectionProps extends Omit<BaseSection, 'content'> {
  listDepth?: number
  content: string[]
}

export function ListSection({
  title,
  content,
  listDepth = 0,
}: ListSectionProps) {
  return (
    <BaseSectionComponent title={title}>
      <List
        spacing="xs"
        type={listDepth === 0 ? 'unordered' : 'ordered'}
        listStyleType={listDepth === 0 ? 'disc' : 'circle'}
      >
        {content.map((item, index) => {
          const itemString =
            typeof item === 'string' ? item : JSON.stringify(item)
          const stableKey = `${title}-${itemString}-${index}`

          return <List.Item key={stableKey}>{itemString}</List.Item>
        })}
      </List>
    </BaseSectionComponent>
  )
}
