import type { HistoryItem } from '@/api/history'
import { useSearch } from '@/features/Search/hooks'
import { Icon } from '../lib/Icon'

const ICON_SIZE = 16

const AOI_ICONS = {
  circle: 'circle',
  latLong: 'location_on',
  polygon: 'polyline',
} as const

export function AoiIcon({ item }: { item: HistoryItem }) {
  const { getAOIFilter } = useSearch()
  const aoiFilter = getAOIFilter(item)
  return aoiFilter ? (
    <Icon name={AOI_ICONS[aoiFilter.type]} size={ICON_SIZE} />
  ) : null
}
