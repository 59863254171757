import type { SavedSearchLocationsDownloadProps } from '@/api/collections/types'
import { getRequest } from '@/api/danti-requests'
import { downloadFromURL } from '@/api/helper.ts'
import * as Sentry from '@sentry/react'
import { generateBlob } from './download-utils'

export const queryUrl = ({
  collectionId,
  filter,
  totalCount,
  searchTerm,
}: SavedSearchLocationsDownloadProps) => {
  const query = new URLSearchParams({
    type: 'extent',
    ...(filter && { filter }),
    ...(totalCount && { limit: `${totalCount}` }),
    ...(searchTerm && { term: searchTerm }),
  }).toString()
  return `collection/${collectionId}/items/download?${query}`
}

export const handleCollectionLocationDownload = ({
  collectionId,
  collectionName,
  totalCount,
  filter,
  searchTerm,
}: SavedSearchLocationsDownloadProps) => {
  return getRequest(
    queryUrl({ collectionId, filter, searchTerm }),
    { responseType: 'blob' }, // Important to specify the response type as blob
  )
    .then((response) => {
      let downloadName = `${collectionName}.json`
      if (response.headers['content-disposition']) {
        const contentDisposition = response.headers[
          'content-disposition'
        ] as string
        const filenameMatch = contentDisposition.match(/filename="(.+)"/)
        if (filenameMatch && filenameMatch.length > 1) {
          downloadName = filenameMatch[1]
        }
      }
      const dataBlob = generateBlob(response.data)
      const url = window.URL.createObjectURL(dataBlob)
      downloadFromURL(url, downloadName)
    })
    .catch(() => {
      Sentry.captureMessage('Failed to download extents', {
        extra: {
          collectionId,
          filter,
          totalCount,
        },
      })
    })
}
