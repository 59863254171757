import { Route } from 'react-router-dom'
import { AppContainer } from '@/features/AppLayout/MainLayout/AppContainer.tsx'
import Account from '@/pages/Account/Account'
import { Password } from '@/pages/Account/Password'
import { Profile } from '@/pages/Account/Profile'
import { UserAdmin } from '@/pages/Account/UserAdmin/UserAdmin'
import { ROUTES } from '@/utils/constants'

export const AccountRoutes = (
  <>
    <Route
      path={ROUTES.account}
      element={
        <AppContainer>
          <Account />
        </AppContainer>
      }
    >
      <Route path={ROUTES.password} element={<Password />} />
      <Route path={ROUTES.users} element={<UserAdmin />} />
      <Route path={ROUTES.account} element={<Profile />} />
      <Route path={ROUTES.subscribed} element={<Profile />} />
    </Route>
  </>
)
