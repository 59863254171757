import React from 'react'
import { Container } from '@mantine/core'
import { useCollections } from '@/api/collections'
import type { Collection } from '@/api/collections/types'
import { ListDisplay } from '@/features/Collections/components'
import { VIEW_TYPES } from '@/features/Collections/constants'
import type { ViewType } from '@/features/Collections/types'
import { useResetCollectionsState } from '@/stores/collections'
import { CollectionGrid } from './CollectionGrid'
import { CollectionListItem } from './CollectionListItem'
import { CollectionsHeader } from './CollectionsHeader'
import { CollectionTable } from './CollectionTable'

import styles from '../../collections.module.css'

export function Collections() {
  const initialize = useResetCollectionsState()
  const [viewType, setViewType] = React.useState<ViewType>(VIEW_TYPES.grid)
  const { collections } = useCollections()

  React.useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Container size={900} p={0} className={styles.contentContainer}>
      <CollectionsHeader viewType={viewType} setViewType={setViewType} />
      {viewType === VIEW_TYPES.grid ? (
        <CollectionGrid />
      ) : (
        <>
          <ListDisplay<Collection>
            items={collections}
            itemComponent={CollectionListItem}
          />
          <CollectionTable />
        </>
      )}
    </Container>
  )
}
