import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { AppShell } from '@mantine/core'
import { useDisclosure, useLocalStorage } from '@mantine/hooks'
import { Notifications } from '@mantine/notifications'
import { useHistory } from '@/api/history'
import { DantiModalsProvider } from '@/features/AppLayout'
import { openAnnouncementModal } from '@/features/AppLayout/Modals'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag.ts'
import { useSentryMetadataSyncing } from '@/hooks/use-sentry-metadata-syncing'
import { FEATURE_FLAG_VALUES } from '@/utils/constants.ts'
import { init as initApm } from '@elastic/apm-rum'
import { posthog } from 'posthog-js'
import { Header, MobileNavMenu } from '../Header'

import './main-layout.css'

export function MainLayout() {
  useHistory()
  useSentryMetadataSyncing()
  const location = useLocation()
  const [opened, { toggle }] = useDisclosure()
  const { isAuthenticated } = useDantiAuth()
  const showWelcomeAnnouncement = useFeatureFlag(
    FEATURE_FLAG_VALUES.welcomeAnnouncement,
  )
  const [acknowledged, setAcknowledged] = useLocalStorage({
    key: 'acknowledged-announcement',
    defaultValue: false,
    getInitialValueInEffect: false,
  })

  React.useEffect(() => {
    posthog.capture('$pageview')
  }, [location])

  React.useEffect(() => {
    if (!showWelcomeAnnouncement || !isAuthenticated || acknowledged) return
    openAnnouncementModal({ setAcknowledged })
  }, [acknowledged, isAuthenticated, setAcknowledged, showWelcomeAnnouncement])

  React.useEffect(() => {
    initApm({
      serviceName: 'bastet',
      serverUrl:
        'https://9adb2815382e406eb8a80c1491167ecb.apm.us-west-2.aws.cloud.es.io:443',
    })
  }, [])

  return (
    <DantiModalsProvider>
      <AppShell
        navbar={{
          width: 300,
          breakpoint: 'sm',
          collapsed: { mobile: !opened, desktop: true },
        }}
      >
        <AppShell.Header withBorder={false}>
          <Header opened={opened} toggle={toggle} />
        </AppShell.Header>
        <AppShell.Navbar>
          <MobileNavMenu toggle={toggle} />
        </AppShell.Navbar>
        <AppShell.Main>
          <Notifications
            position="top-left"
            mt="calc(var(--danti-head-height) - 1rem)"
          />
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </DantiModalsProvider>
  )
}
