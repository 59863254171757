import { ICONS, ROUTES } from '@/utils/constants'
import type { NavItem } from './types'

export const QUERY_HEADER_HEIGHT = 50
export const HEADER_HEIGHT = 60
export const FOOTER_HEIGHT = 80
export const APP_PADDING = 30

export enum TerrapinLocationType {
  latLong = 'latLong',
  polygon = 'polygon',
  mgrs = 'mgrs',
}

export const NAV_ITEMS: NavItem[] = [
  {
    id: 'explore',
    icon: ICONS.search,
    label: 'Explore',
    path: ROUTES.explore,
  },
  {
    id: 'collections',
    icon: ICONS.collectionsBookmark,
    label: 'Saved Searches',
    path: ROUTES.collections,
  },
  {
    id: 'history',
    icon: ICONS.history,
    label: 'History',
    path: ROUTES.history,
  },
]
