import { List, Stack, Text } from '@mantine/core'
import { type BaseSection, isStringRecord } from '../../types/insight-types'
import { BaseSection as BaseSectionComponent } from './BaseSection'
import { KeyValueSection } from './KeyValueSection'
import { LinkSection } from './LinkSection'

interface SectionItemProps {
  title: string
  children: React.ReactNode
}

function SectionItem({ title, children }: SectionItemProps) {
  return (
    <Stack gap="xs">
      <Text fw={500}>{title}</Text>
      {children}
    </Stack>
  )
}

export function NestedSection({
  title,
  content,
  type,
  subsections,
}: BaseSection) {
  const renderSection = (section: BaseSection) => {
    const sectionType = section.type || type

    return (
      <List.Item key={section.title} mb="sm">
        {sectionType === 'list' && Array.isArray(section.content) && (
          <SectionItem title={section.title}>
            <List withPadding listStyleType="disc" spacing="xs">
              {section.content.map((item) => (
                <List.Item key={`${section.title}-${item}`}>{item}</List.Item>
              ))}
            </List>
          </SectionItem>
        )}
        {sectionType === 'keyValue' && (
          <KeyValueSection
            title={section.title}
            type={sectionType}
            content={isStringRecord(section.content) ? section.content : {}}
            subsections={section.subsections}
          />
        )}
        {sectionType === 'links' && (
          <SectionItem title={section.title}>
            <List withPadding listStyleType="disc" spacing="xs">
              <LinkSection
                title={section.title}
                type={sectionType}
                content={
                  Array.isArray(section.content) ||
                  isStringRecord(section.content)
                    ? section.content
                    : []
                }
                subsections={section.subsections}
                isNested
              />
            </List>
          </SectionItem>
        )}
        {sectionType === 'nested' && (
          <SectionItem title={section.title}>
            {typeof section.content === 'string' && (
              <Text>{section.content}</Text>
            )}
            {section.subsections && (
              <List withPadding listStyleType="disc" spacing="xs">
                {section.subsections.map((subsection) =>
                  renderSection(subsection),
                )}
              </List>
            )}
          </SectionItem>
        )}
        {sectionType === 'text' && typeof section.content === 'string' && (
          <SectionItem title={section.title}>
            <List withPadding listStyleType="disc" spacing="xs">
              <List.Item>
                <Text>{section.content}</Text>
              </List.Item>
            </List>
          </SectionItem>
        )}
      </List.Item>
    )
  }

  return (
    <BaseSectionComponent title={title}>
      <List withPadding listStyleType="disc">
        {content && typeof content === 'string' && !subsections && (
          <List.Item>
            <Text>{content}</Text>
          </List.Item>
        )}
        {subsections?.map((section) => renderSection(section))}
      </List>
    </BaseSectionComponent>
  )
}
