import { ICONS, RESULT_CATEGORIES } from '@/utils/constants'

// Result menu options
export const MENU_OPTION_KEYS = {
  addToCollection: 'addToCollection',
  visitArticle: 'visitArticle',
  viewDetails: 'viewDetails',
  downloadImage: 'downloadImage',
  share: 'share',
  downloadFile: 'downloadFile',
  removeFromCollection: 'removeFromCollection',
} as const

export const MENU_OPTION_LABELS = {
  [MENU_OPTION_KEYS.addToCollection]: 'Add to Saved Search',
  [MENU_OPTION_KEYS.visitArticle]: 'Visit Article',
  [MENU_OPTION_KEYS.downloadFile]: 'Download File',
  [MENU_OPTION_KEYS.downloadImage]: 'Download',
  [MENU_OPTION_KEYS.viewDetails]: 'View Details',
  [MENU_OPTION_KEYS.share]: 'Share',
  [MENU_OPTION_KEYS.removeFromCollection]: 'Remove',
} as const

export const MENU_OPTION_ICONS = {
  [MENU_OPTION_KEYS.addToCollection]: ICONS.add,
  [MENU_OPTION_KEYS.visitArticle]: ICONS.openInNew,
  [MENU_OPTION_KEYS.downloadFile]: ICONS.download,
  [MENU_OPTION_KEYS.downloadImage]: ICONS.download,
  [MENU_OPTION_KEYS.share]: ICONS.iosShare,
  [MENU_OPTION_KEYS.viewDetails]: ICONS.info,
  [MENU_OPTION_KEYS.removeFromCollection]: ICONS.delete,
} as const

export const FUTURE_MENU_OPTIONS = [MENU_OPTION_KEYS.share] as const

export const FUTURE_OPTION_TITLE = 'Coming Soon'

export const DEFAULT_ACTIONS_TO_HIDE = [
  MENU_OPTION_KEYS.downloadImage,
  MENU_OPTION_KEYS.share,
  MENU_OPTION_KEYS.visitArticle,
]

export const COLLECTIBLE_ACTIONS_TO_HIDE = [
  MENU_OPTION_KEYS.downloadImage,
  MENU_OPTION_KEYS.share,
  MENU_OPTION_KEYS.visitArticle,
]

export const HIDE_ACTIONS_BY_CATEGORY = {
  [RESULT_CATEGORIES.ATTOM]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.HAZARD_RISK]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.CORELOGIC_PROPERTY]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.CORELOGIC_RISK]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.REGRID]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.VEXCEL]: DEFAULT_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.FIRE]: COLLECTIBLE_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.SHIP]: COLLECTIBLE_ACTIONS_TO_HIDE,
  [RESULT_CATEGORIES.IMAGE]: [MENU_OPTION_KEYS.visitArticle],
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: [
    MENU_OPTION_KEYS.downloadImage,
    MENU_OPTION_KEYS.share,
  ],
  [RESULT_CATEGORIES.PUBLICATION]: [MENU_OPTION_KEYS.downloadImage],
}
