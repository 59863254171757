import { Box, Image, Stack, Text } from '@mantine/core'
import { useIntersection } from '@mantine/hooks'
import { useThumbnailProxy } from '@/api/thumbnail'
import { DantiLogoMark } from '@/components/lib/DantiLogoMark'
import type { ResultImageObject } from '@/features/ResultImage/types.ts'
import type React from 'react'

import styles from './result-image.module.css'

interface ResultImageProps {
  item: ResultImageObject
  style?: React.CSSProperties
  onClick?: () => void
  className?: string
}

export function ResultImage({ item, style, ...props }: ResultImageProps) {
  const { ref, entry } = useIntersection({
    rootMargin: '0px',
    threshold: 0,
  })
  const { title, thumbnail, source } = item

  const { data } = useThumbnailProxy(
    { thumbnail: thumbnail ?? '', source },
    Boolean(thumbnail) && entry?.isIntersecting,
  )

  return (
    <Box ref={ref} className={styles.resultImageContainer} style={style}>
      {data ? (
        <Image alt={title} src={data} {...props} />
      ) : (
        <Stack className={styles.logoContainer} title="Preview Unavailable">
          <DantiLogoMark
            color="var(--mantine-color-gray-3)"
            className={styles.logo}
          />
          <Text className={styles.text}>Preview Unavailable</Text>
        </Stack>
      )}
    </Box>
  )
}
