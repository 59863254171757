import { RESULT_CATEGORIES, ROUTES } from '@/utils/constants'

export const CATEGORY_ROUTES = {
  [RESULT_CATEGORIES.PUBLICATION]: ROUTES.news,
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: ROUTES.social,
  [RESULT_CATEGORIES.IMAGE]: ROUTES.images,
  [RESULT_CATEGORIES.SHIP]: ROUTES.ships,
  [RESULT_CATEGORIES.FIRE]: ROUTES.fires,
} as { [key: string]: string }

export const COMMERCIAL_CATEGORY_STRINGS = {
  [RESULT_CATEGORIES.FIRE]: 'All Fire Results',
  [RESULT_CATEGORIES.IMAGE]: 'All Image Results',
  [RESULT_CATEGORIES.PUBLICATION]: 'All News Results',
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: 'All Social Results',
  [RESULT_CATEGORIES.ATTOM]: 'All Permits',
  [RESULT_CATEGORIES.REGRID]: 'Land information',
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: 'Property Information',
  [RESULT_CATEGORIES.HAZARD_RISK]: 'All Hazard Risk Results',
  [RESULT_CATEGORIES.SHIP]: 'All Ship Results',
} as { [key: string]: string }

export const GOV_CATEGORY_STRINGS = {
  [RESULT_CATEGORIES.FIRE]: 'All Fire Results',
  [RESULT_CATEGORIES.IMAGE]: 'All Image Results',
  [RESULT_CATEGORIES.PUBLICATION]: 'All News Results',
  [RESULT_CATEGORIES.SOCIAL_MEDIA]: 'All Social Results',
  [RESULT_CATEGORIES.ATTOM]: 'All Permits',
  [RESULT_CATEGORIES.REGRID]: 'Land Information',
  [RESULT_CATEGORIES.HAZARD_PROPERTY]: 'Property Information',
  [RESULT_CATEGORIES.HAZARD_RISK]: 'Risk Information',
  [RESULT_CATEGORIES.SHIP]: 'All Ship Results',
} as { [key: string]: string }

export const COMMERCIAL_BENTO_ORDER = [
  [
    RESULT_CATEGORIES.HAZARD_PROPERTY,
    RESULT_CATEGORIES.VEXCEL,
    RESULT_CATEGORIES.REGRID,
  ],
  [
    RESULT_CATEGORIES.VEXCEL,
    RESULT_CATEGORIES.REGRID,
    RESULT_CATEGORIES.HAZARD_PROPERTY,
    RESULT_CATEGORIES.PUBLICATION,
  ],
  [
    RESULT_CATEGORIES.HAZARD_PROPERTY,
    RESULT_CATEGORIES.HAZARD_RISK,
    RESULT_CATEGORIES.ATTOM,
  ],
  [
    RESULT_CATEGORIES.PUBLICATION,
    RESULT_CATEGORIES.HAZARD_RISK,
    RESULT_CATEGORIES.ATTOM,
  ],
  [RESULT_CATEGORIES.HAZARD_RISK, RESULT_CATEGORIES.HAZARD_PROPERTY],
  [RESULT_CATEGORIES.ATTOM, RESULT_CATEGORIES.HAZARD_PROPERTY],
]

export const PROPERTY_BENTO_ORDER = [
  RESULT_CATEGORIES.HAZARD_PROPERTY,
  RESULT_CATEGORIES.IMAGE,
  RESULT_CATEGORIES.REGRID,
  RESULT_CATEGORIES.PUBLICATION,
  RESULT_CATEGORIES.HAZARD_RISK,
  RESULT_CATEGORIES.ATTOM,
  RESULT_CATEGORIES.SOCIAL_MEDIA,
]

export const GOV_BENTO_ORDER = [
  RESULT_CATEGORIES.PUBLICATION,
  RESULT_CATEGORIES.IMAGE,
  RESULT_CATEGORIES.SOCIAL_MEDIA,
  RESULT_CATEGORIES.FIRE,
  RESULT_CATEGORIES.SHIP,
]
