import { Skeleton } from '@mantine/core'

export function InsightSectionLoader() {
  return (
    <>
      <Skeleton height={40} width="20%" radius="md" animate />
      <Skeleton height={10} mt={10} radius="md" animate />
      <Skeleton height={10} mt={10} radius="md" animate />
      <Skeleton height={10} mt={10} mb={20} radius="md" animate />
    </>
  )
}
