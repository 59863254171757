import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import type { Results } from '@/api/results/types'
import { OrthoThumbnail } from '@/components/OrthoThumbnail'
import { emitter, EVENTS } from '@/events'
import { useOrthoImagerySources } from '@/features/DantiMap/hooks'
import {
  FireReferenceCard,
  NewsReferenceCard,
  ShipReferenceCard,
  SocialMediaReferenceCard,
} from '@/features/ResultCards'
import {
  useActiveOrthoLayerGroupIds,
  useSelectedPointId,
  useSetSelectedPointId,
  useToggleOrthoLayerGroupId,
  useZoomToOrthoLayer,
} from '@/stores/map-store'
import { useTimelineFilteredResults } from '@/stores/results-filter-store'
import type { OrthoImageryLayerGroup } from '@/utils/types/ortho-imagery-types'
import type {
  FireResult,
  PublicationResult,
  ResultWithId,
  ShipResult,
  SocialMediaResult,
} from '@/utils/types/result-types'
import * as turf from '@turf/turf'

const TAB_ORDER = [
  SEARCH_CATEGORIES.IMAGE,
  SEARCH_CATEGORIES.PUBLICATION,
  SEARCH_CATEGORIES.SOCIAL_MEDIA,
  SEARCH_CATEGORIES.FIRE,
  SEARCH_CATEGORIES.SHIP,
] as const

export const TAB_VALUES = {
  SUMMARY: 'summary',
  [SEARCH_CATEGORIES.IMAGE]: 'imagery',
  [SEARCH_CATEGORIES.PUBLICATION]: 'news',
  [SEARCH_CATEGORIES.SOCIAL_MEDIA]: 'social',
  [SEARCH_CATEGORIES.FIRE]: 'fire',
  [SEARCH_CATEGORIES.SHIP]: 'ships',
} as const

export type TabValue = ValueOf<typeof TAB_VALUES>

function isTabValue(value?: string | null): value is TabValue {
  return Object.values(TAB_VALUES).includes(value as TabValue)
}

// Use tab value from URL param to set active category
function getCategoryValue(activeTab: TabValue): keyof Partial<Results> {
  if (activeTab === TAB_VALUES.SUMMARY) {
    return SEARCH_CATEGORIES.IMAGE
  }

  const categoryValue = Object.entries(TAB_VALUES).find(
    ([, value]) => value === activeTab,
  )

  return categoryValue?.[0] as keyof Partial<Results>
}

type TabConfig = {
  value: string
  category: keyof Partial<Results>
  content: JSX.Element[]
  results: ResultWithId[] | OrthoImageryLayerGroup[]
}

export function useResultTabs(tab?: TabValue | null) {
  // Ensure tab value is valid since users can manipulate the URL
  const tabValue = isTabValue(tab) ? tab : TAB_VALUES.SUMMARY
  const categoryValue = getCategoryValue(tabValue)
  const [activeTab, setActiveTab] = React.useState(tabValue)
  const [activeCategory, setActiveCategory] =
    React.useState<keyof Partial<Results>>(categoryValue)

  const [_, setSearchParams] = useSearchParams()

  const { categorized: timelineCategorized } = useTimelineFilteredResults()
  const { orthoLayerGroups } = useOrthoImagerySources()
  const imageResults = orthoLayerGroups
  const toggleOrthoLayerId = useToggleOrthoLayerGroupId()
  const zoomToOrthoLayer = useZoomToOrthoLayer()
  const activeOrthoLayerIds = useActiveOrthoLayerGroupIds()
  const selectedPointId = useSelectedPointId()
  const setSelectedPointId = useSetSelectedPointId()

  const renderCard = (result: ResultWithId, contents: React.ReactNode) => {
    const onClick = () => {
      const center = turf.center(result.geometry).geometry.coordinates
      emitter.emit(EVENTS.map.flyTo, {
        center: [center[0], center[1]],
        zoom: 15,
      })
      setSelectedPointId(result.id)
    }
    return (
      <span onClick={onClick} key={result.id}>
        {contents}
      </span>
    )
  }

  const tabs = {
    [SEARCH_CATEGORIES.IMAGE]: {
      category: SEARCH_CATEGORIES.IMAGE,
      value: TAB_VALUES[SEARCH_CATEGORIES.IMAGE],
      content: imageResults.map((result: OrthoImageryLayerGroup) => {
        const { id } = result
        const onClick = () => {
          if (!activeOrthoLayerIds.includes(id)) {
            zoomToOrthoLayer(id)
          }
          toggleOrthoLayerId(id)
        }
        const isActive = activeOrthoLayerIds.includes(id)
        return (
          <span onClick={onClick} key={result.id}>
            <OrthoThumbnail item={result} isActive={isActive} />
          </span>
        )
      }),
      results: imageResults,
    } as TabConfig,
    [SEARCH_CATEGORIES.PUBLICATION]: {
      category: SEARCH_CATEGORIES.PUBLICATION,
      value: TAB_VALUES[SEARCH_CATEGORIES.PUBLICATION],
      content: timelineCategorized[SEARCH_CATEGORIES.PUBLICATION].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <NewsReferenceCard
              result={result as PublicationResult}
              isActive={result.id === selectedPointId}
            />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.PUBLICATION],
    } as TabConfig,
    [SEARCH_CATEGORIES.SOCIAL_MEDIA]: {
      category: SEARCH_CATEGORIES.SOCIAL_MEDIA,
      value: TAB_VALUES[SEARCH_CATEGORIES.SOCIAL_MEDIA],
      content: timelineCategorized[SEARCH_CATEGORIES.SOCIAL_MEDIA].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <SocialMediaReferenceCard
              result={result as SocialMediaResult}
              isActive={result.id === selectedPointId}
            />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.SOCIAL_MEDIA],
    } as TabConfig,
    [SEARCH_CATEGORIES.FIRE]: {
      category: SEARCH_CATEGORIES.FIRE,
      value: TAB_VALUES[SEARCH_CATEGORIES.FIRE],
      content: timelineCategorized[SEARCH_CATEGORIES.FIRE].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <FireReferenceCard
              result={result as FireResult}
              isActive={result.id === selectedPointId}
            />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.FIRE],
    } as TabConfig,
    [SEARCH_CATEGORIES.SHIP]: {
      category: SEARCH_CATEGORIES.SHIP,
      value: TAB_VALUES[SEARCH_CATEGORIES.SHIP],
      content: timelineCategorized[SEARCH_CATEGORIES.SHIP].map(
        (result: ResultWithId) =>
          renderCard(
            result,
            <ShipReferenceCard
              result={result as ShipResult}
              isActive={result.id === selectedPointId}
            />,
          ),
      ),
      results: timelineCategorized[SEARCH_CATEGORIES.SHIP],
    } as TabConfig,
  } as const

  const handleTabChange = (value: string | null) => {
    const isTabValid = isTabValue(value)
    const newTab = isTabValid ? value : null
    setSearchParams((params) => {
      params.delete('tab')
      const newParams = Object.fromEntries(params.entries())
      return {
        ...newParams,
        ...(newTab && { tab: newTab }),
      }
    })
    setActiveTab(newTab ?? TAB_VALUES.SUMMARY)
    setActiveCategory(
      Object.values(tabs).find((tab) => tab.value === value)?.category ??
        SEARCH_CATEGORIES.IMAGE,
    )
  }
  return {
    orderedTabs: TAB_ORDER.map((category) => tabs[category]),
    handleTabChange,
    activeCategory,
    activeTab,
  }
}
