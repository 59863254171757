import { Box, type BoxComponentProps } from '@mantine/core'
import type { ReactNode } from 'react'

// CONTEXT: When using padding/margin with Mantine's ScrollArea the
//          content scrolls behind the padding/margin.
//          Use custom ScrollableContainer to avoid this issue.
export function ScrollableContainer({
  children,
  ...boxProps
}: BoxComponentProps & { children: ReactNode }) {
  return (
    <Box {...boxProps} flex={1} style={{ overflow: 'hidden auto' }}>
      {children}
    </Box>
  )
}
