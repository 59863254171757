import { Center } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { SingleImageMap } from '@/features/DantiMap'
import { ResultImage, type ResultImageObject } from '@/features/ResultImage'
import type { SymbolName } from '@/utils/types/material-icons'
import type { ImageryResult } from '@/utils/types/result-types'
import { StaticImageHero } from './StaticImageHero'

import styles from './hero.module.css'

export interface SOVHeroImageProps {
  staticImage?: string | null
  imageAlt?: string
  mapImage?: ImageryResult | null
  vexcelImage?: string | null
  vexcelSource?: string | null
  thumbnailImage?: ResultImageObject | null
  fallbackIcon?: SymbolName
}

export function SOVHeroImage({
  staticImage,
  imageAlt = 'Image Result',
  mapImage,
  vexcelImage,
  vexcelSource,
  thumbnailImage,
  fallbackIcon,
}: SOVHeroImageProps) {
  if (staticImage !== undefined) {
    return staticImage ? (
      <StaticImageHero image={staticImage} title={imageAlt} />
    ) : (
      <Center bg="gray.3" h={480}>
        <Icon
          className={styles.noImageIcon}
          name={fallbackIcon || 'camera'}
          size="xl"
        />
      </Center>
    )
  }
  if (thumbnailImage) {
    return (
      <ResultImage item={thumbnailImage} className={styles.thumbnailImage} />
    )
  }
  if (vexcelImage) {
    return (
      <ResultImage
        item={{
          thumbnail: vexcelImage ?? '',
          title: '',
          source: vexcelSource ?? '',
        }}
        style={{
          height: '500px',
        }}
      />
    )
  }
  if (mapImage) {
    return <SingleImageMap key={mapImage.id} item={mapImage} />
  }

  return (
    <Center>
      <Icon className={styles.noImageIcon} name="camera" size="xl" />
    </Center>
  )
}
