import { type MutableRefObject, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSingleObjectViewItem } from '@/api/collections/collection-items/use-single-object-view-item'
import { useSearchMetadata } from '@/api/results'
import { actOnMap } from '@/features/DantiMap/utils'
import * as turf from '@turf/turf'

export const useInitialBehavior = (
  map: MutableRefObject<mapboxgl.Map | null>,
) => {
  const { itemId, collectionId, terrapinItemId } = useParams()
  const { item } = useSingleObjectViewItem({
    itemId,
    collectionId,
    terrapinItemId,
  })
  const searchMetadata = useSearchMetadata()

  const isSOV = itemId || collectionId || terrapinItemId
  const geometry = isSOV ? item?.geometry : searchMetadata?.geometry

  useEffect(() => {
    if (!geometry) return

    const feature = turf.feature(geometry)
    const { type } = feature.geometry

    actOnMap(() => {
      if (['Polygon', 'MultiPolygon'].includes(type)) {
        const bbox = turf.bbox(feature)
        map.current?.fitBounds(bbox as mapboxgl.LngLatBoundsLike, {
          duration: 0,
        })
      } else if (type === 'Point') {
        const [c0, c1] = feature.geometry.coordinates
        map.current?.jumpTo({ center: [c0, c1], zoom: isSOV ? 14 : 8 })
      }
    })
  }, [map, geometry, isSOV])
}
