import { getCoreLogicPropertyDataFields } from '@/api/results'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import type { ObjectViewProps } from '@/features/SingleObjectView/types'
import { formatDatetime } from '@/utils/format-datetime'
import type { CoreLogicPropertyResult } from '@/utils/types/result-types'

export function CoreLogicPropertyObjectView({
  item: rawItem,
}: ObjectViewProps) {
  const item = rawItem as CoreLogicPropertyResult

  return (
    <SingleObjectViewTemplate<CoreLogicPropertyResult, string>
      {...{
        item,
        hero: {
          metaDetails: [formatDatetime(item.authoredOn)],
        },
        content: {
          title: item.properties.address,
          details:
            'Detailed property information including building characteristics, features, and location details.',
          data: getCoreLogicPropertyDataFields(item),
        },
      }}
    />
  )
}
