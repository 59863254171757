import { useParams } from 'react-router-dom'
import { Stack } from '@mantine/core'
import { ScrollableContainer } from '@/components/ScrollableContainer'
import { SOVCarousel, type SOVCarouselProps } from './Carousel'
import { SOVContent, type SOVContentProps } from './Content'
import { SOVHero, type SOVHeroProps } from './Hero'

export type { SOVContentProps } from './Content'
export type { SOVHeroProps } from './Hero'

export interface SingleObjectViewTemplateProps<ItemType, CarouselType> {
  item: ItemType
  carousel?: SOVCarouselProps<CarouselType>
  content?: SOVContentProps
  hero: Omit<SOVHeroProps<ItemType>, 'item'>
}

export function SingleObjectViewTemplate<IT, CT>({
  item,
  carousel,
  content,
  hero,
}: SingleObjectViewTemplateProps<IT, CT>) {
  const heroWithCapture = { ...hero }
  const { collectionId, terrapinItemId } = useParams()
  const isSavedSearch = collectionId || terrapinItemId

  return (
    <ScrollableContainer px={{ base: 'sm', lg: 'md' }} pt="sm">
      <Stack gap="xs">
        <SOVHero {...heroWithCapture} item={item} />
        {content && <SOVContent {...content} />}
        {carousel && !isSavedSearch && <SOVCarousel {...carousel} />}
      </Stack>
    </ScrollableContainer>
  )
}
