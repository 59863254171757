import { Stack } from '@mantine/core'
import type React from 'react'

type ItemWithId<T> = T & { id: string }

export const ListDisplay = <T,>({
  items,
  itemComponent: ItemComponent,
}: {
  items: ItemWithId<T>[]
  itemComponent: React.FC<{ data: T }>
}) => (
  <Stack mt="xs" gap={0} hiddenFrom="sm">
    {items.map((item) => (
      <ItemComponent key={item.id} data={item} />
    ))}
  </Stack>
)
