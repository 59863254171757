export const avatarColorOptions = [
  'teal.4',
  'blue.4',
  'teal',
  'blue',
  'purple',
  'red.5',
  'red.4',
  'red.3',
  'yellow',
  'orange',
]

export const COLORS = {
  darken: [
    '#00000010', // 0
    '#0000001b', // 1
    '#00000034', // 2
    '#0000004e', // 3
    '#00000067', // 4
    '#00000081', // 5
    '#0000009a', // 6
    '#000000b4', // 7
    '#000000cd', // 8
    '#000000e7', // 9
  ],

  blue: [
    '#E3F2FD', // 0
    '#BBDEFB', // 1
    '#90CAF9', // 2
    '#64B5F6', // 3
    '#42A5F5', // 4
    '#2196F3', // 5
    '#0091EA', // 6
    '#1976D2', // 7
    '#2b5ba9', // 8
    '#0D47A1', // 9
  ],

  teal: [
    '#E0F2F1', // 0
    '#B2DFDB', // 1
    '#80CBC4', // 2
    '#4DB6AC', // 3
    '#26A69A', // 4
    '#009688', // 5
    '#00A5B0', // 6
    '#00897B', // 7
    '#00796B', // 8
    '#004D40', // 9
  ],

  purple: [
    '#F3E5F5', // 0
    '#E1BEE7', // 1
    '#CE93D8', // 2
    '#BA68C8', // 3
    '#834DD3', // 4
    '#8E24AA', // 5
    '#7B1FA2', // 6
    '#6A1B9A', // 7
    '#4A148C', // 8
    '#38006b', // 9
  ],

  red: [
    '#FFEBEE', // 0
    '#FFCDD2', // 1
    '#EF9A9A', // 2
    '#E57373', // 3
    '#EF5350', // 4
    '#D32F2F', // 5
    '#C62828', // 6
    '#B71C1C', // 7
    '#891515', // 8
    '#5D0F0F', // 9
  ],

  orange: [
    '#FFF3E0', // 0
    '#FFE0B2', // 1
    '#FFCC80', // 2
    '#FFB74D', // 3
    '#FFA726', // 4
    '#FF9800', // 5
    '#ED6C02', // 6
    '#E65100', // 7
    '#BF360C', // 8
    '#8F2704', // 9
  ],

  green: [
    '#E8F5E9', // 0
    '#C8E6C9', // 1
    '#A5D6A7', // 2
    '#81C784', // 3
    '#4CAF50', // 4
    '#2E7D32', // 5
    '#1B5E20', // 6
    '#829A24', // 7
    '#33691E', // 8
    '#1A3311', // 9
  ],

  gray: [
    '#f9f9f9', // 0
    '#f5f5f5', // 1
    '#eeeeee', // 2
    '#e5e5e5', // 3
    '#e0e0e0', // 4
    '#c4c4c4', // 5
    '#aaaaaa', // 6
    '#626262', // 7
    '#424242', // 8
    '#212121', // 9
  ],

  yellow: [
    '#FFF8E1', // 0
    '#FFECB3', // 1
    '#FFE082', // 2
    '#FFD54F', // 3
    '#f1c21b', // 4
    '#FFC107', // 5
    '#FFB300', // 6
    '#FFA000', // 7
    '#FF8F00', // 8
    '#FF6F00', // 9
  ],
} as const

export const SOCIAL_COLORS = {
  facebook: COLORS.blue[6],
  youtube: '#FF0000',
  instagram: '#E1306C',
  reddit: '#FF4500',
  telegram: COLORS.blue[6],
  toutiao: '#FF8247',
  weibo: '#E6162D',
  vk: '#4C7FA3',
} as { [key: string]: string }

export const SCORE_COLORS = {
  A: COLORS.green[5],
  B: COLORS.green[7],
  C: COLORS.orange[4],
  D: COLORS.orange[6],
  F: COLORS.red[6],
} as { [key: string]: string }

export const SEMANTIC_COLORS = {
  error: COLORS.red[5],
  warning: COLORS.orange[6],
  success: COLORS.green[5],
  info: COLORS.blue[6],
} as const

export const RESULT_CATEGORY_COLORS = {
  attom: COLORS.purple[4],
  fire: COLORS.red[5],
  ship: COLORS.teal[6],
  news: COLORS.orange[4],
  social: COLORS.green[4],
}
