import { useNavigate } from 'react-router-dom'
import { Burger, Group } from '@mantine/core'
import { useAddToCollection, useCollections } from '@/api/collections'
import { useDataMetadata } from '@/api/data-metadata/use-data-metadata.ts'
import { DantiWordMark } from '@/components/lib/DantiWordMark'
import { NAV_ITEMS } from '@/features/AppLayout/constants'
import { useExploreUrl } from '@/features/AppRouting/hooks'
import { useClearAll } from '@/hooks/use-clear-all'
import { ROUTES } from '@/utils/constants'
import { HeaderLink, HeaderLinkHistory, HeaderUserMenu } from './components'

export interface HeaderAuthenticatedProps {
  opened: boolean
  toggle: () => void
}

export function HeaderAuthenticated({
  opened,
  toggle,
}: HeaderAuthenticatedProps) {
  useDataMetadata()
  useCollections()
  useAddToCollection()

  const navigate = useNavigate()
  const clearAll = useClearAll()
  const exploreUrl = useExploreUrl()

  return (
    <>
      <Group gap="xl" visibleFrom="sm">
        <DantiWordMark
          onClick={() => {
            clearAll()
            navigate(ROUTES.root)
          }}
        />
        <Group gap="xl" ml="md">
          {NAV_ITEMS.map(({ id, path, label, icon }) =>
            id === 'history' ? (
              <HeaderLinkHistory key={id} />
            ) : (
              <HeaderLink
                to={id === 'explore' ? exploreUrl : path}
                key={id}
                title={label}
                icon={icon}
              />
            ),
          )}
        </Group>
      </Group>

      {/* MOBILE/SMALL SCREEN */}
      <Burger
        color="white"
        opened={opened}
        onClick={toggle}
        size="sm"
        hiddenFrom="sm"
      />

      <HeaderUserMenu />
    </>
  )
}
