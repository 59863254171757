import type {
  ComplexEvent,
  ComplexEventWithFunction,
  ComplexEventWithProperties,
  PostHogEventObject,
} from '@/features/Analytics/types'

export function isComplexEvent(
  event: PostHogEventObject,
): event is ComplexEvent {
  return (
    typeof event === 'object' &&
    'eventName' in event &&
    Array.isArray(event.properties)
  )
}

export function isDynamicEventName(
  event: PostHogEventObject,
): event is ComplexEventWithFunction {
  return (
    isComplexEvent(event) &&
    typeof event.eventName === 'function' &&
    Array.isArray(event.variables)
  )
}

export function isStaticEventName(
  event: PostHogEventObject,
): event is ComplexEventWithProperties {
  return isComplexEvent(event) && typeof event.eventName === 'string'
}
