import type {
  CategorizedResults,
  ImageryResult,
  ResultWithId,
} from '@/utils/types/result-types.ts'
import * as Sentry from '@sentry/react'

export type ResultPredicate = (r: ResultWithId) => boolean

export const filterCategorizedResults = (
  cr: CategorizedResults,
  predicate: ResultPredicate,
): CategorizedResults => {
  return {
    imageResults: cr.imageResults.filter((r) => predicate(r)),
    dataResults: cr.dataResults.filter((r) => predicate(r)),
    publicationResults: cr.publicationResults.filter((r) => predicate(r)),
    socialMediaResults: cr.socialMediaResults.filter((r) => predicate(r)),
    insightResults: cr.insightResults,
    propertyResults: cr.propertyResults.filter((r) => predicate(r)),
  }
}

type Results = {
  ids: string[]
  categorized: CategorizedResults
  currentQueryId: string
}

export const flattenResults = (
  categorized: CategorizedResults,
): ResultWithId[] => {
  const withoutInsights: Partial<CategorizedResults> = { ...categorized }
  delete withoutInsights.insightResults
  return Object.values(withoutInsights).flat() as ResultWithId[]
}

export const filterResultsById = (
  predicate: ResultPredicate,
  unfilteredResults: Results,
): Results => {
  const results = flattenResults(unfilteredResults.categorized).filter((r) =>
    predicate(r),
  )
  const categorized = filterCategorizedResults(
    unfilteredResults.categorized,
    predicate,
  )
  const ids = results.map((r) => r.id)

  return {
    currentQueryId: unfilteredResults.currentQueryId,
    categorized,
    ids,
  }
}

export const extractProvenance = (
  ir: ImageryResult,
  orderByProvider?: boolean,
) => {
  const source = ir.properties._dSource
  const provider = ir.properties._dProvider
  const platform = ir.properties._dPlatform

  const sourceString = source ? `${source} ` : ''
  const platformString = platform ? `${platform} ` : ''
  const addParens = platform || source
  const platformParen = platform
    ? `${addParens ? '(' : ''}${platform}${addParens ? ')' : ''}`
    : ''
  const providerParen = provider
    ? `${addParens ? '(' : ''}${provider}${addParens ? ')' : ''}`
    : ''
  const providerString = provider ? `${provider} ` : ''

  const sourceFirstTitle = `${sourceString}${platformString}${providerParen}`
  const providerFirstTitle = `${providerString}${sourceString}${platformParen}`

  if (!provider && !source && !platform) {
    return 'Unknown Source'
  }
  if (!source || provider?.toUpperCase() == source?.toUpperCase()) {
    return orderByProvider
      ? `${providerString}${platformParen}`
      : `${platformString}${platform ? providerParen : provider}`
  }
  return orderByProvider ? providerFirstTitle : sourceFirstTitle
}

export const extractAssetName = (ir: ImageryResult) => {
  const sensorProperty = ir.properties._dSensor as string
  if (!sensorProperty) {
    return 'Unknown'
  }
  switch (sensorProperty) {
    case 'EO':
      return 'Electro-optical (EO)'
    case 'SAR':
      return 'Synthetic Aperture Radar (SAR)'
    case 'MULTI_SPEC':
      return 'Multispectral'
    default:
      console.warn(`Encountered unknown asset property for: ${sensorProperty}`)
      Sentry.captureMessage(
        `Encountered unknown asset property for: ${sensorProperty}`,
        { level: 'warning', tags: { resultId: ir.id } },
      )
      return 'Unknown'
  }
}

export const extractSensorName = (ir: ImageryResult) => {
  const sensorProperty = ir.properties._dSensor as string
  if (!sensorProperty) {
    return 'Unknown'
  }
  return sensorProperty
}
