import { Box } from '@mantine/core'
import type { CollectionItem } from '@/api/collections'
import { getFireCategoryCardProps, SEARCH_CATEGORIES } from '@/api/results'
import { useSubscribeScrollIntoView } from '@/hooks/use-subscribe-scroll-into-view'
import type { FireResult } from '@/utils/types/result-types'
import { ReferenceCard } from './components/ReferenceCard'

export const FireReferenceCard = ({
  result,
  withActions,
  item,
  isActive = false,
}: {
  result: FireResult
  withActions?: boolean
  item?: CollectionItem
  isActive?: boolean
}) => {
  const { geometry, datetime, latitude, longitude } =
    getFireCategoryCardProps(result)
  const { targetRef } = useSubscribeScrollIntoView(isActive)

  return (
    <Box ref={targetRef}>
      <ReferenceCard
        result={result}
        item={item}
        category={SEARCH_CATEGORIES.FIRE}
        heroGeometry={geometry}
        title="Wildfire"
        authoredOn={datetime}
        details={`${latitude}, ${longitude}`}
        withActions={withActions}
        isActive={isActive}
      />
    </Box>
  )
}
