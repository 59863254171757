import { Container, type ContainerProps } from '@mantine/core'
import { APP_PADDING, HEADER_HEIGHT } from '@/features/AppLayout'

export function AppContainer({ children, ...props }: ContainerProps) {
  return (
    <Container
      size="lg"
      pt={HEADER_HEIGHT + APP_PADDING}
      pb={APP_PADDING}
      px="xl"
      maw="100%"
      mih="100%"
      {...props}
    >
      {children}
    </Container>
  )
}
