import { Button, Menu, Stack } from '@mantine/core'
import { iconSize } from '@/utils/icon-size'
import { MdAddCircleOutline } from 'react-icons/md'

export const ActionsMenu = ({ children }: { children: React.ReactNode }) => {
  return (
    <Menu position="bottom-start" trigger="click-hover">
      <Menu.Target>
        <Button
          size="xs"
          color="blue"
          leftSection={<MdAddCircleOutline size={iconSize('xs')} />}
          variant="filled"
        >
          ACTIONS
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Stack gap="xs">{children}</Stack>
      </Menu.Dropdown>
    </Menu>
  )
}
