import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import type { MultiPolygon, Point, Polygon } from 'geojson'

export function SOVHeroGeometry<Type>({
  geometry,
  color,
}: {
  item: Type
  geometry?: Polygon | Point | MultiPolygon
  color?: string
}) {
  return <GeometryStaticDisplay {...{ geometry, color }} />
}
