import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { BackgroundImage, Box } from '@mantine/core'
import {
  commercialBackgroundImages,
  govBackgroundImages,
} from '@/assets/images'
import { useFeatureFlag } from '@/hooks/use-feature-flag.ts'
import { FEATURE_FLAG_VALUES } from '@/utils/constants.ts'

import styles from './rotating-background.module.css'

const intervalDuration = 10000
const fadeTransition = 1000
const delayFadeOut = intervalDuration - fadeTransition

export default function RotatingBackground() {
  const [imageIndex, setImageIndex] = useState(0)
  const backgroundRef = useRef<HTMLImageElement>(null)
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  const backgroundImages = isCommercial
    ? commercialBackgroundImages
    : govBackgroundImages

  function getNextIndex(index: number = imageIndex) {
    return (index + 1) % backgroundImages.length
  }

  useEffect(() => {
    // Begin preload of all images on mount to ensure caching
    backgroundImages.forEach((image) => {
      const img = new Image()
      img.src = image
    })

    const backgroundInterval = setInterval(() => {
      setImageIndex(getNextIndex)
    }, intervalDuration)

    return () => {
      clearInterval(backgroundInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useLayoutEffect(() => {
    if (backgroundRef.current) {
      const timeout = setTimeout(() => {
        backgroundRef.current?.classList.add(styles['fadeOut'])
      }, delayFadeOut)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [imageIndex])

  return (
    <>
      <BackgroundImage
        src={backgroundImages[getNextIndex()]}
        pos="absolute"
        h="100%"
        top={0}
        left={0}
        // Using style.backgroundColor because `bg` caused a size flash -  Image
        //  was rendering at half-res first then full-res half a second later.
        //  Ensures any possible flash will be dark instead of white.
        style={{ zIndex: -1, backgroundColor: 'var(--mantine-color-gray-9)' }}
      />
      <BackgroundImage
        // Keyed to ensure it drops the 'fadeOut' class without fading back in
        key={imageIndex}
        src={backgroundImages[imageIndex]}
        ref={backgroundRef}
        pos="absolute"
        h="100%"
        top={0}
        left={0}
        style={{
          zIndex: -1,
          transition: `opacity ${fadeTransition}ms ease`,
          opacity: 1,
        }}
      />
      <Box
        bg="linear-gradient(180deg, #0000 60%, #000b 100%)"
        pos="absolute"
        style={{
          zIndex: -1,
          inset: 0,
        }}
      />
    </>
  )
}
