import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ActionIcon,
  Avatar,
  BackgroundImage,
  Divider,
  Group,
  NavLink,
  Stack,
} from '@mantine/core'
import {
  commercialBackgroundImages,
  govBackgroundImages,
} from '@/assets/images'
import { Icon } from '@/components/lib/Icon'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { NAV_ITEMS } from '@/features/AppLayout/constants'
import { useExploreUrl } from '@/features/AppRouting'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useAvatarDetails } from '@/stores/user-store'
import { FEATURE_FLAG_VALUES, ROUTES } from '@/utils/constants'

export interface NavbarProps {
  toggle: () => void
}

export function MobileNavMenu({ toggle }: NavbarProps) {
  const { logOut } = useDantiAuth()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const exploreUrl = useExploreUrl()
  const { avatarBackgroundColor, userInitials } = useAvatarDetails()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  const backgroundImages = isCommercial
    ? commercialBackgroundImages
    : govBackgroundImages

  return (
    <>
      <BackgroundImage src={backgroundImages[0]} p="md">
        <Group justify="space-between" align="start">
          <Avatar
            variant="filled"
            color={avatarBackgroundColor}
            size="lg"
            radius="sm"
          >
            {userInitials}
          </Avatar>
          <ActionIcon variant="transparent" color="white" onClick={toggle}>
            <Icon name="close" size="xl" />
          </ActionIcon>
        </Group>
      </BackgroundImage>
      <Stack py="md" px="xs" justify="space-between" h="100%">
        <Stack>
          <NavLink
            onClick={() => {
              navigate(ROUTES.root)
              toggle()
            }}
            label="Home"
            leftSection={<Icon name="home" />}
          />
          {NAV_ITEMS.map(({ id, icon, label, path }) => (
            <NavLink
              key={id}
              onClick={() => {
                navigate(id === 'explore' ? exploreUrl : path)
                toggle()
              }}
              label={label}
              leftSection={<Icon name={icon} />}
            />
          ))}
        </Stack>
        <Stack>
          <Divider mx="sm" />
          <NavLink
            onClick={() => {
              captureEvent(USER_EVENTS.LOGIN.LOGOUT)
              searchParams.delete('search')
              setSearchParams(searchParams)
              void logOut()
            }}
            label="Sign Out"
            leftSection={<Icon name="logout" />}
          />
        </Stack>
      </Stack>
    </>
  )
}
