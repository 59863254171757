import { GeometryStaticDisplay } from '@/features/GeometryStaticDisplay'
import { ResultImage } from '@/features/ResultImage'
import type { MultiPolygon, Point, Polygon } from 'geojson'
import { BentoCardIcon } from './BentoCardIcon'
import { BentoCardTextContent } from './BentoCardTextContent'

interface ImageCardContent {
  image?: {
    title: string
    source: string
    thumbnail?: string
  }
}
interface GeometryCardContent {
  geometry?: Polygon | Point | MultiPolygon
}
interface DataCardContent {
  content?: {
    heading?: React.ReactNode
    subHeading: React.ReactNode
    color?: string
    fontColor?: string
  }
}

export interface BentoCardContentParams
  extends ImageCardContent,
    GeometryCardContent,
    DataCardContent {
  icon?: string
  svgIcon?: React.ReactNode
  socialIcon?: React.ReactNode
}
export const BentoCardContent = ({
  icon,
  image,
  geometry,
  content,
  svgIcon,
  socialIcon,
}: BentoCardContentParams) => (
  <>
    {image && <ResultImage item={image} />}
    <BentoCardIcon
      {...{
        icon,
        svgIcon,
        socialIcon,
      }}
    />
    <BentoCardTextContent {...{ content }} />
    <GeometryStaticDisplay geometry={geometry} />
  </>
)

export default BentoCardContent
