import { type MutableRefObject, useEffect } from 'react'
import { fetchSignedS3URL } from '@/api/thumbnail'
import { makeImageBounds } from '@/features/DantiMap/utils'
import { makeOrthoLayer } from '@/features/DantiMap/utils/ortho-imagery'
import { appEnv, IMAGE_SOURCES } from '@/utils/constants'
import type { ImageryResult } from '@/utils/types/result-types'
import * as turf from '@turf/turf'

export const useSingleOrthoLayer = (
  map: MutableRefObject<mapboxgl.Map | null>,
  item: ImageryResult | undefined,
) => {
  const SINGLE_IMAGE = 'single_image'
  const orthoLayer = item ? makeOrthoLayer(item) : undefined

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    map.current?.on('styledata', async () => {
      if (!map.current || !orthoLayer) {
        return
      }

      const isImageSource = orthoLayer.source in IMAGE_SOURCES

      const isS3SignedUrl = !!orthoLayer.tileUrl.includes('s3')

      let url = isS3SignedUrl
        ? await fetchSignedS3URL(orthoLayer.tileUrl)
        : orthoLayer.tileUrl

      if (appEnv == 'gov' && url.includes('api.prod.danti.ai')) {
        url = url.replace('prod', 'gov')
      }

      if (!map.current?.getSource(SINGLE_IMAGE) && orthoLayer) {
        const bounds = turf.bbox(orthoLayer.geometry) as [
          number,
          number,
          number,
          number,
        ]
        const coords = makeImageBounds(orthoLayer.geometry)

        if (isImageSource) {
          map.current?.addSource(SINGLE_IMAGE, {
            type: 'image',
            url: url,
            coordinates: coords,
          })
        } else {
          map.current?.addSource(SINGLE_IMAGE, {
            type: 'raster',
            tiles: [url],
            bounds,
          })
        }
        map.current?.addLayer({
          type: 'raster',
          source: SINGLE_IMAGE,
          id: `${SINGLE_IMAGE}_layer`,
        })

        const bbox = turf.bbox(orthoLayer.geometry)
        map.current?.fitBounds(bbox as mapboxgl.LngLatBoundsLike, {
          padding: 75,
          animate: false,
        })

        const currentMap = map.current
        const resize = () => currentMap?.resize()
        currentMap?.on('load', resize)
        document.addEventListener('DOMContentLoaded', resize)
        return () => {
          currentMap.off('load', resize)
          document.removeEventListener('DOMContentLoaded', resize)
        }
      }
    })
  }, [orthoLayer, map])
}
