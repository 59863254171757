import {
  ActionIcon,
  Group,
  LoadingOverlay,
  Stack,
  Table,
  Title,
  Tooltip,
} from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { type HistoryItem, useDeleteHistoryMutation } from '@/api/history'
import { Icon } from '@/components/lib/Icon'
import { HistoryRow } from './HistoryRow'

// Given a date, return the start and end datetimes of the day
function getDayRange(date: Date): { startAt: Date; endAt: Date } {
  // Clone the date to avoid modifying the original date object
  const startAt = new Date(date)
  const endAt = new Date(date)

  startAt.setHours(0, 0, 0, 0)
  endAt.setHours(23, 59, 59, 999)

  return { startAt, endAt }
}

interface HistorySectionProps {
  date: Date | undefined
  title: string
  items: HistoryItem[] | undefined
}

export function HistoryDateSection({
  date,
  title,
  items,
}: HistorySectionProps) {
  const deleteHistoryMutation = useDeleteHistoryMutation()

  if (!date || !items) return

  const handleDeleteDayHistory = () => {
    const { startAt, endAt } = getDayRange(date)
    deleteHistoryMutation.mutate(
      {
        startAt: startAt.toISOString(),
        endAt: endAt.toISOString(),
      },
      {
        onSuccess: () => {
          notifications.show({
            title: 'Success',
            color: 'green',
            message: `Search history for ${title} deleted`,
          })
        },
        onError: () => {
          notifications.show({
            title: 'Error',
            color: 'red',
            message: `Failed to delete search history for ${title}`,
          })
        },
      },
    )
  }

  return (
    <Stack gap="xs" pos="relative">
      <LoadingOverlay
        visible={deleteHistoryMutation.isPending}
        overlayProps={{ blur: 2 }}
      />
      <Group justify="space-between" mr="xs">
        <Title order={2}>{title}</Title>
        <Tooltip label={`Delete all history for ${title}`}>
          <ActionIcon
            variant="subtle"
            color="red"
            onClick={handleDeleteDayHistory}
          >
            <Icon name="delete" />
          </ActionIcon>
        </Tooltip>
      </Group>
      <Table>
        <Table.Tbody>
          {items.map((item) => (
            <HistoryRow key={item.queryId} item={item} />
          ))}
        </Table.Tbody>
      </Table>
    </Stack>
  )
}
