import { px } from '@mantine/core'
import { theme } from '@/theme'

export function iconSize(size: string | number) {
  if (typeof size === 'number') {
    return size
  }

  if (size in theme.fontSizes) {
    return px(theme.fontSizes[size])
  }
  if (size.includes('em')) {
    return px(size)
  }
  if (size.includes('px')) {
    return Number.parseInt(size.replace('px', ''), 10)
  }

  throw new Error(
    `Invalid size value: ${size}. Use number (px), rem or theme.fontSizes`,
  )
}

export const ICON_SIZES = {
  xs: px(theme.fontSizes.xs),
  sm: px(theme.fontSizes.sm),
  md: px(theme.fontSizes.md),
  lg: px(theme.fontSizes.lg),
  xl: px(theme.fontSizes.xl),
  xxl: px(theme.fontSizes.xxl),
  huge: px(theme.fontSizes.huge),
}
