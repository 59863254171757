import React from 'react'
import { Button, Grid, Stack, TextInput, Title } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useHistory } from '@/api/history'
import { Icon } from '@/components/lib/Icon'
import { Pager } from '@/components/lib/Pagination'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { openRemoveAllHistoryModal } from '@/features/AppLayout/Modals'

const handleRemoveAll = () => {
  captureEvent(USER_EVENTS.HISTORY.REMOVE_ALL)
  openRemoveAllHistoryModal()
}

export function HistoryDetailHeader() {
  const [searchText, setSearchText] = React.useState('')
  const [debouncedSearchText] = useDebouncedValue(searchText, 300)
  const { search, currentPage, pageSize, totalCount, nextPage, previousPage } =
    useHistory()

  React.useEffect(() => {
    if (debouncedSearchText !== '') {
      captureEvent(USER_EVENTS.HISTORY.SEARCH)
    }
    search(debouncedSearchText)
  }, [search, debouncedSearchText])

  return (
    <Stack gap="sm">
      <Title>History</Title>
      <Grid align="center" gutter="xs">
        <Grid.Col span={{ base: 12, md: 'auto' }}>
          <TextInput
            leftSection={<Icon name="search" />}
            placeholder="Search in history"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </Grid.Col>
        <Grid.Col span={2}>
          <Button
            variant="subtle"
            color="red"
            bg={totalCount === 0 ? 'white' : undefined}
            size="xs"
            leftSection={<Icon size={18} name="delete_outline" />}
            onClick={handleRemoveAll}
            disabled={totalCount === 0}
          >
            Delete All History
          </Button>
        </Grid.Col>
        <Grid.Col span={{ base: 'auto', md: 2 }}>
          <Pager
            currentPage={currentPage}
            pageSize={pageSize}
            totalCount={totalCount}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </Grid.Col>
      </Grid>
    </Stack>
  )
}
