import { ActionIcon, Group, Text } from '@mantine/core'
import { Icon } from '@/components/lib/Icon.tsx'
import type { SymbolName } from '@/utils/types/material-icons'

import styles from './Pager.module.css'

export interface PagerProps {
  totalCount: number
  currentPage: number
  pageSize: number
  nextPage: () => void
  previousPage: () => void
}

const PageButton = ({
  onClick,
  disabled,
  icon,
}: {
  onClick: () => void
  disabled: boolean
  icon: SymbolName
}) => (
  <ActionIcon
    variant="subtle"
    onClick={onClick}
    disabled={disabled}
    className={styles.pageButton}
  >
    <Icon name={icon} size={16} weight={500} />
  </ActionIcon>
)

export function Pager({
  currentPage,
  pageSize,
  totalCount,
  nextPage,
  previousPage,
}: PagerProps) {
  const lowerRange = pageSize * currentPage + 1
  const higherRange = Math.min(totalCount, pageSize * (currentPage + 1))
  const maxPage = Math.ceil(totalCount / pageSize) - 1

  return (
    <Group gap="xs" justify="flex-end">
      <Text size="xs">
        {lowerRange}-{higherRange} of {totalCount}
      </Text>
      <Group gap={0}>
        <PageButton
          onClick={previousPage}
          disabled={currentPage === 0}
          icon="keyboard_arrow_left"
        />
        <PageButton
          onClick={nextPage}
          disabled={currentPage === maxPage}
          icon="keyboard_arrow_right"
        />
      </Group>
    </Group>
  )
}
