import { normalizeUrl } from '@/api/helper'
import { extractProvenance } from '@/stores/results-filter-store/utils'
import type {
  OrthoImageryLayer,
  OrthoImageryProvider,
} from '@/utils/types/ortho-imagery-types.ts'
import type { ImageryResult } from '@/utils/types/raw-result-types.ts'
import type { MultiPolygon, Polygon } from 'geojson'

type Geometry = Polygon | MultiPolygon

export const makePngImageOrthoLayer = (r: ImageryResult): OrthoImageryLayer => {
  const featureId = (r.properties.featureId as string) || r.id

  return {
    source: r.source as OrthoImageryProvider,
    id: r.documentId,
    tileUrl: normalizeUrl(r.properties._dPreview as string),
    authoredOn: r.authoredOn,
    geometry: r.geometry as Geometry,
    documentId: r.documentId,
    imageResult: r,
    groupKey: featureId,
    score: r.score,
    formattedSource: `${extractProvenance(r)}`,
  }
}
