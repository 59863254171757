import { FlaggedRoute } from '@/features/AppRouting/components'
import type { FeatureLock } from '@/features/AppRouting/constants'
import type { FeatureFlagKeys } from '@/utils/constants'

export interface RouteProps {
  Component: React.ComponentType
  feature?: FeatureLock | FeatureFlagKeys
}

export const DantiRoute = ({ Component, feature, ...route }: RouteProps) => {
  let element = <Component />
  element = feature ? (
    <FlaggedRoute
      isProtected
      {...{ feature: feature as FeatureFlagKeys, element, ...route }}
    />
  ) : (
    element
  )
  return element
}
