import { useLocation } from 'react-router-dom'
import { Group } from '@mantine/core'
import { ROUTES } from '@/utils/constants'
import { HeaderAuthenticated, HeaderUnauthenticated } from './'

interface HeaderProps {
  opened: boolean
  toggle: () => void
}

export function Header({ opened, toggle }: HeaderProps) {
  const { pathname } = useLocation()
  const isLogIn = pathname === ROUTES.login

  return (
    <Group h={60} px="md" bg="gray.9" justify="space-between">
      {isLogIn ? (
        <HeaderUnauthenticated />
      ) : (
        <HeaderAuthenticated opened={opened} toggle={toggle} />
      )}
    </Group>
  )
}
