import React from 'react'
import { useParams } from 'react-router-dom'
import { useDebouncedValue } from '@mantine/hooks'
import { useFetchCollection } from '@/api/collections'
import { useCollectionItems } from '@/api/collections/collection-items'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { useNavigateToCollectionItem } from '@/features/AppRouting/hooks'
import type { ViewType } from '@/features/Collections/types'
import { useCollectionItemsState } from '@/stores/collection-items'
import type { CategoryTypes } from '@/utils/types/result-types'
import type { ColumnFiltersState } from '@tanstack/react-table'

export function useCollectionDetails() {
  const { collectionId } = useParams()
  const { search, searchTerm } = useCollectionItemsState()
  const navigateToCollectionItem = useNavigateToCollectionItem()

  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300)

  React.useEffect(() => {
    search(debouncedSearchTerm)
  }, [search, debouncedSearchTerm])

  const [viewType, setViewType] = React.useState<ViewType>('list')

  const { data: collection, isLoading: isLoadingCollection } =
    useFetchCollection(collectionId || '')

  const { selectedCategories, toggleSelectedCategory } =
    useCollectionItemsState()

  const { items, totalCount, isLoading } = useCollectionItems(collectionId)

  const handleToggleSelectedCategory = (category: CategoryTypes) => {
    if (!collection?.name) {
      return
    }

    let updatedCategories = [...selectedCategories]

    if (selectedCategories.includes(category)) {
      updatedCategories = updatedCategories.filter((item) => item != category)
    } else {
      updatedCategories.push(category)
    }

    captureEvent(USER_EVENTS.COLLECTIONS.FILTER, {
      filterType: 'type',
      filterValue: updatedCategories.join(','),
    })

    toggleSelectedCategory(category)
  }

  const columnFilters: ColumnFiltersState = React.useMemo(
    () => [{ id: 'kind', value: selectedCategories }],
    [selectedCategories],
  )

  const handleClick = (id: string) => {
    if (collection) {
      navigateToCollectionItem(collection.id, id)
    }
  }

  return {
    collection,
    columnFilters,
    handleClick,
    handleToggleSelectedCategory,
    hasCollection: collection && !isLoadingCollection,
    items,
    setViewType,
    totalCount,
    viewType,
    isLoading,
  }
}
