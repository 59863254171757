import { Divider, Group, Pill, Stack, Text } from '@mantine/core'
import {
  type Collection,
  type CollectionItem,
  isCollection,
} from '@/api/collections'
import { CollectionMenu } from '@/features/Collections/views/Collections/CollectionMenu'
import { formatDatetime } from '@/utils/format-datetime'
import { isSubCollection } from '@/utils/types/result-type-checkers'
import type { ReactElement } from 'react'

export interface ListItemProps {
  data: CollectionItem | Collection
  onClick: () => void
  icon: ReactElement
  date: string
  name: string
  status?: string
}

export const ListItem = ({
  data,
  onClick,
  icon,
  date,
  name,
  status,
}: ListItemProps) => {
  const shouldShowMenu = isCollection(data) && !isSubCollection(data)

  return (
    <Stack gap="xs" py="xs" onClick={onClick} style={{ cursor: 'pointer' }}>
      <Group justify="space-between">
        <Stack gap="xs">
          <Group gap="xs" wrap="nowrap">
            {icon}
            <Text c="dark" h="var(--mantine-font-size-xxl)" lineClamp={1}>
              {name}
            </Text>
          </Group>
          <Group gap="xs">
            <Text c="gray.6" size="12px">
              {formatDatetime(date)}
            </Text>
            {status === 'NEW' && (
              <Pill key="NEW" size="xs" bg="yellow.4" fw="bold">
                NEW
              </Pill>
            )}
          </Group>
        </Stack>
        {shouldShowMenu && (
          <CollectionMenu collection={data} orientation="vertical" />
        )}
      </Group>
      <Divider mt="xs" />
    </Stack>
  )
}
