import { Text } from '@mantine/core'
import { getRiskColor } from '@/api/results'
import { HazardRiskReferenceCard } from '@/features/ResultCards/ReferenceCards'
import { SingleObjectViewTemplate } from '@/features/SingleObjectView/components/SingleObjectViewTemplate/SingleObjectViewTemplate.tsx'
import {
  useHazardRiskList,
  useLocalNavigation,
} from '@/features/SingleObjectView/hooks'
import type {
  LoadedRisk,
  ObjectViewProps,
} from '@/features/SingleObjectView/types'
import { COLORS } from '@/theme/colors.ts'
import { ICONS } from '@/utils/constants.ts'
import { formatDatetime } from '@/utils/format-datetime'
import { RISK_ICONS } from '@/utils/svg-icons'
import type { HazardRiskResult } from '@/utils/types/result-types'

export function HazardRiskObjectView({ item: rawItem }: ObjectViewProps) {
  const item = rawItem as HazardRiskResult
  const riskList = useHazardRiskList(item)
  const { toNext, toPrevious, selectItem, current } =
    useLocalNavigation<LoadedRisk>(riskList)

  // Return simplified template if risks are not found - need to verify with product what to show
  if (!current) {
    return (
      <SingleObjectViewTemplate<HazardRiskResult, LoadedRisk>
        item={item}
        hero={{
          details: {
            iconName: ICONS.construction,
            color: COLORS.orange[4],
            title: item.title,
            leftDetail: 'No risks found',
            detailsList: [`Address: ${item.properties.address}`],
          },
          metaDetails: [formatDatetime(item.authoredOn)],
        }}
        content={{
          title: `Summary of Risks for ${item.properties.address}`,
        }}
      />
    )
  }

  return (
    <SingleObjectViewTemplate<HazardRiskResult, LoadedRisk>
      {...{
        item,
        hero: {
          details: {
            title: current.title,
            icon: RISK_ICONS[current.key],
            color: getRiskColor(current),
            leftDetail: current.risk,
            rightDetail: `Score: ${current.score ?? '--'}`,
            detailsList: current.description
              ? [
                  <Text key="desc-label" fw="bold">
                    Description
                  </Text>,
                  <Text key="desc-value">{current.description}</Text>,
                ]
              : undefined,
          },
          metaDetails: [formatDatetime(item.authoredOn)],
          toNext,
          toPrevious,
        },
        content: {
          title: `Summary of Risks and Hazard for ${item.properties.address}`,
        },
        carousel: {
          title: 'All Risks & Hazards',
          items: riskList,
          renderCard: (item: LoadedRisk) => (
            <HazardRiskReferenceCard result={item} />
          ),
          selectItem,
        },
      }}
    />
  )
}
