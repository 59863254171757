import * as React from 'react'
import type { HistoryItem } from '@/api/history'
import { useQueryId, useSearchQuery } from '@/api/results'
import { captureEvent, USER_EVENTS } from '@/features/Analytics'
import { useClearAll } from '@/hooks/use-clear-all'
import { useDantiAuth } from '@/hooks/use-danti-auth'
import {
  type Filters,
  useGetFilters,
  useResetFilters,
  useSetFilters,
} from '@/stores/filters-store'
import { MAP_LAYER_OPTIONS, useSetActiveLayers } from '@/stores/map-store'
import {
  useCurrentQuery,
  useSetQuery,
  useSetQueryFilters,
  useSetQueryStarted,
  useSetRootQueryId,
  useSetTotalProcessedResults,
} from '@/stores/queries-store'
import { debug } from '@/utils/debug'
import { makeApiFilters } from '@/utils/search-filters/make-api-filters'
import { makeSemanticQueryFilters } from '@/utils/search-filters/make-semantic-filters'
import type {
  LocationApiFilterValue,
  RadiusApiFilterValue,
} from '@/utils/types/filter-types'
import { useQueryClient } from '@tanstack/react-query'
import { singletonHook } from 'react-singleton-hook'

type AoiFilter = {
  filter: RadiusApiFilterValue | LocationApiFilterValue
  type: 'circle' | 'latLong' | 'polygon'
}

export function useSearchImpl() {
  const queryClient = useQueryClient()
  const { isAuthenticated } = useDantiAuth()

  const setQuery = useSetQuery()
  const setQueryFilters = useSetQueryFilters()
  const setTotalProcessedResults = useSetTotalProcessedResults()

  const setActiveLayers = useSetActiveLayers()
  const setFilters = useSetFilters()
  const filters = useGetFilters()
  const resetFilters = useResetFilters()
  const currentQuery = useCurrentQuery()
  const currentQueryId = useQueryId()
  const setRootQueryId = useSetRootQueryId()
  const setQueryStarted = useSetQueryStarted()
  useSearchQuery()

  const clearAll = useClearAll()

  const doSearch = React.useCallback(
    (params: { query: string; filters: Partial<Filters> }) => {
      if (!isAuthenticated) {
        throw new Error('Attempted to search before login')
      }
      clearAll()
      setTotalProcessedResults(0)
      setFilters(params.filters)
      const apiFilters = makeApiFilters(params.filters)
      setQuery(params.query)
      setQueryFilters(apiFilters)
      setQueryStarted()

      captureEvent(USER_EVENTS.SEARCH.SUBMIT, {
        query: params.query,
        filters: JSON.stringify(params.filters),
      })

      debug(
        `Running search for ${params.query} with filters ${JSON.stringify(
          apiFilters,
        )}`,
      )

      // Display all layers when a search is made.
      setActiveLayers(Object.values(MAP_LAYER_OPTIONS))

      // Slight delay on backend when this shows up in opensearch
      setTimeout(() => {
        void queryClient.invalidateQueries({ queryKey: ['history'] })
        void queryClient.invalidateQueries({ queryKey: ['history', 0, ''] })
      }, 1000)
    },
    [
      isAuthenticated,
      queryClient,
      clearAll,
      setTotalProcessedResults,
      setQuery,
      setQueryFilters,
      setQueryStarted,
      setActiveLayers,
      setFilters,
    ],
  )

  const doTextSearch = React.useCallback(
    (searchString: string) => {
      setRootQueryId(null)
      resetFilters()
      return doSearch({ query: searchString, filters: {} })
    },
    [doSearch, resetFilters, setRootQueryId],
  )

  const doLocationSearch = React.useCallback(
    (location: string, locationType: string, query: string = '') => {
      setRootQueryId(null)
      const locationFilter: Partial<Filters> = { location, locationType }
      return doSearch({ query, filters: locationFilter })
    },
    [doSearch, setRootQueryId],
  )

  const doRadiusSearch = React.useCallback(
    (geojson: RadiusApiFilterValue, query: string = '') => {
      setRootQueryId(null)
      const locationFilter: Partial<Filters> = { radius: geojson }
      return doSearch({ query, filters: locationFilter })
    },
    [doSearch, setRootQueryId],
  )

  const doSplitSearch = React.useCallback(
    (
      location: string,
      formatted: string,
      query: string,
      geocodeValues: {
        formattedAddress: string
        placeId: string
      },
    ) => {
      setRootQueryId(null)
      const combinedQuery = `${formatted}${query ? ` - ${query}` : ''}`
      const { locationType, keywords } = makeSemanticQueryFilters(combinedQuery)
      const splitFilters = {
        ...filters,
        location,
        locationType,
        keywords,
        ...geocodeValues,
      }

      return doSearch({ query: combinedQuery, filters: splitFilters })
    },
    [doSearch, filters, setRootQueryId],
  )

  const doLastSearchWithFilters = React.useCallback(() => {
    const filterPayload = makeApiFilters(filters)
    captureEvent(USER_EVENTS.EXPLORE.ADVANCED_SEARCH, {
      filters: JSON.stringify(filterPayload),
    })
    setRootQueryId(currentQueryId)
    return doSearch({ query: currentQuery, filters })
  }, [currentQuery, currentQueryId, doSearch, filters, setRootQueryId])

  // Returns object containing radius or location filter and filter type
  // Undefined if no filters present
  const getAOIFilter = React.useCallback(
    (item: HistoryItem): AoiFilter | null => {
      if (!item.filters) {
        return null
      }

      const radiusFilter = item.filters?.find(
        (filter) => filter.type === 'RADIUS',
      )

      if (radiusFilter) {
        return { filter: radiusFilter, type: 'circle' }
      }

      const locationFilter = item.filters?.find(
        (filter) => filter.type === 'LOCATION',
      )
      const locationTypeFilter = item.filters?.find(
        (filter) => filter.type === 'LOCATIONTYPE',
      )

      if (locationFilter && locationTypeFilter) {
        const locationType = locationTypeFilter.value as AoiFilter['type']
        return { filter: locationFilter, type: locationType }
      }

      return null
    },
    [],
  )

  const doSearchFromHistory = React.useCallback(
    (item: HistoryItem) => {
      const aoiFilter = getAOIFilter(item)

      if (!aoiFilter) {
        return doTextSearch(item.query)
      }

      if (aoiFilter.type === 'circle') {
        const radiusFilter = aoiFilter.filter as RadiusApiFilterValue
        return doRadiusSearch(radiusFilter, item.query)
      }

      if (aoiFilter.type === 'latLong' || aoiFilter.type === 'polygon') {
        const locationFilter = aoiFilter.filter as LocationApiFilterValue
        return doLocationSearch(
          locationFilter.value,
          aoiFilter.type,
          item.query,
        )
      }
    },
    [doLocationSearch, doRadiusSearch, doTextSearch, getAOIFilter],
  )

  return {
    doTextSearch,
    doLocationSearch,
    doRadiusSearch,
    doSplitSearch,
    doLastSearchWithFilters,
    doSearchFromHistory,
    getAOIFilter,
  }
}

export const useSearch = singletonHook<{
  doTextSearch: (searchString: string) => void
  doLocationSearch: (
    location: string,
    locationType: string,
    query?: string,
  ) => void
  doRadiusSearch: (geojson: RadiusApiFilterValue, query?: string) => void
  doSplitSearch: (
    location: string,
    formatted: string,
    query: string,
    geocodeValues: {
      formattedAddress: string
      placeId: string
    },
  ) => void
  doLastSearchWithFilters: () => void
  doSearchFromHistory: (item: HistoryItem) => void
  getAOIFilter: (item: HistoryItem) => AoiFilter | null
}>(
  () => ({
    doTextSearch: () => {},
    doLocationSearch: () => {},
    doRadiusSearch: () => {},
    doSplitSearch: () => {},
    doLastSearchWithFilters: () => {},
    doSearchFromHistory: () => {},
    getAOIFilter: () => {
      return null
    },
  }),
  useSearchImpl,
)
