export type SectionType =
  | 'list'
  | 'links'
  | 'text'
  | 'nested'
  | 'keyValue'
  | 'whitespace'

type StringRecord = Record<string, string>

export interface BaseSection {
  title: string
  type: SectionType
  content: string | string[] | StringRecord
  subsections?: BaseSection[]
}

// Type guards
export function isStringArray(
  content: BaseSection['content'],
): content is string[] {
  return Array.isArray(content)
}

export function isStringRecord(
  content: BaseSection['content'],
): content is StringRecord {
  return !Array.isArray(content) && typeof content === 'object'
}

export interface ListSectionProps extends BaseSection {
  content: string[]
  listDepth?: number
}

export interface LinkSectionProps extends BaseSection {
  content: string[]
}

export interface TextSectionProps extends BaseSection {
  content: string
}

export interface KeyValueSectionProps extends BaseSection {
  content: StringRecord
}

export interface NestedSectionProps extends BaseSection {
  content: string | string[] | StringRecord
  subsections: BaseSection[]
}

export interface WhitespaceSectionProps extends BaseSection {
  content: string[]
  subsections: BaseSection[]
}
