import type { CollectionItem } from '@/api/collections/types'
import { CollectionCard } from '@/features/Collections/components/CollectionCard'
import {
  FileReferenceCard,
  FireReferenceCard,
  ImageReferenceCard,
  NewsReferenceCard,
  ShipReferenceCard,
  SocialMediaReferenceCard,
} from '@/features/ResultCards'
import {
  isFileResult,
  isSubCollection,
} from '@/utils/types/result-type-checkers'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types'
import { ResultBase } from './ResultBase'

export interface CollectionItemGridResultProps {
  item: CollectionItem
}

export function CollectionItemGridResult({
  item,
}: CollectionItemGridResultProps) {
  const { doc } = item
  if (isSubCollection(item)) {
    return (
      <CollectionCard
        key={item.refId}
        collection={item}
        collectionId={item.refId}
      />
    )
  }

  const result = JSON.parse(doc) as ResultWithId

  let resultCard: JSX.Element
  // Necessary to pass item in as a prop as this is used for the menu actions to have access to the collection object
  if (isImageryResult(result)) {
    resultCard = <ImageReferenceCard result={result} item={item} />
  } else if (isSocialMediaResult(result)) {
    resultCard = <SocialMediaReferenceCard result={result} item={item} />
  } else if (isFireResult(result)) {
    resultCard = <FireReferenceCard result={result} item={item} />
  } else if (isShipResult(result)) {
    resultCard = <ShipReferenceCard result={result} item={item} />
  } else if (isFileResult(result)) {
    resultCard = <FileReferenceCard result={result} />
  } else {
    resultCard = <NewsReferenceCard result={result} item={item} />
  }
  return (
    <ResultBase item={item} result={result}>
      {resultCard}
    </ResultBase>
  )
}
