import { useEffect, useState } from 'react'
import { Box, Group, Slider, Stack, Text } from '@mantine/core'
import houseImage from '@/assets/overhead-house.png'
import type { FilterArgs } from '@/features/Filters/types'

type SliderMarks = {
  label: string
  value: string
}

const qualities: SliderMarks[] = [
  { label: '5cm', value: '0.05' },
  { label: '10cm', value: '0.1' },
  { label: '15cm', value: '0.15' },
  { label: '30cm', value: '0.3' },
  { label: '50cm', value: '0.5' },
  { label: '1m', value: '1' },
  { label: '3m', value: '3' },
  { label: '10m', value: '10' },
  { label: '10m+', value: '5000' },
]

const getQualityIndex = (value: string) => {
  const qualityIndex = qualities.findIndex((quality) => quality.value === value)

  return qualityIndex === -1 ? qualities.length - 1 : qualityIndex
}

export function Quality({ value, setter }: FilterArgs<string>) {
  const [qualityIndex, setQualityIndex] = useState(getQualityIndex(value))

  useEffect(() => setQualityIndex(getQualityIndex(value)), [value])

  return (
    <Stack gap="md" w="100%">
      <Group align="center" justify="space-between">
        <Text size="md">Quality</Text>
        <Text size="sm">
          {qualityIndex === qualities.length - 1
            ? 'Any quality'
            : `${qualities[qualityIndex].label} and better`}
        </Text>
      </Group>
      <Box
        pos="relative"
        w="100%"
        h={128}
        style={{
          overflow: 'hidden',
        }}
      >
        {
          // This establishes 2 image layers on top of each other to prevent the
          // edges of the image from showing as semi-transparent
          [0, 1].map((value) => (
            <img
              key={value}
              alt="blur"
              src={houseImage}
              style={{
                objectFit: 'cover',
                position: 'absolute',
                left: '-5%',
                top: '-5%',
                width: '110%',
                height: '110%',
                filter: `blur(${qualityIndex}px)`,
              }}
            />
          ))
        }
      </Box>
      <Slider
        styles={{ markLabel: { fontSize: 12 } }}
        restrictToMarks
        label={(value) => qualities[value].label}
        value={qualityIndex}
        onChange={(value) => setQualityIndex(value)}
        onChangeEnd={(value) => setter(qualities[value].value)}
        marks={qualities.map(({ label }, index) => ({
          value: index,
          label,
        }))}
        min={0}
        max={qualities.length - 1}
      />
    </Stack>
  )
}
