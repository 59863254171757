import { Group, Text } from '@mantine/core'
// import type { CollectionSortType } from '@/api/collections'
import type { CollectionItem } from '@/api/collections/types'
// import { TableHeaderSortCell } from '@/features/Collections/components'
// import { SORT_TYPES } from '@/features/Collections/constants'
import { formatDatetime } from '@/utils/format-datetime'
import { iconSize } from '@/utils/icon-size'
import {
  // type CategoryTypes,
  getLabelForResult,
  type ResultWithId,
} from '@/utils/types/raw-result-types'
import { isSubCollection } from '@/utils/types/result-type-checkers'
import { createColumnHelper } from '@tanstack/react-table'
import { MdOutlineFolder } from 'react-icons/md'
import { CollectionDetailResultIcon } from '../CollectionDetailResultIcon'
import { CollectionDetailMenu } from './CollectionDetailMenu'

const columnHelper = createColumnHelper<CollectionItem>()

// TODO: Restore when collection sorting is resolved

// interface ColumnFunctions {
//   setSort: (sort: CollectionSortType) => void
//   onCategoryToggle: (categoryTypes: CategoryTypes) => void
// }

export const CollectionDetailTableColumns = [
  columnHelper.accessor((row) => row, {
    id: 'name',
    header: (_info) => (
      // <TableHeaderSortCell
      //   setSort={(info.table.options.meta as ColumnFunctions).setSort}
      //   label="Name"
      //   sortType={SORT_TYPES.name}
      // />
      <Text fz="xs" fw="bold">
        Name
      </Text>
    ),
    size: 400,
    cell: (info) => {
      const item = info.getValue()

      return (
        <Group gap={12} wrap="nowrap">
          {isSubCollection(item) ? (
            <MdOutlineFolder size={iconSize('xl')} style={{ flexShrink: 0 }} />
          ) : (
            <CollectionDetailResultIcon
              result={JSON.parse(item.doc) as ResultWithId}
            />
          )}
          <Text>{item.name}</Text>
        </Group>
      )
    },
  }),

  columnHelper.accessor('authoredOn', {
    header: (_info) => (
      // <TableHeaderSortCell
      //   setSort={(info.table.options.meta as ColumnFunctions).setSort}
      //   label="Authored on"
      //   sortType={SORT_TYPES.date}
      // />
      <Text fz="xs" fw="bold">
        Authored on
      </Text>
    ),
    cell: (info) => <Text py="sm">{formatDatetime(info.getValue())}</Text>,
  }),

  columnHelper.accessor(
    (row) => {
      const { doc } = row
      return isSubCollection(row)
        ? 'Collection'
        : getLabelForResult(JSON.parse(doc) as ResultWithId)
    },
    {
      id: 'kind',
      size: 70,
      cell: (info) => <Text>{info.getValue()}</Text>,
      header: (_info) => {
        // TODO: Re-enable when backend support is available
        // return (
        //   <TableHeaderSortCell
        //     setSort={(info.table.options.meta as ColumnFunctions).setSort}
        //     label="Kind"
        //     sortType={SORT_TYPES.kind}
        //   />
        // )
        return (
          <Text fz="xs" fw="bold">
            Kind
          </Text>
        )
      },
    },
  ),

  columnHelper.accessor((row) => row, {
    id: 'actions',
    header: '',
    size: 70,
    cell: (info) => {
      const item = info.getValue()
      return !isSubCollection(item) && <CollectionDetailMenu item={item} />
    },
  }),
]
