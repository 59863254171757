import type { CollectionItem } from '@/api/collections/types'
import {
  FUTURE_MENU_OPTIONS,
  FUTURE_OPTION_TITLE,
  MENU_OPTION_ICONS,
  MENU_OPTION_KEYS,
  MENU_OPTION_LABELS,
} from '@/features/ResultActions/constants'
import type {
  ActionMenuOption,
  ActionMenuType,
} from '@/features/ResultActions/types'
import { useIsDownloadable } from '@/hooks/results/use-is-downloadable'
import { isFileResult } from '@/utils/types/result-type-checkers'
import { isImageryResult, type ResultWithId } from '@/utils/types/result-types'
import { useMenuOptionActions } from './use-menu-option-actions'

export interface UseResultMenuActionsParams<Type> {
  result: Type
  hideActions?: ActionMenuOption[]
  item?: CollectionItem
}

export const useResultMenuActions = <Type>({
  result,
  hideActions = [],
  item,
}: UseResultMenuActionsParams<Type>) => {
  const isDownloadable = useIsDownloadable(result as ResultWithId)
  const isAnImageResult = isImageryResult(result as ResultWithId)

  const genericResult = result as ResultWithId

  if (isFileResult(genericResult)) {
    hideActions.push(
      MENU_OPTION_KEYS.viewDetails,
      MENU_OPTION_KEYS.visitArticle,
    )
  }

  if (!isAnImageResult) {
    hideActions.push(MENU_OPTION_KEYS.downloadImage)
  }

  const canDownloadFile = item && isFileResult(genericResult) && !item.name

  if (!canDownloadFile) {
    hideActions.push(MENU_OPTION_KEYS.downloadFile)
  }

  if (!item) {
    hideActions.push(MENU_OPTION_KEYS.removeFromCollection)
  }

  const actionKeys = Object.keys(MENU_OPTION_KEYS).filter(
    (key) => !hideActions.includes(key),
  )
  const actions = useMenuOptionActions({ item, result: genericResult })

  return actionKeys.map((key: string) => {
    const action = {
      label: MENU_OPTION_LABELS[key as keyof typeof MENU_OPTION_LABELS],
      icon: MENU_OPTION_ICONS[key as keyof typeof MENU_OPTION_ICONS],
    } as ActionMenuType

    if (key === MENU_OPTION_KEYS.removeFromCollection) {
      return { ...action, onClick: actions[key], color: 'red' }
    }
    if (key === MENU_OPTION_KEYS.downloadFile) {
      return { ...action, disabled: false }
    }

    if (!isDownloadable && key === MENU_OPTION_KEYS.downloadImage) {
      return { ...action, disabled: true, title: 'Not available for download' }
    }

    if (FUTURE_MENU_OPTIONS.includes(key)) {
      return { ...action, disabled: true, title: FUTURE_OPTION_TITLE }
    }
    return { ...action, onClick: actions[key] }
  })
}
