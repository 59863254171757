import { BAR_FILL_COLOR } from '@/features/ResultsTimeline/constants'
import type { TimelineDoctypes } from '@/features/ResultsTimeline/types'
import { datetime } from '@/utils/format-datetime'

export const getCategoryColor = (category: TimelineDoctypes): string => {
  return BAR_FILL_COLOR[category]?.normal || 'var(--mantine-color-black)'
}

export const formatDateRange = (
  min: string | Date,
  max: string | Date,
): string =>
  `${datetime(min).format('MMM D, YYYY')} - ${datetime(max).format('MMM D, YYYY')}`

export const formatXAxisTick = (date: string): string => {
  return datetime(date).format('M/D')
}
