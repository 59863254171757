import { TextInput } from '@mantine/core'
import { iconSize } from '@/utils/icon-size'
import { MdOutlineSearch } from 'react-icons/md'

export const SearchInput = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string
  setSearchTerm: (value: string) => void
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }
  return (
    <TextInput
      placeholder="Search"
      withAsterisk
      leftSection={<MdOutlineSearch size={iconSize('xxl')} />}
      value={searchTerm}
      onChange={handleChange}
      miw={300}
    />
  )
}
