import { useMemo } from 'react'
import { SEARCH_CATEGORIES } from '@/api/results/constants'
import {
  ingestTransforms,
  loadGenericProps,
} from '@/api/results/data-transforms'
import type { Results } from '@/api/results/types'
import { RESULT_CATEGORIES } from '@/utils/constants'
import type {
  AttomResult,
  CoreLogicPropertyResult,
  CoreLogicRiskResult,
  HazardPropertyResult,
  HazardRiskResult,
  PropertyResult,
  RawSearchResult,
  RegridResult,
  VexcelImage,
  VexcelResult,
} from '@/utils/types/result-types'
import { useInsightQuery } from './use-insight-query'
import { useSearchResults } from './use-search-query'

export const useResults = (): Results => {
  const insightResult = useInsightQuery()?.insight?.insight
  const results = useSearchResults()
  return useMemo(() => {
    const addIdToDocument = (result: RawSearchResult) => {
      return { ...result, id: result.documentId }
    }
    const loadDocuments = (rawDocuments: RawSearchResult[]) => {
      const documents = rawDocuments.map(addIdToDocument)
      return documents.filter(
        (document, index) =>
          documents.findIndex((d) => d.id === document.id) === index,
      )
    }

    const getCategoryDocuments = (category: string) =>
      results.find((result) => result.category.toUpperCase() === category)
        ?.documents || []

    const loadCategory = (category: string) =>
      loadDocuments(getCategoryDocuments(category))

    const categorized = {
      [SEARCH_CATEGORIES.IMAGE]: loadCategory(SEARCH_CATEGORIES.IMAGE).map(
        ingestTransforms[RESULT_CATEGORIES.IMAGE],
      ),
      [SEARCH_CATEGORIES.FIRE]: loadCategory(SEARCH_CATEGORIES.FIRE),
      [SEARCH_CATEGORIES.SHIP]: loadCategory(SEARCH_CATEGORIES.SHIP),
      [SEARCH_CATEGORIES.PUBLICATION]: loadCategory(
        SEARCH_CATEGORIES.PUBLICATION,
      ).map(ingestTransforms[RESULT_CATEGORIES.PUBLICATION]),
      [SEARCH_CATEGORIES.SOCIAL_MEDIA]: loadCategory(
        SEARCH_CATEGORIES.SOCIAL_MEDIA,
      ),
      [SEARCH_CATEGORIES.INSIGHT]: insightResult
        ? loadDocuments([insightResult])
        : [],
    } as Results

    const propertyDocuments = getCategoryDocuments(RESULT_CATEGORIES.PROPERTY)
    const propertyByCategory = propertyDocuments.reduce(
      (object, property) => {
        const result = addIdToDocument(property) as PropertyResult
        let resultCategory
        switch (result.properties._dProvider) {
          case 'VEXCEL':
            resultCategory = SEARCH_CATEGORIES.VEXCEL
            break
          case 'REGRID':
            {
              resultCategory = SEARCH_CATEGORIES.REGRID
              let vexcelImages: VexcelImage[] =
                result.properties.vexcel_images?.vexcel_images ?? []

              // Create a separate VexcelResult if vexcel_images exist
              if (vexcelImages.length > 0) {
                vexcelImages = vexcelImages.map((image) => ({
                  ...image,
                  url: image.url.replace(
                    'api.test.danti.ai',
                    'api.dev.danti.ai',
                  ),
                }))
                const [vexcelImage] = vexcelImages
                const vexcelResult: VexcelResult = {
                  ...result,
                  // suffixing id with vexcel beause otherwise result id will be identical to REGRID result
                  id: `${result.id}-vexcel`,
                  properties: {
                    ...loadGenericProps(result),
                    _dProvider: 'VEXCEL',
                    resultCategory: RESULT_CATEGORIES.VEXCEL,
                    collection: vexcelImage.collection,
                    'estimate-date': vexcelImage['estimate-date'],
                    url: vexcelImage.url,
                    // eslint-disable-next-line camelcase
                    vexcel_images: { vexcel_images: vexcelImages },
                  },
                }
                if (!object[RESULT_CATEGORIES.VEXCEL])
                  object[RESULT_CATEGORIES.VEXCEL] = []
                object[RESULT_CATEGORIES.VEXCEL].push(vexcelResult)
              }
            }
            break
          case 'ATTOM':
            resultCategory = SEARCH_CATEGORIES.ATTOM
            break
          case 'CORELOGIC':
            resultCategory =
              result.properties._dProvider === 'CORELOGIC' &&
              'hailRisk' in result.properties
                ? SEARCH_CATEGORIES.CORELOGIC_RISK
                : SEARCH_CATEGORIES.CORELOGIC_PROPERTY
            break
          default:
            resultCategory =
              result.properties._dProvider === 'HAZARD' &&
              !('property' in result.properties)
                ? SEARCH_CATEGORIES.HAZARD_RISK
                : SEARCH_CATEGORIES.HAZARD_PROPERTY
        }
        if (!object[resultCategory]) object[resultCategory] = []
        object[resultCategory].push(result)
        return object
      },
      {} as Record<string, PropertyResult[]>,
    )
    const propertyTransform = ingestTransforms[RESULT_CATEGORIES.PROPERTY]
    const attomResults = propertyByCategory[SEARCH_CATEGORIES.ATTOM] || []
    const hazardPropertyResults =
      propertyByCategory[SEARCH_CATEGORIES.HAZARD_PROPERTY] || []
    const hazardRiskResults =
      propertyByCategory[SEARCH_CATEGORIES.HAZARD_RISK] || []
    const regridResults = propertyByCategory[SEARCH_CATEGORIES.REGRID] || []
    const vexcelResults = propertyByCategory[SEARCH_CATEGORIES.VEXCEL] || []
    const coreLogicPropertyResults =
      propertyByCategory[SEARCH_CATEGORIES.CORELOGIC_PROPERTY] || []
    const coreLogicRiskResults =
      propertyByCategory[SEARCH_CATEGORIES.CORELOGIC_RISK] || []

    categorized[SEARCH_CATEGORIES.ATTOM] = attomResults.map(
      propertyTransform,
    ) as AttomResult[]
    categorized[SEARCH_CATEGORIES.HAZARD_PROPERTY] = hazardPropertyResults.map(
      propertyTransform,
    ) as HazardPropertyResult[]
    categorized[SEARCH_CATEGORIES.HAZARD_RISK] = hazardRiskResults.map(
      propertyTransform,
    ) as HazardRiskResult[]
    categorized[SEARCH_CATEGORIES.CORELOGIC_PROPERTY] =
      coreLogicPropertyResults.map(
        propertyTransform,
      ) as CoreLogicPropertyResult[]
    categorized[SEARCH_CATEGORIES.CORELOGIC_RISK] = coreLogicRiskResults.map(
      propertyTransform,
    ) as CoreLogicRiskResult[]
    categorized[SEARCH_CATEGORIES.REGRID] = regridResults.map(
      propertyTransform,
    ) as RegridResult[]
    categorized[RESULT_CATEGORIES.VEXCEL] = vexcelResults.map(
      propertyTransform,
    ) as VexcelResult[]

    return categorized
  }, [insightResult, results])
}
