import {
  useDrawController,
  useSearchHerePopup,
  useSetSearchHerePopup,
} from './hooks'

export const useClearSearchHerePopup = () => {
  const searchHerePopup = useSearchHerePopup()
  const setSearchHerePopup = useSetSearchHerePopup()
  return () => {
    if (searchHerePopup) {
      searchHerePopup.remove()
      setSearchHerePopup(null)
    }
  }
}

export const useClearSearchPolygon = () => {
  const drawController = useDrawController()
  const clearSearchHerePopup = useClearSearchHerePopup()
  return () => {
    drawController?.deleteAll()
    clearSearchHerePopup()
  }
}
