import { Card, Group, Pill, Stack, Text, useMantineTheme } from '@mantine/core'
import type { Collection, CollectionItem } from '@/api/collections/types'
import { useNavigateToCollection } from '@/features/AppRouting/hooks'
import { CollectionMenu } from '@/features/Collections/views/Collections/CollectionMenu'
import { formatDatetime } from '@/utils/format-datetime'
import { iconSize } from '@/utils/icon-size'
import { isSubCollection } from '@/utils/types/result-type-checkers'
import {
  MdOutlineFolder,
  MdOutlineFolderSpecial,
  MdOutlineUpdate,
} from 'react-icons/md'

const collectionDatetime = (collection: Collection | CollectionItem) =>
  formatDatetime((collection.authoredOn || collection.createdOn).toString())

interface CollectionCardProps {
  collectionId: string
  collection: Collection | CollectionItem
}

export const CollectionCard = ({
  collectionId,
  collection,
}: CollectionCardProps) => {
  const theme = useMantineTheme()

  const iconHeight = iconSize('huge')
  const folderIconStyle = {
    color: theme.colors.blue[7],
    size: iconHeight,
  }
  const icons = {
    folder: <MdOutlineFolder {...folderIconStyle} />,
    specialFolder: <MdOutlineFolderSpecial {...folderIconStyle} />,
  }

  const navigateToCollection = useNavigateToCollection()
  const handleCollectionClick = () => navigateToCollection(collectionId)

  return (
    <Card
      padding="sm"
      withBorder
      radius="md"
      bg="gray.0"
      style={{ cursor: 'pointer' }}
      onClick={handleCollectionClick}
    >
      <Group gap="xs" wrap="nowrap" w="100%">
        <Stack gap="xs" h="100%">
          {collection.refId ? icons.specialFolder : icons.folder}
          <Pill
            size="xs"
            bg="yellow.5"
            fw="bold"
            style={collection.status === 'NEW' ? {} : { visibility: 'hidden' }}
          >
            NEW
          </Pill>
        </Stack>
        <Stack gap="xs" w="100%">
          <Group
            gap="sm"
            wrap="nowrap"
            h={iconHeight}
            w="100%"
            justify="space-between"
          >
            <Text c="gray.8" lineClamp={1} fz="lg" fw="bold">
              {collection.name}
            </Text>
            {!isSubCollection(collection) && (
              <CollectionMenu collection={collection} orientation="vertical" />
            )}
          </Group>
          <Group gap="xxs" wrap="nowrap" align="flex-start">
            <MdOutlineUpdate
              size={iconSize('md')}
              color="var(--mantine-color-gray-6)"
            />
            <Text size="xs" c="dimmed">
              {collectionDatetime(collection)}
            </Text>
          </Group>
        </Stack>
      </Group>
    </Card>
  )
}
