import React from 'react'
import { Badge, Group, Text } from '@mantine/core'
import type { ShareRoleType } from '@/api/collections'
import type {
  CollectionUser,
  UnshareWithVendorParams,
} from '@/api/collections/types'
import { ShareRoleSelect } from './ShareRoleSelect'

export interface UserListItemProps {
  user: CollectionUser
  currentUser: CollectionUser
  onRoleUpdate: (email: string, role: ShareRoleType) => Promise<void>
  onUnshare: (params: UnshareWithVendorParams) => Promise<void>
}

const rankedPermissions: ShareRoleType[] = [
  'viewer',
  'editor',
  'owner',
  'creator',
]

export function UserListItem({
  user,
  currentUser,
  onRoleUpdate,
  onUnshare,
}: UserListItemProps) {
  const [role, setRole] = React.useState(user.role)

  const isCurrentUser = user.email === currentUser.email

  const disabled = React.useMemo(() => {
    if (isCurrentUser) {
      return true
    }
    const currentUserRank = rankedPermissions.indexOf(currentUser.role)
    const userRank = rankedPermissions.indexOf(user.role)

    return currentUserRank <= userRank
  }, [user, currentUser, isCurrentUser])

  const handleRoleUpdate = (role: ShareRoleType) => {
    void onRoleUpdate(user.email, role)
    setRole(role)
  }

  const handleRevokeAccess = () => {
    void onUnshare({ emails: [user.email], role })
  }

  return (
    // Specifying a height to ensure consistent row heights.
    // Height differs because of ShareRoleSelect
    <Group gap="sm" h={40} justify="space-between">
      <Group gap="xs">
        <Text>{user.email}</Text>
        {isCurrentUser && (
          <Badge color="green" tt="capitalize" radius="sm">
            You
          </Badge>
        )}
      </Group>
      <ShareRoleSelect
        role={role}
        setRole={handleRoleUpdate}
        disabled={disabled}
        onRevokeAccess={handleRevokeAccess}
      />
    </Group>
  )
}
