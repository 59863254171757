import { Suspense } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Group, NavLink, Stack } from '@mantine/core'
import { Icon } from '@/components/lib/Icon'
import { useHasActiveSubscription } from '@/hooks/use-has-active-subscription'
import { useProfile } from '@/pages/Account/hooks/use-profile'
import { ROUTES, stripeBillingUrl } from '@/utils/constants'
import { ErrorBoundary } from '@/utils/ErrorBoundary'
import type { SymbolName } from '@/utils/types/material-icons'

interface SubrouteObject {
  [key: string]: {
    label: string
    path: string
    title: string
    disabled?: boolean
    icon: SymbolName
  }
}

export default function Account() {
  const { pathname } = useLocation()
  const { email, role } = useProfile()
  const { hasActiveSubscription, isOwner } = useHasActiveSubscription()
  const SUBROUTES: SubrouteObject = {
    ACCOUNT: {
      label: 'Profile',
      path: ROUTES.account,
      title: 'Profile',
      icon: 'person_outline',
    },
    PASSWORD: {
      label: 'Password',
      path: ROUTES.password,
      title: 'Password',
      icon: 'password',
    },
    USERS: {
      label: 'User Management',
      path: ROUTES.users,
      title: 'User Management',
      icon: 'manage_accounts',
    },
  }

  if (role === 'Admin' || role === 'Owner') {
    SUBROUTES.USERS = {
      label: 'User Management',
      path: ROUTES.users,
      title: 'Users',
      icon: 'manage_accounts',
    }
  }

  const accountNavLinks = Object.values(SUBROUTES).map(
    ({ path, label, icon, disabled }) =>
      disabled ? null : (
        <NavLink
          to={path}
          key={label}
          label={label}
          component={Link}
          active={path === pathname}
          leftSection={<Icon name={icon} />}
          bg="none"
        />
      ),
  )

  if (isOwner && hasActiveSubscription) {
    accountNavLinks.push(
      <NavLink
        href={`${stripeBillingUrl}?prefilled_email=${encodeURIComponent(email)}`}
        key="Billing"
        label="Billing"
        component="a"
        leftSection={<Icon name="credit_card" />}
        bg="none"
      />,
    )
  }

  return (
    <Group gap={0} align="flex-start">
      <Stack gap={0} mr="sm">
        {accountNavLinks}
      </Stack>
      <Stack gap={0} maw={600}>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Stack>
    </Group>
  )
}
