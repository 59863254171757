import React from 'react'
import { Center, Image } from '@mantine/core'
import { ResultImage } from '@/features/ResultImage'
import { iconSize } from '@/utils/icon-size'
import {
  isFireResult,
  isImageryResult,
  isShipResult,
  isSocialMediaResult,
  type ResultWithId,
} from '@/utils/types/result-types'
import {
  MdDashboard,
  MdOutlineAnchor,
  MdOutlineFolder,
  MdOutlineLocalFireDepartment,
} from 'react-icons/md'

export interface CollectionDetailResultIconProps {
  result: ResultWithId
}

export const CollectionDetailResultIcon = ({
  result,
}: CollectionDetailResultIconProps) => {
  const [showImage, setShowImage] = React.useState(
    Boolean(result?.properties?.src_image_url ?? result?.thumbnail),
  )
  const iconProps = { size: iconSize('md'), style: { flexShrink: 0 } }
  const propsWithColor = { ...iconProps, color: 'var(--mantine-color-white)' }

  const icons = {
    dashboard: <MdDashboard {...iconProps} />,
    fire: <MdOutlineLocalFireDepartment {...propsWithColor} />,
    anchor: <MdOutlineAnchor {...propsWithColor} />,
    folder: <MdOutlineFolder {...iconProps} />,
  }

  if (isImageryResult(result)) {
    return (
      <ResultImage
        item={result}
        style={{
          width: '1.5rem',
          height: '1.5rem',
          flexShrink: 0,
        }}
      />
    )
  }

  if (isSocialMediaResult(result)) {
    return showImage ? (
      <Image
        src={result.properties.src_image_url ?? result.thumbnail}
        onError={() => setShowImage(false)}
        style={{ flexShrink: 0 }}
        w="1.5rem"
        h="1.5rem"
        fit="fill"
      />
    ) : (
      icons.dashboard
    )
  }

  const dataBadgeProps = {
    style: { borderRadius: '50%', flexShrink: 0 },
    p: 'xxs',
  }

  if (isFireResult(result)) {
    return (
      <Center bg="red.5" {...dataBadgeProps}>
        {icons.fire}
      </Center>
    )
  }

  if (isShipResult(result)) {
    return (
      <Center bg="teal" {...dataBadgeProps}>
        {icons.anchor}
      </Center>
    )
  }

  return icons.dashboard
}
