import React from 'react'
import { ActionIcon, Menu } from '@mantine/core'
import {
  type CollectionItem,
  handleCollectionLocationDownload,
} from '@/api/collections'
import { useCollectionItems } from '@/api/collections/collection-items'
import type { Collection } from '@/api/collections/types'
import { ConditionalTooltip } from '@/components/lib/ConditionalTooltip'
import {
  openDeleteCollectionModal,
  openShareModal,
} from '@/features/AppLayout/Modals'
import { useFeatureFlag } from '@/hooks/use-feature-flag'
import { useAvatarDetails } from '@/stores/user-store'
import { FEATURE_FLAG_VALUES } from '@/utils/constants'
import {
  MdDownload,
  MdOutlineDelete,
  MdOutlineMoreHoriz,
  MdOutlineMoreVert,
  MdOutlineSend,
} from 'react-icons/md'

export type CollectionMenuOrientation = 'vertical' | 'horizontal'

export interface CollectionMenuProps {
  collection: Collection | CollectionItem
  orientation: CollectionMenuOrientation
}

function MoreIcon({ orientation }: { orientation: CollectionMenuOrientation }) {
  const Component =
    orientation === 'vertical' ? MdOutlineMoreVert : MdOutlineMoreHoriz
  return <Component color="var(--mantine-color-black)" />
}

export const CollectionMenu = ({
  collection,
  orientation,
}: CollectionMenuProps) => {
  const { email } = useAvatarDetails()
  const isCommercial = useFeatureFlag(FEATURE_FLAG_VALUES.commercial)
  const { totalCount, filter } = useCollectionItems(collection.id)

  const handleDownloadClick = () => {
    void handleCollectionLocationDownload({
      collectionId: collection.id,
      collectionName: collection.name,
      totalCount,
      filter,
    })
  }

  const isShared = React.useMemo(() => {
    const role = collection?.users?.find((user) => user.email === email)?.role
    if (!role) {
      return true
    }
    return role !== 'creator' && role !== 'owner'
  }, [collection, email])

  return (
    <Menu shadow="md" withinPortal trigger="click-hover">
      <Menu.Target>
        <ActionIcon
          variant="transparent"
          onClick={(event) => event.stopPropagation()}
          data-cy="collection-menu"
        >
          <MoreIcon orientation={orientation} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <ConditionalTooltip
          label={
            isShared
              ? "You don't have access to share this saved search"
              : undefined
          }
        >
          <Menu.Item
            disabled={isShared}
            leftSection={<MdOutlineSend />}
            onClick={() => openShareModal(collection)}
          >
            Share
          </Menu.Item>
        </ConditionalTooltip>

        <ConditionalTooltip
          label={
            isShared
              ? `You can't delete saved searches that were shared with you`
              : undefined
          }
        >
          <Menu.Item
            disabled={isShared}
            leftSection={<MdOutlineDelete />}
            color="red"
            onClick={() => openDeleteCollectionModal(collection.id)}
          >
            Delete
          </Menu.Item>
        </ConditionalTooltip>
        {!isCommercial && (
          <Menu.Item leftSection={<MdDownload />} onClick={handleDownloadClick}>
            Download Location Data
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
